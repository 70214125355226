import Link from 'next/link'
import Image from 'next/image'
import { Secondary, Wrapper, SmallLinks } from './Footer.styles'
import { useRouter } from 'next/router'
import { Spacing, Body } from '@big-red-group/storefront-common-ui'
import Container from './Container'
import { useElementClick } from '@/hooks/HTMLElementClickHook'

const MinimalFooter = () => {
  const router = useRouter()
  const privacyPolicyLinkTxt = 'Privacy'
  const linkClick = useElementClick('Link')

  return (
    <>
      <Wrapper data-testid="footer" minimalPadding>
        <Container>
          <Spacing size="XXS" />
          <Secondary>
            <SmallLinks>
              <span>
                <Link
                  data-testid={privacyPolicyLinkTxt}
                  href="/en/privacy-policy"
                  onClick={(event) => linkClick({ location: `Footer | ${privacyPolicyLinkTxt}`, event })}
                >
                  <Body size="XS" weight="regular" color="text-inverse">
                    {privacyPolicyLinkTxt}
                  </Body>
                </Link>
              </span>
            </SmallLinks>
            <Image src="/images/brg-network-logo.webp" alt="Big Red Group" width={0} height={0} sizes="100%" />
          </Secondary>
          <Spacing size="XXS" />
        </Container>
      </Wrapper>
    </>
  )
}

export default MinimalFooter
