import { Wrapper } from './ReviewStars.styles'

const ReviewStars: React.FC<{ rating: number; starSize?: number }> = ({ rating, starSize }) => {
  const modRating = parseFloat((rating % 1).toFixed(2))
  const filledStars = Math.trunc(rating) + (modRating >= 0.8 ? 1 : 0)
  const halfStars = modRating >= 0.5 && modRating < 0.8 ? 1 : 0
  const emptyStars = 5 - halfStars - filledStars
  const size = starSize ?? 16

  return (
    <Wrapper isLarge={size > 16}>
      {[...Array(filledStars).keys()].map((_, index) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          key={index}
          fill="currentColor"
          width={size}
          height={size}
          data-testid="star-filled"
        >
          <path d="M12,18.9l7.4,4.5l-2-8.4L24,9.3l-8.6-0.7L12,0.6L8.6,8.5L0,9.3L6.5,15l-2,8.4L12,18.9z" />
        </svg>
      ))}
      {halfStars === 1 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width={size}
          height={size}
          data-testid="star-half"
        >
          <path
            d="M12,16.7V5.5l2.1,4.8l5.3,0.4l-4,3.5l1.2,5.1 M24,9.3l-8.6-0.7l-3.4-8l-3.4,8L0,9.3L6.5,15l-2,8.4l7.4-4.5l7.4,4.5l-2-8.4
	L24,9.3z"
          />
        </svg>
      )}
      {[...Array(emptyStars).keys()].map((_, index) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          key={index}
          fill="currentColor"
          width={size}
          height={size}
          data-testid="star-empty"
        >
          <path
            d="M12,16.7l-4.5,2.7l1.2-5.1l-4-3.5l5.3-0.4L12,5.5l2.1,4.8l5.3,0.4l-4,3.5l1.2,5.1 M24,9.3l-8.6-0.7l-3.4-8l-3.4,8L0,9.3
	L6.5,15l-2,8.4l7.4-4.5l7.4,4.5l-2-8.4L24,9.3z"
          />
        </svg>
      ))}
    </Wrapper>
  )
}

export default ReviewStars
