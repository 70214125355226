import { CustomSiteMapEvent } from '@/helpers/AnalyticsSegmentHelper'
import type { DestinationMiddlewareParams } from '@segment/analytics-next/dist/types/plugins/middleware'

// Add more integration names here if needed to ensure data is appended as expected for those integrations
const integrations = new Set(['Google Tag Manager'])
const observedContentNames: { [pageIdContent: string]: string } = {
  pdp: 'Product Viewed',
  plp: 'Product List Viewed',
}

/**
 * This middleware is a SFMC sitemap helper as it helps determine when an event is being pushed from
 * Segment analytics SDK into GTM dataLayer. Key scenarios specified in this middleware where an event
 * called "page_transition" is fired are:
 *   - If there is no `content` attribute found in named item `pageid` in any `<meta>` tag and the
 *     current event type is `page` (or an `analytics.page()` call)
 *   - If `content` attribute exists in the `observed content names` (as in function argument) key-value
 *     pair and the accompanied event also matches that in that key-value pair
 *   - Otherwise, every other `analytics.page()` call
 */
export const marketingCloudMiddleWare = async ({
  payload,
  integration,
  next,
}: DestinationMiddlewareParams): Promise<void> => {
  // Only apply this middleware for those needed to be observed
  if (!integrations.has(integration)) {
    return next(payload)
  }

  if (payload.obj.messageId && payload.obj.properties) {
    payload.obj.properties = { ...payload.obj.properties, messageId: payload.obj.messageId }
  }

  // Set event data, additional data can also be added if required as payload.obj contains full event payload
  const newEvent = new CustomSiteMapEvent('segment_event_fired', {
    event_name: payload.obj.event,
    event_type: payload.obj.type,
    message_id: payload.obj.messageId,
    properties: payload.obj.properties,
  })

  // Get set value of observed page content types from object keys
  const observedContentNameSet = new Set(Object.keys(observedContentNames))

  // Get page content type from <meta> tag
  const pageContent = document.getElementsByTagName('meta').namedItem('pageid')?.getAttribute('content')

  if (!pageContent) {
    // If no page content type exists and this is a normal page view, signify SFMC about page transition
    if (payload.obj.type === 'page') {
      window.dispatchEvent(newEvent)
    }

    return next(payload)
  }

  // If page content type does exist as well as exists in the observed list
  // (eg. "plp" => "Product List Viewed", "pdp" => "Product Viewed"...)
  if (observedContentNameSet.has(pageContent)) {
    // Only aim for analytics.track() calls with event name specified as observed.
    // All other events will pass through.
    if (payload.obj.type === 'track' && payload.obj.event === observedContentNames[pageContent]) {
      window.dispatchEvent(newEvent)
    }
  }
  // Otherwise, only dispatch event when this is an analytics.page() call as usual
  else if (payload.obj.type === 'page') {
    window.dispatchEvent(newEvent)
  }

  next(payload)
}
