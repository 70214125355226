import type { DestinationMiddlewareParams } from '@segment/analytics-next/dist/types/plugins/middleware'

// Add more integration names here if needed to ensure data is appended as expected for those integrations
const integrations = new Set(['Google Tag Manager'])

export const userDataMiddleWare = async ({
  payload,
  integration,
  next,
}: DestinationMiddlewareParams): Promise<void> => {
  // Only apply this middleware for those needed to adjust
  if (!integrations.has(integration)) {
    return next(payload)
  }

  // Duplicate event context into event properties to deal with Virgin's data
  if (payload.obj.context?.traits && payload.obj.properties) {
    payload.obj.properties = {
      ...payload.obj.properties,
      user_data: payload.obj.context?.traits,
    }
  }

  next(payload)
}
