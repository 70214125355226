import { AnalyticsProductAvailableSession, ProductBaseProperties, ProductSessionVariant } from '@segment/analytics-next'
import { createHash } from 'crypto'

import type {
  AnalyticsMetaDataCachedItems,
  GeoLocation,
  GiftVoucherItem,
  Location,
  PaymentSummary,
  PlpProduct,
  Product,
  ProductComposite,
  ProductDetails,
  ProductRecommendation,
  SegmentCartData,
  SegmentCartItem,
  SegmentCheckoutCartItemOption,
} from '@/types'
import {
  BASE_PRODUCT_PROPERTIES,
  COMPOSITE_KEY_SEPARATOR,
  DEFAULT_LANGUAGE,
  MAXIMUM_SEGMENT_STRING_LENGTH,
  PRODUCTS_LENGTH_LIMIT,
} from '../constant/segment'
import { roundDecimalNumber } from './ClientSideRenderHelper'
import {
  CartItemData,
  CartItemOptionData,
  centsToDollars,
  formatPrice,
  ProductSession,
} from '@big-red-group/storefront-common-checkout'
import {
  CartData,
  CheckoutCartItemOption,
  CouponDiscount,
  ExperienceVoucherCartItem,
  GiftVoucherCartItem,
  LiveBookingCartItem,
  Purchase,
} from '@big-red-group/storefront-common-checkout/dist/types'

//! Types
type ProductBasePropertiesInput = {
  product:
    | CartItemData
    | PlpProduct
    | Product
    | ProductDetails
    | ProductRecommendation
    | GiftVoucherItem
    | SegmentCartItem
  position?: number
  includeSessions?: boolean
  productLayout?: string
}

//! Functions
/**
 * Check if the data type is ProductDetails
 */
export const isProductDetails = (data: any): data is ProductDetails =>
  data && 'id' in data && 'urlSegment' in data && 'helpCode' in data && !('metadata' in data)

/**
 * Check if the data type is Product
 */
export const isProduct = (data: any): data is Product =>
  data && 'id' in data && 'inclusions' in data && !('metadata' in data) && !isProductDetails(data)

/**
 * Check if the data type is ProductComposite
 */
export const isProductComposite = (data: any): data is ProductComposite =>
  isProduct(data) && 'regionUrlSegment' in data && 'operatorName' in data && 'images' in data && !('metadata' in data)

/**
 * Check if the data type is ProductRecommendation
 */
export const isProductRecommendation = (data: any): data is ProductRecommendation =>
  data && 'productId' in data && 'productUrlSegment' in data && !('metadata' in data)

/**
 * Check if the data type is CartItem
 */
export const isCartItem = (data: any): data is SegmentCartItem =>
  data && 'productId' in data && 'uuid' in data && 'allOptions' in data

/**
 * Check if the data type is PlpProduct
 */
export const isPlpProduct = (data: any): data is PlpProduct =>
  data && 'id' in data && 'isLive' in data && 'images' in data && !('metadata' in data)

/**
 * Check if the data type is Gift voucher
 */
export const isGiftVoucherItem = (data: any): data is GiftVoucherItem =>
  data && 'id' in data && 'selectedTheme' in data && 'themes' in data

/**
 * Check if the coupon data type is PromoCode
 */
export const isPromoCodeDiscount = (data: any): data is CouponDiscount => data && 'promoCode' in data

/**
 * Create composite key to be used for metadata provider
 */
export const createCompositeKey = (...keys: (string | number)[]): string => {
  const rawCompositeValue = keys.join(COMPOSITE_KEY_SEPARATOR)

  return createHash('md5').update(rawCompositeValue).digest('hex')
}

/**
 * Check if date string is a valid one
 */
export const isValidDate = (dateString: string | null | undefined): boolean =>
  !Number.isNaN(Date.parse(dateString || ''))

/**
 * Extract the first image URL from the list which conforms with string length specified by Segment
 */
export const getImageUrl = (images?: string[]): string => {
  if (!Array.isArray(images) || !images.length) {
    return ''
  }

  for (const image of images) {
    if (image.length < MAXIMUM_SEGMENT_STRING_LENGTH) {
      return image
    }
  }

  return ''
}

/**
 * Convert time from 24-hour format into 12-hour format for better audience building
 */
export const getTourHourIn12HourFormat = (
  tourHour: number | null | undefined,
  tourMinute: number | null | undefined
): Pick<ProductSessionVariant | AnalyticsProductAvailableSession, 'time_hour' | 'time_minute' | 'time_period'> => {
  if (tourHour === null || tourHour === undefined || Number.isNaN(tourHour) || !Number.isSafeInteger(tourHour)) {
    return {}
  }

  if (tourHour <= 12) {
    const result: Pick<ProductSessionVariant, 'time_hour' | 'time_minute' | 'time_period'> = {
      time_hour: roundDecimalNumber(tourHour, 0),
      time_minute: roundDecimalNumber(tourMinute || 0, 0),
      time_period: 'AM',
    }

    if (tourHour === 12) {
      result.time_period = 'PM'
    }

    return result
  }

  return {
    time_hour: roundDecimalNumber(tourHour - 12, 0),
    time_minute: roundDecimalNumber(tourMinute || 0, 0),
    time_period: 'PM',
  }
}

/**
 * Procure URL segment value from URL string
 */
export const getUrlSegmentFromUrl = (url: string, default_url: string): string => {
  if (!url) {
    return default_url
  }
  const urlParts = url.split('/')

  return urlParts.pop() || default_url
}

/**
 * Extract latitudes and longitudes from list of geolocations
 */
export const adjustGeolocations = (locations: GeoLocation[]): Location[] =>
  locations.map(({ latitude, longitude }) => ({
    latitude: latitude ? latitude : 0,
    longitude: longitude ? longitude : 0,
  }))

// TODO: Cannot figure out category, subcategory, category list
/**
 * Generate universal event properties for Product-related events
 */
export const makeProductBaseProperties = ({
  product,
  position,
  includeSessions = false,
  productLayout = '',
}: ProductBasePropertiesInput): ProductBaseProperties => {
  const productProperties = { ...BASE_PRODUCT_PROPERTIES }

  if (Number.isSafeInteger(position)) {
    productProperties.position = position
  }

  if (isProduct(product) || isProductDetails(product)) {
    productProperties.product_id = product.id
    productProperties.price = roundDecimalNumber(product.fromPrice || 0)
    productProperties.title = product.title

    if (isProductDetails(product)) {
      productProperties.brand = product.operatorName
      productProperties.starting_price = roundDecimalNumber(product.fromPrice)
      // TODO: When retail_price is implemented, discount = retailPrice - fromPrice
      productProperties.discount = 0
      productProperties.sku = product.helpCode
      productProperties.name = product.name
      productProperties.region_id = product.regionId || productProperties.region_id
      productProperties.description = product.description
      productProperties.url_segment = product.urlSegment
      productProperties.operator = {
        ...productProperties.operator,
        id: product.operatorId,
      }
      productProperties.levy = product.levy
      productProperties.image_url = getImageUrl(product.images)
      productProperties.product_layout = productLayout

      if (includeSessions && product.productSessions.length) {
        productProperties.variants = product.productSessions.reduce<ProductSessionVariant[]>(
          (variants, session) => [...variants, ...processProductSession(session)],
          []
        )
      }
    } else {
      productProperties.name = product.title
      productProperties.price = product.price

      if (product.fromPrice !== undefined) {
        productProperties.price = roundDecimalNumber(product.fromPrice)
        productProperties.starting_price = roundDecimalNumber(product.fromPrice)
        productProperties.discount = roundDecimalNumber(Math.max(product.price - product.fromPrice, 0)) ?? 0
      }

      if (product.rating !== undefined) {
        productProperties.rating = product.rating
      }

      productProperties.brand = product.supplier
      productProperties.image_url = product.image
      productProperties.url_segment = getUrlSegmentFromUrl(product.url, productProperties.url_segment)

      if (isProductComposite(product)) {
        productProperties.brand = product.operatorName || product.supplier
        productProperties.price = roundDecimalNumber(product.price)

        productProperties.operator = {
          ...productProperties.operator,
          id: product.operatorId,
        }

        if (product.retailPrice !== undefined) {
          productProperties.retail_price = roundDecimalNumber(product.retailPrice)
        }

        if (product.fromPrice !== undefined) {
          productProperties.price = roundDecimalNumber(product.fromPrice)
          productProperties.starting_price = roundDecimalNumber(product.fromPrice)

          if (product.retailPrice !== undefined) {
            productProperties.discount = roundDecimalNumber(Math.max(product.retailPrice - product.fromPrice, 0)) ?? 0
          } else {
            productProperties.discount = roundDecimalNumber(Math.max(product.price - product.fromPrice, 0)) ?? 0
          }
        }

        productProperties.levy = roundDecimalNumber(product.levy || 0)
        productProperties.description = product.summary
        productProperties.url_segment = product.urlSegment
      }
    }
  } else if (isProductRecommendation(product)) {
    if (product.rating !== undefined) {
      productProperties.rating = product.rating
    }

    productProperties.title = product.productName
    productProperties.brand = product.operatorName
    productProperties.product_id = product.productId
    productProperties.name = product.productName
    productProperties.url_segment = product.productUrlSegment
    productProperties.image_url = getImageUrl(product.images)
    productProperties.product_layout = productLayout
    productProperties.is_recommendation = true
  } else if (isCartItem(product)) {
    productProperties.brand = product.brand || productProperties.brand
    productProperties.product_id = product.productId
    productProperties.image_url = product.image || productProperties.image_url
    productProperties.name = product.name || productProperties.name
    productProperties.title = product.name || productProperties.title
    productProperties.price = centsToDollars(roundDecimalNumber(Number(product.price))) || 0
    productProperties.url = product.path || ''
    productProperties.url_segment = getUrlSegmentFromUrl(productProperties.url, productProperties.url_segment)

    // TODO: Calculate retail_price when product retailPrice is available in the cart response
    productProperties.retail_price = 0
    productProperties.starting_price = 0
    productProperties.discount = centsToDollars(roundDecimalNumber(product.discount!)) || 0

    // TODO: Calculate discount when product retailPrice and starting_price are available in the cart response
    // Ensure discount is not showing negative
    // productProperties.discount =
    //   roundDecimalNumber(Math.max(productProperties.retail_price - productProperties.starting_price, 0)) ?? 0

    // TODO: Calculate operator when operator details are available in the cart response
    productProperties.operator = productProperties.operator
    // productProperties.operator = {
    //   ...productProperties.operator,
    //   id: roundDecimalNumber(Number(product.metadata.catalogueInfo.operatorId) || 0),
    // }
    productProperties.product_type = product.type

    if (includeSessions && product?.allOptions) {
      productProperties.variants = product.allOptions.reduce<ProductSessionVariant[]>(
        (variants, option) => [...variants, processCartItemOptionData(option)],
        []
      )
    }
  } else if (isPlpProduct(product)) {
    productProperties.brand = product.operatorName
    productProperties.product_id = product.id
    productProperties.title = product.name
    productProperties.name = product.name
    productProperties.operator = {
      ...productProperties.operator,
      id: product.operatorId,
    }
    productProperties.price = product.fromPrice
    productProperties.starting_price = product.fromPrice
    productProperties.retail_price = product.retailPrice
    productProperties.discount = roundDecimalNumber(product.retailPrice - product.fromPrice) ?? 0
    productProperties.description = product.summary
    productProperties.levy = product.levy
    productProperties.image_url = getImageUrl(product.images)
    productProperties.product_layout = productLayout
    productProperties.url_segment = product.urlSegment

    if (product.rating !== undefined) {
      productProperties.rating = product.rating
    }
  } else if (isGiftVoucherItem(product)) {
    productProperties.product_id = product.id
    productProperties.name = `${formatPrice(product.giftVoucherAmount!)} Gift Voucher`
    productProperties.title = 'Gift Voucher'
    productProperties.image_url = product.imageUrl || productProperties.image_url
    productProperties.price = centsToDollars(product.giftVoucherAmount!) || 0
    productProperties.starting_price = productProperties.price
    productProperties.retail_price = productProperties.price
    productProperties.brand = product.catalogueType || productProperties.brand
    productProperties.currency = product.currencyCode
    productProperties.discount = 0
  }

  return productProperties
}

/**
 * Compare type of cached item and input item to return a new structure based on type prioritisation
 *
 * Type prioritisation: `ProductDetails` > `ProductComposite` > `PlpProduct` > `ProductRecommendation` > `Product`
 */
export const getPrioritisedProductItem = (
  cachedItem: PlpProduct | ProductDetails | ProductComposite | Product | ProductRecommendation,
  inputItem?: PlpProduct | ProductDetails | ProductComposite | Product | ProductRecommendation
): [AnalyticsMetaDataCachedItems['products'], AnalyticsMetaDataCachedItems['variants']] => {
  // If there is no input item, replace it by cached item
  if (!inputItem) {
    return getPrioritisedProductItem(cachedItem, cachedItem)
  }

  // Always prioritise the most complete data type
  if (isProductDetails(cachedItem) || isProductDetails(inputItem)) {
    const item = isProductDetails(cachedItem) ? cachedItem : (inputItem as ProductDetails)

    // Session list in ProductDetails type hold the most complete data, keep them in the cache
    const { productSessions } = item
    const variants: AnalyticsMetaDataCachedItems['variants'] = {}

    for (const productSession of productSessions) {
      const { productOptions, ...session } = productSession

      for (const productOption of productOptions) {
        const variantKey = createCompositeKey(productSession.id, productOption.id)

        variants[variantKey] = {
          ...session,
          option: productOption,
        }
      }
    }

    return [{ [item.id.toString()]: item }, variants]
  }

  // This is the second prioritised data type
  if (isProductComposite(cachedItem) || isProductComposite(inputItem)) {
    const item = isProductComposite(cachedItem) ? cachedItem : (inputItem as ProductComposite)
    return [{ [item.id.toString()]: item }, {}]
  }

  // Get product ID
  const productId = isPlpProduct(inputItem) || isProduct(inputItem) ? inputItem.id : inputItem.productId

  // If input item is either PlpProduct or Product Recommendation type, combine with cached item into ProductComposite
  if (isPlpProduct(inputItem)) {
    return [
      {
        [productId.toString()]: {
          ...cachedItem,
          operatorId: inputItem.operatorId,
          urlSegment: inputItem.urlSegment,
          summary: inputItem.summary,
          fromPrice: inputItem.fromPrice,
          retailPrice: inputItem.retailPrice,
          levy: inputItem.levy,
        },
      },
      {},
    ]
  }

  if (isProductRecommendation(inputItem)) {
    return [
      {
        [productId.toString()]: {
          ...cachedItem,
          operatorName: inputItem.operatorName,
          regionUrlSegment: inputItem.regionUrlSegment,
          images: inputItem.images,
        },
      },
      {},
    ]
  }

  // After this point, input item should be Product type, combine it with cached item into Product Composite
  if (isPlpProduct(cachedItem)) {
    return [
      {
        [productId.toString()]: {
          ...inputItem,
          operatorId: cachedItem.operatorId,
          urlSegment: cachedItem.urlSegment,
          summary: cachedItem.summary,
          fromPrice: cachedItem.fromPrice,
          retailPrice: cachedItem.retailPrice,
          levy: cachedItem.levy,
        },
      },
      {},
    ]
  }

  if (isProductRecommendation(cachedItem)) {
    return [
      {
        [productId.toString()]: {
          ...inputItem,
          operatorName: cachedItem.operatorName,
          regionUrlSegment: cachedItem.regionUrlSegment,
          images: cachedItem.images,
        },
      },
      {},
    ]
  }

  // Use cached item as default result since input item type if Product, which is the basic type
  return [{ [productId.toString()]: inputItem }, {}]
}

/**
 * Send data cross-origin using `window.postMessage()` method to ensure tracking
 */
export const postWindowMessage = (window: Window | undefined, message: any) => {
  if (window) {
    window.postMessage(message, '*')
  }
}

/**
 * Process product session data from ProductDetails type
 */
export const processProductSession = (session: ProductSession): ProductSessionVariant[] => {
  return session.productOptions.map<ProductSessionVariant>((option) => ({
    id: option.id,
    session_id: session.id,
    name: option.name,
    session_name: session.sessionName,
    type: option.type,
    is_extra: session.isExtra,
    has_pickups: session.hasPickups,
    price: option.price,
    retail_price: option.retailPrice,
    discount: roundDecimalNumber(option.retailPrice - option.price) ?? 0,
    ...(Number.isSafeInteger(session.tourDuration) ? { duration: session.tourDuration } : {}),
    ...getTourHourIn12HourFormat(session.tourHour, session.tourMinute),
  }))
}

/**
 * Process cart item option data from CartItemData type
 */
export const processCartItemOptionData = (option: SegmentCheckoutCartItemOption): ProductSessionVariant => ({
  id: option.optionId,
  price: Number(option.price) || 0,
  quantity: option.quantity,
  name: option.productOptionName || '',
  retail_price: Number(option.retailPrice) || 0,
  discount: option.discount ?? 0,
  session_id: roundDecimalNumber(Number(option.sessionId) || 0, 0),
  session_name: option.sessionName || '',
  // TODO: Calculate duration when productSession time is available in the cart response
  // duration: roundDecimalNumber(Number(option.metadata.productSessionDuration) || 0, 0),
  // ...getTourHourIn12HourFormat(
  //   Number(option.metadata.productSessionHour),
  //   Number(option.metadata.productSessionMinute)
  // ),
})

/**
 * Segment has limits on the amount of data we can send to the Segment Public API in a single request.
 * When the filter/ product list returns a large number of products, then Segment returns a Bad Request error because
 * the payload is too large and exceeds the limits. To fix this error, set limit to 30.
 */
export const limitProductsLength = (products: (PlpProduct | Product | ProductRecommendation)[]) => {
  // Extracted products
  let limitedProducts: (PlpProduct | Product | ProductRecommendation)[] = []
  limitedProducts = products.length <= PRODUCTS_LENGTH_LIMIT ? products : products.slice(0, PRODUCTS_LENGTH_LIMIT)
  return limitedProducts
}

/**
 * Create cart product options, required for tracking.
 * This function is similar to a helper function in CartItemHelper.ts(storefront-common-checkout lib) and
 * returns additional fields required for tracking that CartItemHelper.ts doesn't provide.
 * TODO: Remove this helper when all the data is available in CartItemHelper.ts
 */
const getSegmentProductOptions = (
  item: GiftVoucherCartItem | ExperienceVoucherCartItem | LiveBookingCartItem
): SegmentCheckoutCartItemOption[] => {
  if (item.type === 'EXPERIENCE_VOUCHER') {
    const options: SegmentCheckoutCartItemOption[] = [
      {
        optionId: item.productOptionId,
        productOptionName: item.productOptionName,
        productOptionType: item.productOptionType,
        // @ts-ignore
        price: Number(item?.price) || 0,
        quantity: 1,
        sessionId: item.productSessionId,
        sessionName: item.productSessionName,
        retailPrice: Number(item.retailPrice) || 0,
        // @ts-ignore
        discount: roundDecimalNumber(Math.max((Number(item.retailPrice) || 0) - Number(item?.sellPrice), 0)) ?? 0,
      },
    ]
    return options
  } else if (item.type === 'LIVE_BOOKING') {
    const options: SegmentCheckoutCartItemOption[] = item.optionItems?.map((option) => ({
      optionId: option.productOptionId,
      productOptionName: option.productOptionName,
      productOptionType: option.productOptionType,
      // @ts-ignore
      price: Number(option?.sellPrice) || 0,
      quantity: option.quantity,
      optionalExtras: option.optionalExtras || [],
      sessionId: item.productSessionId,
      sessionName: item.productSessionName,
      retailPrice: Number(option.retailPrice) || 0,
      discount:
        // @ts-ignore: TODO: Add sellPrice to OptionCartItem
        roundDecimalNumber(Math.max((Number(option.retailPrice) || 0) - Number(option.sellPrice), 0)) ?? 0,
    }))
    return options
  } else {
    return []
  }
}

/**
 * Create new cart item, required for tracking
 * This function is similar to a helper function in CartItemHelper.ts(storefront-common-checkout lib) and
 * returns additional fields required for tracking that CartItemHelper.ts doesn't provide.
 * TODO: Remove this helper when all the data is available in CartItemHelper.ts
 */
const createSegmentNewCartItem = (
  map: Map<string, SegmentCartItem>,
  uuid: string,
  productId: number,
  cartItemData: CartItemData,
  options: CheckoutCartItemOption[]
) => {
  map.set(uuid, {
    uuid: cartItemData.uuid,
    productId: productId,
    allIds: [cartItemData.uuid],
    image:
      cartItemData.item.type === 'EXPERIENCE_VOUCHER' || cartItemData.item.type === 'LIVE_BOOKING'
        ? cartItemData.item.productImage
        : '/images/gift-voucher-icon.svg',
    name:
      cartItemData.item.type === 'EXPERIENCE_VOUCHER' || cartItemData.item.type === 'LIVE_BOOKING'
        ? cartItemData.item.productName
        : `${formatPrice(cartItemData.item.amount)} Gift Voucher`,
    price: cartItemData.total,
    total: cartItemData.total,
    allOptions: options,
    type: cartItemData.item.type,
    discount: cartItemData.discount,
    path: createProductUrl(cartItemData.item),
    brand:
      cartItemData.item.type === 'EXPERIENCE_VOUCHER' || cartItemData.item.type === 'LIVE_BOOKING'
        ? // @ts-ignore
          (cartItemData.item?.supplier?.name ?? '')
        : '',
  })
}

/**
 * Update existing checkout cart items, required for tracking.
 * This function is similar to a helper function in CartItemHelper.ts(storefront-common-checkout lib) and
 * returns additional fields required for tracking that CartItemHelper.ts doesn't provide.
 * TODO: Remove this helper when all the data is available in CartItemHelper.ts
 */
const updateSegmentExistingCartItem = (
  existingItem: SegmentCartItem,
  newOptions: CheckoutCartItemOption[],
  itemUuid: string
) => {
  existingItem.allIds.push(itemUuid)

  newOptions.forEach((newOption) => {
    const existingOption = existingItem.allOptions?.find((option) => option.optionId === newOption.optionId)
    if (existingOption) {
      existingOption.quantity += newOption.quantity
    } else {
      existingItem.allOptions?.push(newOption)
    }
  })
  existingItem.price += newOptions.reduce((sum, option) => sum + option.price * option.quantity, 0)
}

/**
 * Create checkout cart items for tracking.
 * This function is similar to a helper function in CartItemHelper.ts(storefront-common-checkout lib) and
 * returns additional fields required for tracking that CartItemHelper.ts doesn't provide.
 * TODO: Remove this helper when all the data is available in CartItemHelper.ts
 */
export const createSegmentCheckoutCartItems = (cartItemsData: CartItemData[]): SegmentCartItem[] => {
  const checkoutCardItemMap = new Map<string, SegmentCartItem>()

  cartItemsData.forEach((cartItemData) => {
    const itemType = cartItemData.item.type
    const uuid =
      itemType === 'EXPERIENCE_VOUCHER'
        ? (cartItemData.item as ExperienceVoucherCartItem).productId.toString()
        : cartItemData.uuid

    // TODO: Make productId = 1 dynamic for gift voucher
    const productId =
      itemType === 'EXPERIENCE_VOUCHER' || itemType === 'LIVE_BOOKING' ? cartItemData.item.product.id : 1

    const existingItem = checkoutCardItemMap.get(uuid)
    const options = getSegmentProductOptions(cartItemData.item)

    if (existingItem) {
      updateSegmentExistingCartItem(existingItem, options, cartItemData.uuid)
    } else {
      createSegmentNewCartItem(checkoutCardItemMap, uuid, productId, cartItemData, options)
    }
  })

  return Array.from(checkoutCardItemMap.values())
}

/**
 * Transform CartData to SegmentCartData
 */
export const createSegmentCartData = (cart: CartData | undefined): SegmentCartData | undefined => {
  if (cart?.items) {
    // @ts-ignore
    const { items, payments, ...rest } = cart

    const segmentPayments: PaymentSummary[] = payments?.length
      ? payments.map((payment: any) => {
          return {
            paymentMethod: payment.method,
            amount: payment.amount,
          }
        })
      : []

    const segmentCartItems = createSegmentCheckoutCartItems(cart.items)

    const segmentCartData = {
      items: segmentCartItems,
      payments: segmentPayments,
      ...rest,
    }
    return segmentCartData
  }
}

/**
 * Sum the amount for the payment methods:
 * CARD_WEB, LOYALTY_POINTS, ROOM_CHARGE, CASH, ZIPPAY, AFTERPAY, CARD_NON_WEB)
 */
export const calculateAmountCashPaid = (paymentSummaries: PaymentSummary[]) => {
  const filteredPayments = paymentSummaries.filter(
    (payment: PaymentSummary) =>
      payment.paymentMethod == 'CARD_WEB' ||
      payment.paymentMethod == 'LOYALTY_POINTS' ||
      payment.paymentMethod == 'ROOM_CHARGE' ||
      payment.paymentMethod == 'CASH' ||
      payment.paymentMethod == 'ZIPPAY' ||
      payment.paymentMethod == 'AFTERPAY' ||
      payment.paymentMethod == 'CARD_NON_WEB'
  )
  const { computedTotalAmount } = filteredPayments.reduce<{ computedTotalAmount: number }>(
    (computed, filteredPayment) => ({
      computedTotalAmount: computed.computedTotalAmount + filteredPayment.amount,
    }),
    { computedTotalAmount: 0 }
  )
  return computedTotalAmount
}

// Iterate over the colors object and return the
// key from the property where the value matches the rgb value
export const findBrowserThemeColor = (rgbColor: string) => {
  const themeColors = {
    Dark: 'rgb(18, 18, 18)',
    Light: 'rgb(255, 255, 255)',
  }

  const pair = Object.entries(themeColors).find(([key, value]) => {
    return value === rgbColor
  })

  if (pair) {
    return pair[0]
  }
}

export const createProductUrl = (product: GiftVoucherCartItem | ExperienceVoucherCartItem | LiveBookingCartItem) => {
  const url =
    product.type === 'GIFT_VOUCHER'
      ? `${window.location.origin}/${DEFAULT_LANGUAGE}/gift-vouchers`
      : `${window.location.origin}/${DEFAULT_LANGUAGE}/${product.path}`
  return url
}
