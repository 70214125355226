import { CartViewedEventProperties } from '@segment/analytics-next'

import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, SegmentCartItem } from '@/types'
import { centsToDollars } from '@big-red-group/storefront-common-checkout'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'

type CreateCartViewedEvent = {
  products: SegmentCartItem[]
  cartId?: string
  jweToken?: string
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Cart+Viewed#Properties
 */
export const createCartViewedEvent = ({
  products = [],
  cartId = '',
  jweToken = '',
}: CreateCartViewedEvent): Extract<AnalyticsTrackEvent, { object: 'Cart'; action: 'Viewed' }> => {
  const productBaseProperties = products.map((product, index) => ({
    ...makeProductBaseProperties({ product, position: index + 1, includeSessions: true }),
  }))

  // Update price
  const updatedProductBaseProperties = productBaseProperties.map((product) => {
    const updatedVariants = product.variants!.map((variant) => {
      return {
        ...variant,
        price: centsToDollars(roundDecimalNumber(variant.price!)),
      }
    })
    return { ...product, variants: updatedVariants }
  })

  const eventProperties: CartViewedEventProperties = {
    products: updatedProductBaseProperties,
  }

  if (cartId) {
    eventProperties.cart_id = cartId
  }

  const event: Extract<AnalyticsTrackEvent, { object: 'Cart'; action: 'Viewed' }> = {
    object: 'Cart',
    action: 'Viewed',
    properties: eventProperties,
  }

  if (jweToken) {
    event.context = { ...event.context, context: { jwe_token: jweToken } }
  }

  return event
}
