import { NotificationType } from '@/types'
import { toast } from 'react-hot-toast'
import { Main, Text, Title, Wrapper, ContentWrapper } from './Notification.styles'
import Icon from '@/components/Icons'

type NotificationProps = {
  text: string
  title: string
  type: NotificationType
}

const Notification: React.FC<NotificationProps> = ({ text, title, type }) => {
  return (
    <Wrapper type={type} data-testid={`notification-${type}`}>
      <Icon name="info" width={18} height={18} />
      <Main>
        <ContentWrapper>
          <Title data-testid="notification-title">{title}</Title>
          {/* Close icon */}
          <Icon name="close" width={18} height={18} data-testid="notification-close" onClick={() => toast.remove()} />
        </ContentWrapper>
        <Text data-testid="notification-text">{text}</Text>
      </Main>
    </Wrapper>
  )
}

export default Notification
