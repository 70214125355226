import { LoyaltyCampaignData } from '@/types/segment'
import type { DestinationMiddlewareParams } from '@segment/analytics-next/dist/types/plugins/middleware'

// Add more integration names here if needed to ensure data is appended as expected for those integrations
const integrations = new Set(['Google Analytics 4 Web'])

export const loyaltyCampaignDataMiddleWare = async ({
  payload,
  integration,
  next,
}: DestinationMiddlewareParams): Promise<void> => {
  // Only apply this middleware for those needed to adjust
  if (!integrations.has(integration)) {
    return next(payload)
  }

  // Check if loyalty campaign exists as an array with at least 1 element
  if (payload.obj.properties?.loyalty_campaign?.length) {
    const loyaltyCampaigns = payload.obj.properties.loyalty_campaign as LoyaltyCampaignData[]

    const newLoyaltyCampaign: { [k: string]: number } = {}

    for (const loyaltyCampaign of loyaltyCampaigns) {
      // For instance, this will turn { operation_type: "burn", points: 2000 } into { burn: 2000 }
      newLoyaltyCampaign[loyaltyCampaign.operation_type] = loyaltyCampaign.points
    }

    payload.obj.properties.loyalty_campaign = newLoyaltyCampaign
  }

  next(payload)
}
