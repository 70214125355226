import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@/constant/ui'
export const Wrapper = styled.div`
  background: var(--grey-25);
`
export const ContentWrapper = styled.div`
  max-width: 100%;
  padding: 24px 0px;
  gap: 40px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 45px 0px;
    gap: 27px;
    flex-direction: column;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-wrap: balance;
  flex-basis: 50%;
  justify-content: center;
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-wrap: balance;
  flex-flow: wrap;
  flex-basis: 50%;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
  }
`
export const HighlightsWrapper = styled.div`
  @media (min-width: ${BREAKPOINT_SMALL}px) {
    ul {
      flex-direction: row;
    }
    li {
      flex-basis: calc(100% / 3);
    }
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  & > input {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    gap: 24px;
    flex-direction: column;
  }
`
