import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'
import { BREAKPOINT_MEDIUM_LARGE } from '@big-red-group/storefront-common-ui'

export const Main = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-gap: 64px;
  margin-bottom: 64px;

  svg {
    color: var(--brand-primary);
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    grid-template-columns: 1fr;
  }
`
export const Secondary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  font-size: 12px;
  font-family: var(--font-light);

  & a {
    color: white;
    text-decoration: none;
  }

  img {
    height: 32px;
    width: auto;
    display: block;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;

    & > svg {
      margin-top: 48px;
    }
  }
`
export const SecondaryLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;

  @media (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const BottomWrapper = styled.div`
  background-color: var(--bg-black);
  padding: 24px 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 40px 0;
  }
`

export const PaymentMethods = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  pointer-events: none;
  margin-left: 32px;

  @media (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
    margin-left: 0;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin: 0;
  }
`
export const CardIcon = styled.div`
  border: 1px solid var(--grey-100);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 30px;
  flex-shrink: 0;

  svg {
    width: 80%;
    height: 60%;
  }
`

export const Third = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  svg {
    flex-shrink: 0;
    color: var(--brand-accent);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const SmallLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-size: 12px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`
export const Social = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;

  a {
    display: block;
  }

  svg {
    color: var(--brand-accent);
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    justify-content: flex-start;
  }
`
export const Menu = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 25%));
  align-items: flex-start;
  grid-gap: 32px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    grid-gap: 64px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`
export const SectionLink = styled(Link)`
  line-height: 1.1;
  display: block;
  color: white;
  text-decoration: none;
  width: fit-content;
`

export const Wrapper = styled.footer<{ lightFooter?: boolean; minimalPadding?: boolean }>`
  background-color: var(--bg-black);
  padding: ${({ minimalPadding }) => (minimalPadding ? '34px 0' : '80px 0')};
  z-index: ${Z_INDEX.footer};
  position: relative;

  ${({ lightFooter }) =>
    lightFooter &&
    `
      color: black;
      background-color: #E1E1E1;
      
      ${SectionLink},
      ${Secondary} a {
        color: black;
      }
    `}

  ${({ minimalPadding }) =>
    !minimalPadding &&
    `
    @media (max-width: ${BREAKPOINT_SMALL}px) {
      padding: 32px 0;
    }
  `};
`
