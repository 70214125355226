import { ButtonClickEventProperties } from '@segment/analytics-next'
import { AnalyticsTrackEvent } from '@/types'

// TODO: Add sort method, filter when available
export const createButtonClickEvent = ({
  type,
  url,
  text,
  location,
  section,
  sticky_bar,
}: ButtonClickEventProperties): Extract<AnalyticsTrackEvent, { object: 'Button'; action: 'Clicked' }> => {
  // TODO: If geolocation list is available in any input type, set it up in the payload
  const eventProperties: ButtonClickEventProperties = {
    type,
    text,
    location,
    url,
    section,
    sticky_bar,
  }

  return {
    object: 'Button',
    action: 'Clicked',
    properties: eventProperties,
  }
}
