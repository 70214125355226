import { Options, Plugin } from '@segment/analytics-next'

import { utmParametersContexts } from '@/helpers/PluginHelper'

export const UTMParametersPlugin = (
  _siteName: string = '',
  _currencyCode?: string,
  contextOptions?: Options
): Plugin => ({
  name: ' UTM Parameters context value',
  type: 'enrichment',
  version: '0.0.1',

  isLoaded: () => true,
  load: () => Promise.resolve(),

  track: utmParametersContexts(contextOptions!),
  page: utmParametersContexts(contextOptions!),
  identify: utmParametersContexts(contextOptions!),
})
