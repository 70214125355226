import { ProductAddedEventProperties } from '@segment/analytics-next'

import {
  getTourHourIn12HourFormat,
  isGiftVoucherItem,
  isProductDetails,
  makeProductBaseProperties,
} from '@/helpers/AnalyticsHelper'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'
import { AnalyticsTrackEvent, GiftVoucherItem, ProductDetails, Voucher, VoucherDetails } from '@/types'
import { CartItemData, centsToDollars, ProductOption, ProductSession } from '@big-red-group/storefront-common-checkout'

type CreateProductAddedEvent = {
  product: ProductDetails | CartItemData | GiftVoucherItem
  url: string
  isGift?: boolean
  quantity: number
  isProductEdit: boolean
  session?: Omit<ProductSession, 'productOptions'>
  variant?: ProductOption
  fromWishlist?: boolean
  isRecommended?: boolean
  voucher?: VoucherDetails
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Product+Added#Properties
 */
export const createProductAddedEvent = ({
  product,
  url,
  isGift = false,
  quantity,
  isProductEdit,
  session,
  variant,
  fromWishlist = false,
  isRecommended = false,
  voucher,
}: CreateProductAddedEvent): Extract<AnalyticsTrackEvent, { object: 'Product'; action: 'Added' }> => {
  const productBaseProperties = makeProductBaseProperties({ product })

  const eventProperties: ProductAddedEventProperties = {
    ...productBaseProperties,
    is_product_edit: isProductEdit,
    quantity,
    latitude: 0,
    longitude: 0,
    url,
    is_gift: isGift,
    is_recommendation: isRecommended,
    from_wishlist: fromWishlist,
  }

  if (isProductDetails(product)) {
    const { geolocations } = product
    const latitude = geolocations?.[0]?.latitude || 0
    const longitude = geolocations?.[0]?.longitude || 0

    eventProperties.latitude = latitude || eventProperties.latitude
    eventProperties.longitude = longitude || eventProperties.longitude
  }

  if (session && variant) {
    eventProperties.name = `${eventProperties.name} | ${variant.name}`

    eventProperties.levy = session.levy
    eventProperties.price = variant.price
    eventProperties.retail_price = variant.retailPrice
    eventProperties.starting_price = variant.price
    eventProperties.discount = roundDecimalNumber(variant.retailPrice - variant.price)

    eventProperties.variant = {
      id: variant.id,
      session_id: session.id,
      name: variant.name,
      session_name: session.sessionName,
      type: variant.type,
    }

    if (session.tourDuration) {
      eventProperties.variant.duration = session.tourDuration
    }

    if (session.tourHour) {
      eventProperties.variant = {
        ...eventProperties.variant,
        ...getTourHourIn12HourFormat(session.tourHour, session.tourMinute),
      }
    }
  }

  // Experience Voucher
  if (voucher && voucher.type === 'experience') {
    const { type, deliveryType, isPersonalisedMessages } = voucher
    eventProperties.voucher = {
      delivery_type: deliveryType,
      is_personalised_messages: isPersonalisedMessages,
      type: type,
    }
  }

  // Gift Voucher
  if (voucher && isGiftVoucherItem(product)) {
    const { allowsCustomAmount, maxAllowedAmount, predefinedAmounts, selectedTheme, themes } = product
    const { type, deliveryType, isPersonalisedMessages, giftVoucherCustomAmountAdded } = voucher

    eventProperties.voucher = {
      is_custom_amount_allow: allowsCustomAmount,
      max_allowed_amount: centsToDollars(maxAllowedAmount) || 0,
      predefined_amounts: predefinedAmounts.map((amount) => centsToDollars(amount)),
      themes: themes,
      selected_theme: selectedTheme,
      delivery_type: deliveryType,
      is_personalised_messages: isPersonalisedMessages,
      is_custom_amount_added: giftVoucherCustomAmountAdded,
      type: type,
    }
  }

  return {
    object: 'Product',
    action: 'Added',
    properties: eventProperties,
  }
}
