import React, { useMemo } from 'react'

import { AnalyticsContext } from '@/context/AnalyticsContext'
import { isClient } from '@/helpers/ClientSideRenderHelper'
import { logger } from '@/logging/Logger'
import { CommonAnalyticsContext, CommonAnalyticsHandler, CommonAnalyticsProvider } from '@/types'

const log = logger()

export const AnalyticsProvider: React.FC<CommonAnalyticsProvider> = ({ children }) => {
  const analytics: CommonAnalyticsContext = useMemo(() => {
    const handlers: Partial<CommonAnalyticsHandler>[] = []

    return {
      setUserId(userId, additionalProps): void {
        if (!isClient()) {
          return
        }

        for (const handler of handlers) {
          try {
            handler.setUserId?.(userId, additionalProps)
          } catch (error) {
            log.error(
              'Handler encountered error when setting user ID',
              { handlerName: `${handler.name}` },
              error as Error
            )
          }
        }
      },

      trackEvent(event): void {
        if (!isClient()) {
          return
        }

        for (const handler of handlers) {
          try {
            handler.trackEvent?.(event)
          } catch (error) {
            log.error(
              'Handler encountered error when sending Track event',
              { handlerName: `${handler.name}` },
              error as Error
            )
          }
        }
      },

      trackPage(page, sessionUser): void {
        if (!isClient()) {
          return
        }

        for (const handler of handlers) {
          try {
            if (sessionUser) {
              handler.trackPage?.(page, sessionUser)
            } else {
              handler.trackPage?.(page)
            }
          } catch (error) {
            log.error(
              'Handler encountered error when sending Page event',
              { handlerName: `${handler.name}` },
              error as Error
            )
          }
        }
      },

      addHandler(handler: Partial<CommonAnalyticsHandler>): void {
        const handlerExists = handlers.indexOf(handler) !== -1

        // This is to prevent double tracking
        if (!handlerExists) {
          handlers.push(handler)
          return
        }

        log.warn('Handler already exists and does not need adding', { handlerName: `${handler.name}` })
      },

      removeHandler(handler: Partial<CommonAnalyticsHandler>): boolean {
        const index = handlers.indexOf(handler)

        if (index >= 0) {
          handlers.splice(index, 1)
          return true
        }

        log.warn('Handler does not exist and cannot be removed', { handlerName: `${handler.name}` })
        return false
      },
    }
  }, [])

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
}
