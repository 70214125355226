import {
  DEFAULT_FBC_COOKIE_PREFIX,
  DEFAULT_FBP_COOKIE_PREFIX,
  DEFAULT_GA_CLIENT_ID_COOKIE_PREFIX,
  DEFAULT_GA_SESSION_COOKIE_PREFIX,
} from '../constant/segment'
import { CampaignMetadata, FBCookies, GaCookies, GetFBDataInput, GetGADataInput, ProductDetails } from '@/types'
import { Options } from '@segment/analytics-next'
import { ParsedUrlQuery } from 'querystring'

declare const window: {
  location: {
    origin: string
  }
  document: {
    cookie: string
  }
  navigator: {
    userAgent: string
  }
}

/**
 * Validate whether the current environment is client or server
 */
export const isClient = () => typeof window !== 'undefined'

/**
 * Check if the environment is running with Cypress, either component or E2E testing
 */
export const isCypressRunning = { check: () => 'Cypress' in window || /cypress/gi.test(window.navigator.userAgent) }

/**
 * Check whether the current NODE environment is production or development/test and return corresponding value
 */
export const getEnv = () => (process.env.NODE_ENV !== 'production' ? 'DEV' : 'PROD')

export const roundDecimalNumber = (value: number, precision: number = 2): number => {
  // Return 0 if number is division by 0
  if (!Number.isFinite(value)) {
    return 0
  }

  // Return input value if this is an integer
  if (Number.isInteger(value)) {
    return value
  }

  // This will be the contextual aspect of the round method
  const roundContext = Math.pow(10, precision)

  // Otherwise, return the value rounded by 2 decimal digits
  return Math.round(value * roundContext) / roundContext
}

/**
 * This will extract domain name from the host name, including subdomain.
 *
 * This is not omnipotent since it can only assume that the host name does not include path or host name is neither empty string nor dot exclusive.
 *
 * If host name is sent as `com.au`, it will return `com.au`.
 */
export const getCurrentDomain = (hostname: string): string | undefined => {
  if (!hostname || hostname.indexOf('/') !== -1 || hostname.split('.').length < 2) {
    return
  }

  return hostname.replace(/^(?=www|ww\d|cdn|ftp|mail|pop\d?|ns\d?|git)[^.]+\./g, '')
}

/**
 * Sometimes, the URL provided is only a path acquired from href attribute, this will extend the URL into a full URL
 */
export const constructFullUrl = (url: string): string => {
  if (url.indexOf('://') > 0 || url.indexOf('//') === 0) {
    return url
  } else {
    return `${window.location.origin}${url}`
  }
}

export const setRecentlyViewed = (product: ProductDetails): void => {
  // Fetch existing product data from session storage
  const existingData = localStorage.getItem('recentlyViewed')
  let productData: ProductDetails[] = []

  if (existingData) {
    // Parse existing data from session storage
    productData = JSON.parse(existingData)
    const isProductExisting = productData.some((p) => p.id === product.id)

    if (isProductExisting) {
      return
    }

    // Remove the oldest product if there are already 8 products
    if (productData.length >= 8) {
      productData.pop()
    }
  }

  // TODO: These are being set manually because the product carousel expects type Product[] not ProductDetails[]
  // @ts-ignore
  product.image = [product.images && product.images[0]]
  // @ts-ignore
  product.supplier = product.operatorName
  // TODO: lang
  // @ts-ignore
  product.url = `/en/${product.canonicalRegionUrlSegment}/${product.urlSegment}`

  // Add the new product to the beginning of the array
  productData.unshift(product)

  // Store the updated product data in session storage
  localStorage.setItem('recentlyViewed', JSON.stringify(productData))
}

/**
 * Parse query parameters from Next router, this includes both path query and search params
 */
export const parseQueryParams = (query: ParsedUrlQuery): Options | undefined => {
  const queryKeys = Object.keys(query)

  if (!queryKeys.length) {
    return
  }

  const utmContextParams: Options = {}

  for (const queryKey of queryKeys) {
    switch (true) {
      case queryKey.indexOf('utm_') !== -1: {
        // const utmParamKey = queryKey.replace('utm_', '') as CampaignMetadata
        const utmParamKey: string = queryKey.replace('utm_', '')
        utmContextParams.context = utmContextParams.context || {}
        utmContextParams.context.campaign = utmContextParams.context?.campaign || {
          content: '',
          name: '',
          medium: '',
          source: '',
          term: '',
        }

        utmContextParams.context = {
          ...utmContextParams.context,
          // CoreExtraContext.campaign? has a different type in ExOz
          campaign: {
            ...utmContextParams.context.campaign,
            [utmParamKey]: query[queryKey] as string,
          },
        }
      }
      // Can add more context data if needed
      default:
        continue
    }
  }

  // Context is prioritised and must be present after the above data processing
  // Integrations is optional only
  if (!Object.keys(utmContextParams.context || {}).length) {
    return
  }

  // Clean out empty campaign metadata
  if (utmContextParams.context?.campaign) {
    for (const campaignKey in utmContextParams.context.campaign) {
      if (!utmContextParams.context.campaign[campaignKey as CampaignMetadata]) {
        delete utmContextParams.context.campaign[campaignKey as CampaignMetadata]
      }
    }
  }

  return utmContextParams
}

// TODO: Consolidate getGAData and getFBData into single function called getCookieData
/**
 * Returns Google Analytics Client ID, Session ID and Session Number from the browser's cookies
 *
 *  » A cookie with a Client ID: GA1.1.2123711811.1687247260
 *
 *  ✔ Client ID: 2123711811.1687247260
 *
 *  » A cookie with a Session ID and Session Number: GS1.1.1687250858.2.1.1687251993.0.0.0
 *
 *  ✔ Session Id: 1687250858
 *  ✔ Session number: 2
 */
export const getGAData = (input: GetGADataInput = {}): GaCookies | undefined => {
  if (isClient()) {
    const {
      clientIdCookiePrefix = DEFAULT_GA_CLIENT_ID_COOKIE_PREFIX,
      sessionCookiePrefix = DEFAULT_GA_SESSION_COOKIE_PREFIX,
    } = input

    // Split cookie string and get all individual name=value pairs in an array
    const cookieArray = window.document.cookie.split(';')

    const gaCookies = cookieArray.reduce<GaCookies>(
      (result, currentCookieData) => {
        // Split at "="
        const [cookieKey, cookieValue] = currentCookieData.split('=')

        /**
         * Removing whitespace at the beginning of the cookie name
         * and compare it with the given string
         */
        if (clientIdCookiePrefix == cookieKey.trim()) {
          // Decode the cookie value
          result.clientId = decodeURIComponent(cookieValue.split('.').slice(2).join('.'))
        }

        if (cookieKey.trim().includes(sessionCookiePrefix)) {
          // Decode the cookie value
          result.sessionId = decodeURIComponent(cookieValue.split('.')[2])
          result.sessionNumber = decodeURIComponent(cookieValue.split('.')[3])
        }

        return result
      },
      { clientId: '' }
    )

    return gaCookies
  }

  return
}

/**
 * Returns Facebook Pixel fbp and fbc (if available) from the browser's cookies
 *
 *  » An example for fbp could be: `fb.1.1596403881668.1116446470`
 *
 *  » An example for fbc could be: `fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890`
 *
 * More context on how these value could be acquired can be seen [here](https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/)
 */
export const getFBData = (input: GetFBDataInput = {}): FBCookies | undefined => {
  if (isClient()) {
    const { cookieFacebookPixel = DEFAULT_FBP_COOKIE_PREFIX, cookieFacebookClick = DEFAULT_FBC_COOKIE_PREFIX } = input

    // Split cookie string and get all individual name=value pairs in an array
    const cookieArray = window.document.cookie.split(';')

    const fbCookies = cookieArray.reduce<FBCookies>(
      (result, currentCookieData) => {
        // Split at "="
        const [cookieKey, cookieValue] = currentCookieData.split('=')
        /**
         * Removing whitespace at the beginning of the cookie name
         * and compare it with the given string
         */
        if (cookieFacebookPixel === cookieKey.trim()) {
          // Decode the cookie value
          result.fbp = decodeURIComponent(cookieValue)
        }

        if (cookieFacebookClick == cookieKey.trim()) {
          // Decode the cookie value
          result.fbc = decodeURIComponent(cookieValue)
        }

        return result
      },
      { fbp: '', fbc: '' } // default return if cookie values were not found
    )

    return fbCookies
  }

  return
}

interface CustomPerformanceEntry extends PerformanceEntry {
  domContentLoadedEventStart: number
  domContentLoadedEventEnd: number
  loadEventEnd: number
  loadEventStart: number
  domInteractive: number
  responseStart: number
  fetchStart: number
  redirectStart: number
  redirectEnd: number
}

type PagePerformanceMetrics = {
  content_loaded_time_ms: number
  onload_time_ms: number
  dom_interactive_time_ms: number
  fully_loaded_time_ms: number
  time_to_first_byte_ms: number
  redirect_duration_ms: number
  first_paint_time_ms: number
  first_contentful_paint_time_ms: number
}

export const getPagePerformanceData = (): PagePerformanceMetrics | undefined => {
  if (isClient()) {
    const payload: PagePerformanceMetrics = {
      content_loaded_time_ms: 0,
      onload_time_ms: 0,
      dom_interactive_time_ms: 0,
      fully_loaded_time_ms: 0,
      time_to_first_byte_ms: 0,
      redirect_duration_ms: 0,
      first_paint_time_ms: 0,
      first_contentful_paint_time_ms: 0,
    }

    const performanceNavigationEntry = performance?.getEntriesByType('navigation')?.[0] as CustomPerformanceEntry

    const firstPaintDuration = performance.getEntriesByType('paint')?.[0]?.startTime

    const firstContentfulPaintDuration = performance.getEntriesByType('paint')?.[1]?.startTime

    if (performanceNavigationEntry) {
      const {
        domContentLoadedEventEnd,
        domContentLoadedEventStart,
        loadEventEnd,
        loadEventStart,
        domInteractive,
        responseStart,
        fetchStart,
        redirectStart,
        redirectEnd,
      } = performanceNavigationEntry

      payload.content_loaded_time_ms = domContentLoadedEventEnd - domContentLoadedEventStart

      payload.onload_time_ms = +Number(loadEventEnd - loadEventStart).toFixed(3)

      payload.dom_interactive_time_ms = +domInteractive.toFixed(3)

      payload.fully_loaded_time_ms = +Number(loadEventEnd - fetchStart).toFixed(3)

      payload.time_to_first_byte_ms = +Number(responseStart - fetchStart).toFixed(3)

      payload.redirect_duration_ms = +Number(redirectEnd - redirectStart).toFixed(3)
    }

    if (firstPaintDuration) payload.first_paint_time_ms = +Number(firstPaintDuration).toFixed(3)

    if (firstContentfulPaintDuration)
      payload.first_contentful_paint_time_ms = +Number(firstContentfulPaintDuration).toFixed(3)

    return payload
  }

  return
}

/**
 * Compute the percentage of click location from the top of the document, precised until 3 decimal digit by default
 */
export const computeEventLocationPct = (
  event: React.MouseEvent<HTMLElement> | MouseEvent,
  precision: number = 3
): number => {
  // Get the boundary of the document
  const docBoundary = document.documentElement.getBoundingClientRect()

  // Get document overall height in pixels
  const docHeight = docBoundary.height

  // Get the precise vertical doordinate in pixels where the event takes place
  const eventHeight = event.pageY

  return roundDecimalNumber(eventHeight / docHeight, precision)
}
