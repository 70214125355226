import { Spinner, Wrapper } from './LoadingSpinner.styles'

const LoadingSpinner: React.FC = ({}) => {
  return (
    <Wrapper>
      <Spinner data-testid="spinner" />
    </Wrapper>
  )
}

export default LoadingSpinner
