import styled from 'styled-components'

export const Wrapper = styled.div<{ isLarge: boolean }>`
  display: flex;
  gap: ${({ isLarge }) => (isLarge ? '3' : '1')}px;

  svg {
    color: var(--brand-accent);
  }
`
