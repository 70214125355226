import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { Toaster } from 'react-hot-toast'
import Footer from '@/components/Footer'
import Topbar from '@/components/Topbar'
import '@/styles/reset.css'
import '@/styles/globals.css'
import '@/styles/react-hot-toast.css'
import { getAgentConfiguration, removeProtocol } from '@/helpers/UrlHelper'
import { DefaultPropertiesContext } from '@/context/DefaultPropertiesContext'
import { DefaultPageResponse } from '@/types'
import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { BrgThemeProvider } from '@big-red-group/storefront-common-ui'
import { initChat } from '@/helpers/LiveChatHelper'
import { TOASTER_CLASS_NAME, TOASTER_STYLE } from '@/constant/ui'
import Subscriber from '@/components/Subscriber'
import { AnalyticsProvider } from '@/components/AnalyticsProvider'
import { AnalyticsSegmentProvider } from '@/components/AnalyticsSegmentProvider'
import { AnalyticsSegmentHandler } from '@/handlers/AnalyticsSegmentHandler'
import { AnalyticsMetaDataProvider } from '@/components/AnalyticsMetaDataProvider'
import { AnalyticsPageTracker } from '@/components/AnalyticsPageTracker'
import { AnalyticsIdentifyTracker } from '@/components/AnalyticsIdentifyTracker'
import { AnalyticsErrorTracker } from '@/components/AnalyticsErrorTracker'
import { datadogLogsInitialization, datadogRumInitialization } from '@/logging/DatadogInitialisation'
import MinimalTopbar from '@/components/MinimalTopbar'
import MinimalFooter from '@/components/MinimalFooter'
import AuthWrapper from '@/components/AuthWrapper'
import { AnalyticsBeaconTracker } from '@/components/AnalyticsBeaconTracker'

datadogRumInitialization()
datadogLogsInitialization()

type AppPropsWithAuth = AppProps & {
  session: Session
  Component: {
    requireAuth?: boolean
    hideSubscriber?: boolean
  }
}

export default function App({ Component, pageProps, session }: AppPropsWithAuth) {
  const defaultProperties: DefaultPageResponse = pageProps

  const useMinimalNav = pageProps?.minimalNavigation || false
  const useMinimalFooter = pageProps?.minimalFooter || false

  useEffect(() => {
    initChat(3, undefined)
  }, [])

  return (
    <>
      <SessionProvider session={session} basePath="/api/auth">
        <DefaultPropertiesContext.Provider value={defaultProperties}>
          <AnalyticsProvider>
            <AnalyticsMetaDataProvider>
              <AnalyticsSegmentProvider>
                <AnalyticsSegmentHandler />
              </AnalyticsSegmentProvider>
              <AnalyticsPageTracker />
              <AnalyticsIdentifyTracker />
              <AnalyticsErrorTracker />
              <AnalyticsBeaconTracker />
              <BrgThemeProvider theme={{ platform: 'adr' }}>
                <Toaster
                  containerClassName={TOASTER_CLASS_NAME}
                  toastOptions={{
                    style: TOASTER_STYLE,
                    duration: 1000 * 4, // 4 secs it closes automatically
                  }}
                />
                {useMinimalNav ? <MinimalTopbar /> : <Topbar />}
                {Component.requireAuth ? (
                  <AuthWrapper>
                    <Component {...pageProps} />
                  </AuthWrapper>
                ) : (
                  <Component {...pageProps} />
                )}
                {/* Renders an empty div to be targeted by SFMC */}
                {(Component.hideSubscriber === false || !Component.hideSubscriber) && <Subscriber />}
                {useMinimalFooter ? <MinimalFooter /> : <Footer />}
              </BrgThemeProvider>
            </AnalyticsMetaDataProvider>
          </AnalyticsProvider>
        </DefaultPropertiesContext.Provider>
      </SessionProvider>
    </>
  )
}

App.getInitialProps = async ({ ctx }: any) => {
  // If req is undefined it's using CSR not SSR
  const isServerSideRequest = ctx?.req?.headers ? true : false
  const host = isServerSideRequest ? ctx.req.headers.host : removeProtocol(window.location.origin)
  const agentConfiguration = getAgentConfiguration(host)

  // Need to strip the subdomain off the host for server side calls
  const url = ctx?.req?.headers
    ? `${process.env.NEXT_BACKEND_API_URL}/storefront-resources/global-properties`
    : `${window.location.origin}/api/storefront-resources/global-properties`
  // Server side request, go directly to LOKI
  if (isServerSideRequest) {
    try {
      const query = new URLSearchParams(agentConfiguration)
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_LOKI_BASE_URL}/storefront-resources/global-properties?${query}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      if (!response.ok) return { props: { error: true } }
      const json = await response.json()
      return { pageProps: json }
    } catch (error) {
      console.log({ error: `An error occurred fetching global properties ${error}` })
      return { pageProps: {} }
    }
  }
  // Client side request, go through next api
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ agentConfiguration }),
    })
    if (!response.ok) return { props: { error: true } }
    const json = await response.json()
    return { pageProps: json }
  } catch (error) {
    console.log({ error: `An error occurred fetching global properties ${error}` })
    return { pageProps: {} }
  }
}
