import { Options, Plugin } from '@segment/analytics-next'

import { enrichCommonContext } from '@/helpers/PluginHelper'

export const CommonEnrichmentPlugin = (
  siteName: string = '',
  currencyCode?: string,
  _contextOptions?: Options
): Plugin => ({
  name: 'Enrich common context value',
  type: 'enrichment',
  version: '0.0.1',

  isLoaded: () => true,
  load: () => Promise.resolve(),

  track: enrichCommonContext(siteName, currencyCode),
  page: enrichCommonContext(siteName),
  identify: enrichCommonContext(siteName),
})
