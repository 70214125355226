import { PageEventAction, PageEventProperties } from '@segment/analytics-next'

import { AnalyticsTrackEvent } from '@/types'

type CreatePageEvent = {
  timeSpent: number
  isTrusted: boolean
  eventAction: PageEventAction
}

/**
 * For taxonomy of this event, see:
 *   - https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Page+Bounced#Properties
 *   - https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Page+Exited#Properties
 */
export const createPageEvent = ({
  timeSpent,
  isTrusted,
  eventAction,
}: CreatePageEvent): Extract<AnalyticsTrackEvent, { object: 'Page'; action: PageEventAction }> => {
  const eventProperties: PageEventProperties = {
    time_spent: timeSpent,
    is_trusted: isTrusted,
    event_date: new Date(),
  }

  return {
    object: 'Page',
    action: eventAction,
    properties: eventProperties,
  }
}
