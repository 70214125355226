import { ButtonSize, ButtonStyle } from '@/types'
import { BUTTON_STYLE } from '@/constant/ui'
import styled from 'styled-components'

const getButtonStyle = (size: ButtonSize): ButtonStyle => {
  return BUTTON_STYLE[size]
}

const getIconSize = (size: ButtonSize): number => {
  if (size === 'M' || size === 'L') {
    return 24
  }
  return 16
}

export const Wrapper = styled.button<{ size: ButtonSize; $secondary: boolean; $alternate: boolean; $inverse: boolean }>`
  position: relative;
  padding: ${({ size }) => getButtonStyle(size).paddingVertical}px
    ${({ size }) => getButtonStyle(size).paddingHorizontal}px;
  display: inline-block;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: var(--brand-accent);
  border: 1px solid var(--brand-accent);
  color: var(--text-inverse);
  cursor: pointer;
  height: min-content;
  white-space: nowrap;
  font-family: var(--font-medium);

  > p {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    border: 1px solid var(--brand-accent-dark);
    background: var(--brand-accent-dark);
  }
  svg {
    height: ${({ size }) => getIconSize(size)}px;
    width: ${({ size }) => getIconSize(size)}px;
  }
  &[disabled] {
    background-color: var(--bg-disabled);
    color: var(--text-disabled);
    border: 1px solid var(--bg-disabled);
    cursor: not-allowed;
    &:hover {
      background: var(--bg-disabled);
      border: 1px solid var(--bg-disabled);
      color: var(--text-disabled);
      cursor: not-allowed;
    }
  }
  ${({ $secondary }) =>
    $secondary &&
    `
    border: 1px solid var(--brand-accent);
    background: transparent;
    color: var(--brand-accent);
    &:hover {
      background: var(--brand-accent);
      border: 1px solid var(--brand-accent);
    }
  `}
  ${({ $alternate, $secondary }) =>
    $alternate &&
    !$secondary &&
    `
    border: 1px solid var(--brand-secondary);
    background: var(--brand-secondary);
    color: var(--text-inverse);
    &:hover {
      background: var(--brand-secondary-light);
      border: 1px solid var(--brand-secondary-light);
      color: var(--text-inverse);
    }
  `}
  
  ${({ $alternate, $secondary }) =>
    $alternate &&
    $secondary &&
    `
    background: transparent;
    border: 1px solid var(--brand-secondary);
    color: var(--brand-secondary);
    &:hover {
      border: 1px solid var(--brand-secondary);
      background: var(--brand-secondary);
      color: var(--text-inverse);
  `}
  
    ${({ $inverse, $secondary }) =>
    $inverse &&
    !$secondary &&
    `
    background: var(--bg-light);
    color: var(--text-primary);
    border: 1px solid var(--text-primary);
    &:hover {
      border: 1px solid var(--brand-accent-dark);
      background: var(--brand-accent-dark);
      color: var(--text-inverse);
    }`}
    
    ${({ $inverse, $secondary }) =>
    $inverse &&
    $secondary &&
    `
    border: 1px solid var(--text-inverse);
    background: transparent;
    color: var(--text-inverse);
      
    &:hover {
      background: var(--brand-secondary-light);
      color: var(--text-inverse);
      border: 1px solid var(--brand-secondary-light);
    }
  `}
`
