import { NotificationType } from '@/types'
import styled from 'styled-components'

const notificationTypeBackground: Record<NotificationType, string> = {
  info: '#CCDAEB',
  success: '#D5F6D7',
  warn: '#FDF5D5',
  error: '#FAE6E6',
}

const notificationTypeIcon: Record<NotificationType, string> = {
  info: '#215696',
  success: '#17701F',
  warn: '#FDCF16',
  error: '#B82626',
}

const notificationTypeTitle: Record<NotificationType, string> = {
  info: '#215696',
  success: '#17701F',
  warn: 'var(--black)',
  error: '#B82626',
}

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 18px;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  gap: 5px;

  & > svg {
    width: 28px;
    height: 28px;
  }
  svg:last-child {
    cursor: pointer;
  }
`
export const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 20px;
  line-height: 1;
  margin-bottom: 5px;
  display: block;
  :first-letter {
    text-transform: capitalize;
  }
`

export const Wrapper = styled.div<{ type: NotificationType }>`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  /* lib wrapper adds 4 10 px margin */
  padding: 24px 18px;
  width: 100%;
  background-color: white;

  ${({ type }) =>
    type &&
    `
    background-color: ${notificationTypeBackground[type]};

    /* Info */
    & ${Main} {
      color: ${notificationTypeIcon[type]};
      
    }
    svg:first-child {
      color: ${notificationTypeIcon[type]};
    }

    & ${Title} {
      color: ${notificationTypeTitle[type]};
    }
  `}
`
export const Text = styled.p`
  font-family: var(--font-regular);
  font-size: 15px;
  text-wrap: wrap;
`
