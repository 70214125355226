import { AnalyticsTrackEvent } from '@/types'
import { BeaconEventAction, BeaconEventObject } from '@segment/analytics-next'

type CreateBeaconEvent = {
  object: BeaconEventObject
  action: BeaconEventAction
  properties: object
}

export const createBeaconEvent = ({
  object,
  action,
  properties,
}: CreateBeaconEvent): Extract<AnalyticsTrackEvent, { object: BeaconEventObject; action: BeaconEventAction }> => {
  return {
    object: object,
    action: action,
    properties: properties,
  }
}
