import { AuthSession } from '@/types'
import { useSession } from 'next-auth/react'

export const useAuthSession = (): AuthSession => {
  const { status: sessionStatus, data: user } = useSession()
  const authenticated = sessionStatus === 'authenticated'
  const unauthenticated = sessionStatus === 'unauthenticated'
  const loading = sessionStatus === 'loading'

  return { authenticated, unauthenticated, loading, user }
}
