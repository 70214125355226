import { Product, Inclusion } from '@/types'
import Badge from './Badge'
import Icon, { IconName } from './Icons'
import {
  Wrapper,
  MobileWrapper,
  Badges,
  Header,
  Main,
  Pricing,
  ProductImageWrap,
  ProductImage,
  TextContent,
  Rating,
  ProductCounter,
  WishList,
} from './ProductTile.styles'
import { formatCurrency } from '@/helpers/CurrencyHelper'
import { decoratorsIcons, productTileBadges } from '@/helpers/DecoratorHelper'
import { Body } from '@big-red-group/storefront-common-ui'
import ReviewStars from './ReviewStars'

const ProductTile: React.FC<
  Omit<Product, 'inclusions'> & {
    onClick: () => void
    mobileCardTile?: boolean
    smallDesktopTile?: boolean
    counter?: number | undefined
    hidePrice?: boolean
  }
> = ({
  id,
  url,
  badges,
  image,
  price,
  region,
  supplier,
  title,
  fromPrice,
  rating,
  hotDeals,
  onClick,
  mobileCardTile = true,
  smallDesktopTile = false,
  counter,
  hidePrice = false,
}) => {
  const hasHotDeals = hotDeals && hotDeals.length > 0
  const filteredBadges = badges ? badges.filter((badge) => productTileBadges.includes(badge.name)) : []

  const renderBadges = () => {
    return (
      badges &&
      badges.length > 0 &&
      filteredBadges.map((badge, index) => {
        if ((hasHotDeals && index > 0) || index > 1) {
          return ''
        }
        let icon = decoratorsIcons[badge.name as Inclusion]
        return (
          <Badge key={index} color="blue" icon={icon}>
            {badge.label}
          </Badge>
        )
      })
    )
  }

  const renderRating = () => {
    if (!rating || rating == 0) return

    return (
      <>
        <Rating>
          <ReviewStars rating={rating} />
          <Body as="span" size="S" weight="medium">
            {rating}
          </Body>
        </Rating>
      </>
    )
  }

  return (
    <Wrapper
      href={url}
      data-testid={`product-${id}`}
      onClick={onClick}
      $mobileCardTile={mobileCardTile}
      $smallDesktopTile={smallDesktopTile}
      className={`product-tile ${mobileCardTile ? 'mobile-card' : ''}`}
    >
      <MobileWrapper
        $mobileCardTile={mobileCardTile}
        $smallDesktopTile={smallDesktopTile}
        className="product-tile__wrap"
      >
        <ProductImageWrap
          className="product-tile__image"
          $mobileCardTile={mobileCardTile}
          $smallDesktopTile={smallDesktopTile}
        >
          {image && <ProductImage src={image} alt="Product image" loading="lazy" />}
          {counter && !mobileCardTile && <ProductCounter data-testid="product-counter">{counter}</ProductCounter>}
        </ProductImageWrap>
        <TextContent $mobileCardTile={mobileCardTile} $smallDesktopTile={smallDesktopTile}>
          <Header $mobileCardTile={mobileCardTile} $smallDesktopTile={smallDesktopTile}>
            <Body size="M" weight="regular" color="text-primary-light">
              {region}
            </Body>
          </Header>
          <Main $mobileCardTile={mobileCardTile} $smallDesktopTile={smallDesktopTile}>
            <Body size="M" weight="medium">
              {title}
            </Body>
            {/* <Body size="S" weight="regular" color="text-primary-light">
              {supplier}
            </Body> */}
          </Main>
          {renderRating()}
          {!hidePrice && (
            <Pricing data-testid="product-tile-pricing" onSale={fromPrice != price}>
              <Body size="M" weight="bold">
                from {<span data-testid="product-tile-price">{formatCurrency(fromPrice!)}</span>}
                {fromPrice != price && price && (
                  <>
                    <s data-testid="product-tile-retail-price">{formatCurrency(price)}</s>
                  </>
                )}
              </Body>
            </Pricing>
          )}

          {(hasHotDeals || filteredBadges.length > 0) && (
            <Badges
              $mobileCardTile={mobileCardTile}
              $smallDesktopTile={smallDesktopTile}
              className="product-tile__badges"
            >
              {hasHotDeals && (
                <Badge color="red" icon="hotDeal">
                  Sale
                </Badge>
              )}
              {renderBadges()}
            </Badges>
          )}
        </TextContent>
        {/* TODO: To be a future release and commented out for now as part of BAI-2307 */}
        {/* <WishList $filled={false} $mobileCardTile={mobileCardTile} $smallDesktopTile={smallDesktopTile}>
          <Icon name="wishlist" width={23} height={21} />
        </WishList> */}
      </MobileWrapper>
    </Wrapper>
  )
}

export default ProductTile
