import { CheckoutStepViewedEventProperties } from '@segment/analytics-next'

import { DEFAULT_CURRENCY_CODE } from '@/constant/segment'
import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, SegmentCartData, SegmentCartItem } from '@/types'
import { centsToDollars } from '@big-red-group/storefront-common-checkout'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'

type CreateCheckoutStepViewedEvent = {
  step: number
  stepLabel: string
  cartData?: Omit<SegmentCartData, 'items'>
  cartItemsData?: { [k: string]: SegmentCartItem }
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Checkout+Step+Viewed#Properties
 */
export const createCheckoutStepViewedEvent = ({
  step,
  stepLabel,
  cartData,
  cartItemsData,
}: CreateCheckoutStepViewedEvent): Extract<AnalyticsTrackEvent, { object: 'Checkout Step'; action: 'Viewed' }> => {
  const eventProperties: CheckoutStepViewedEventProperties = {
    step,
    step_name: stepLabel,
    cart_id: cartData?.uuid || '',
    checkout_id: cartData?.uuid || '',
    total: centsToDollars(roundDecimalNumber(cartData?.total || 0)),
    coupon: cartData?.couponPromoCode?.coupon?.code || '',
    discount: centsToDollars(roundDecimalNumber(cartData?.couponDiscount || 0)),
    tax: 0,
    promo_code: cartData?.couponPromoCode?.promoCode?.code || '',
    currency: DEFAULT_CURRENCY_CODE,
    payments: [],
  }

  eventProperties.payments = cartData?.payments?.map((payment) => {
    return {
      payment_method: payment.paymentMethod,
      amount: centsToDollars(roundDecimalNumber(payment?.amount || 0)),
    }
  })

  if (cartItemsData) {
    // Grab additional discount from item
    const itemDiscount = Object.values(cartItemsData).reduce<number>(
      (itemDiscount, cartItem) => itemDiscount + Math.abs(cartItem.discount!),
      0
    )

    eventProperties.discount = (eventProperties.discount || 0) + centsToDollars(roundDecimalNumber(itemDiscount))

    const cartItemKeys = Object.keys(cartItemsData)

    if (cartItemKeys.length) {
      const sampleProduct = makeProductBaseProperties({ product: cartItemsData[cartItemKeys[0]] })
      eventProperties.currency = sampleProduct.currency || eventProperties.currency
    }
  }

  return {
    object: 'Checkout Step',
    action: 'Viewed',
    properties: eventProperties,
  }
}
