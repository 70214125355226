import { ProductAddedEventProperties, ProductRemovedEventProperties } from '@segment/analytics-next'

import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'
import { AnalyticsTrackEvent, SegmentCartItem, SegmentCheckoutCartItemOption } from '@/types'
import { centsToDollars } from '@big-red-group/storefront-common-checkout'

type CreateProductRemovedEvent = {
  product: SegmentCartItem
  url: string
  isGift: boolean
  latitude: number
  longitude: number
  isProductEdit: boolean
  option?: SegmentCheckoutCartItemOption
  cached?: ProductAddedEventProperties
  currency?: string
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Product+Removed#Properties
 */
export const createProductRemovedEvent = ({
  product,
  url,
  isGift,
  latitude,
  longitude,
  isProductEdit,
  option,
  cached,
  currency,
}: CreateProductRemovedEvent): Extract<AnalyticsTrackEvent, { object: 'Product'; action: 'Removed' }> => {
  // If data could be fetched from the cache in Metadata Provider, it will be prioritised
  if (cached) {
    const { is_recommendation: _, from_wishlist: __, ...cachedProduct } = cached

    return {
      object: 'Product',
      action: 'Removed',
      properties: { ...cachedProduct, is_product_edit: isProductEdit, ...(currency ? { currency } : {}) },
    }
  }

  const productBaseProperties = makeProductBaseProperties({ product })

  const eventProperties: ProductRemovedEventProperties = {
    ...productBaseProperties,
    is_product_edit: isProductEdit,
    latitude,
    longitude,
    url,
    is_gift: product.type === 'EXPERIENCE_VOUCHER' || product.type === 'GIFT_VOUCHER' ? true : false,
    ...(currency ? { currency } : {}),
  }

  if (option) {
    eventProperties.name = `${eventProperties.name} | ${option.productOptionName || 'Unknown Variant'}`

    eventProperties.quantity = option.quantity
    eventProperties.price = centsToDollars(roundDecimalNumber(Math.max(option.price, eventProperties.price || 0)))
    eventProperties.variant = {
      id: option.optionId,
      name: option.productOptionName || '',
      session_id: Number(option.sessionId) || 0,
      session_name: option.sessionName || '',
      // TODO: Add session hour when available in the cart response
      // ...getTourHourIn12HourFormat(
      //   Number(option.metadata.productSessionHour),
      //   Number(option.metadata.productSessionMinute)
      // ),
    }
  }

  return {
    object: 'Product',
    action: 'Removed',
    properties: eventProperties,
  }
}
