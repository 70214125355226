import { datadogRum } from '@datadog/browser-rum'
import getConfig from 'next/config'
import { datadogLogs } from '@datadog/browser-logs'
import '@datadog/browser-logs/bundle/datadog-logs'
import '@datadog/browser-rum/bundle/datadog-rum'

const config = getConfig()
const appVersion = config?.publicRuntimeConfig?.version

// these values are preset for production, adjust these values as needed when testing locally
const localDev = false
const enableRUM = !localDev
const enableBrowserLogs = !localDev
// todo this doesn't accommodate other environments, we need to build different bundles per environment as per https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
const environment = localDev ? 'localdev' : 'prod' // leaving `prod` as a unique distinction for browser side, as `production` is used for on-prem, uat etc for server side.

const datadogApplicationId = '7d16f92d-a687-45c8-adae-a9a29f06cf2c'
const datadogClientToken = 'pubfbc39b6794c54bc8f66a9315782a2979'
// todo this name is the same as for the server side, we probably want different names, should change the server side startup as it is less complicated, and use same environment names consistently, so we distinguish based on service name
const datadogServiceName = 'storefront-adrenaline'

export const datadogRumInitialization = () => {
  if (enableRUM) {
    datadogRum.init({
      // @ts-ignore
      applicationId: datadogApplicationId,
      // @ts-ignore
      clientToken: datadogClientToken,
      site: 'datadoghq.com',
      service: datadogServiceName,
      env: environment,
      version: appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,// Sampling sessions costs 2.20/1k sessions, replay costs 2.60/1k sessions and includes sampling sessions. We have about 230k sessions/month in prod. The sessionReplaySampleRate parameter is a percentage of sessionSampleRate.
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      // list of internal, first-party origins called by browser application, required for connecting frontend and backend traces, https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#opentelemetry-support
      allowedTracingUrls: [
        // { match: /.*/, propagatorTypes: ["datadog"]}, // match any url, only for local debugging purposes
        // { match: "http://localhost", propagatorTypes: ["datadog"]}, // for local development
        // urls based on https://redballoon.atlassian.net/wiki/spaces/TRAN/pages/2594963562/Environments+Current+Status+Versions
        // non-prod
        // { match: /.*/, propagatorTypes: ["datadog"]}, // match any url, only for local debugging purposes
        // { match: "http://localhost", propagatorTypes: ["datadog"]}, // for local development
        // urls based on https://redballoon.atlassian.net/wiki/spaces/TRAN/pages/2594963562/Environments+Current+Status+Versions
        // non-prod
        { match: "/https:\\/\\/.*\\.adrenaline-dev\\.com\\.au/", propagatorTypes: ["datadog"]},
        { match: "/https:\\/\\/.*\\.adrenaline-uat\\.com\\.au/", propagatorTypes: ["datadog"]},
        { match: "/https:\\/\\/.*\\.bigredgroup-dev\\.net/", propagatorTypes: ["datadog"]},
        { match: "/https:\\/\\/.*\\.bigredgroup-uat\\.net/", propagatorTypes: ["datadog"]},
        { match: "http://loki.bigredgroup.net", propagatorTypes: ["datadog"]} // loki
        // prod
        // { match: "/https:\\/\\/.*\\.adrenaline\\.com\\.au/", propagatorTypes: ["datadog"]},
        // { match: "/https:\\/\\/.*\\.bigredgroup\\.net/", propagatorTypes: ["datadog"]},
        // { match: "/https:\\/\\/.*\\.adrenaline\\.online/", propagatorTypes: ["datadog"]}
      ],
      // does not impact RUM sessions sampling. Only backend traces are sampled out.
      // End-to-end tracing is available for requests fired after the Browser SDK is initialized. End-to-end tracing of the initial HTML document and early browser requests is not supported.
      traceSampleRate: 10,
      defaultPrivacyLevel: 'mask-user-input' // by default everything is masked, the replays look like a wireframe, mask-user-input makes it more convenient for us to review session replays. More details https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/, mind that we can mask fields with sensitive information by supplying the right HTML attribute
    })

    datadogRum.startSessionReplayRecording()
  }
}

export const datadogLogsInitialization = () => {
  if (enableBrowserLogs) {
    // https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
    datadogLogs.init({
      clientToken: datadogClientToken,
      site: 'datadoghq.com',
      service: datadogServiceName,
      env: environment,
      version: appVersion,
      forwardErrorsToLogs: true,
      // The percentage of sessions to track: 100 for all, 0 for none. Only tracked sessions send logs.
      sessionSampleRate: 1,
    })
  }
}
