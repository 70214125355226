import styled from 'styled-components'

export const Wrapper = styled.input<{ error: boolean; hasPrefix: boolean }>`
  border: 1px solid var(--brand-accent-secondary-dark);
  padding: 14px ${({ hasPrefix }) => (hasPrefix ? '16px' : '14px')};
  padding-left: ${({ hasPrefix }) => (hasPrefix ? '2rem' : '16px')};
  border-radius: 4px;
  box-sizing: border-box;
  background: white;
  font-family: var(--font-regular);
  color: var(--text-primary);
  font-size: 16px;
  width: 100%; /* Ensure input spans full width */

  &::placeholder {
    color: lightgrey;
    font-size: 16px;
  }

  &:disabled {
    border: 1px solid #e5e7eb;
    background: #eef0f2;
  }

  ${({ error }) =>
    error &&
    `
      border: 1px solid var(--ui-red);
  `}

  ${({ error }) =>
    error
      ? `
    &:focus {
      outline: none;
      border: 1px solid #6b0303;
    } 
  `
      : `
    &:focus {
      outline: none;
      border: 1px solid var(--brand-secondary);
    }
  `}
`

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Prefix = styled.span`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  color: #333;
  pointer-events: none;
  font-family: var(--font-bold);
`
