import { BREAKPOINT_SMALL } from '@big-red-group/storefront-common-ui'
import styled from 'styled-components'

export const Step = styled.div<{ complete: boolean }>`
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  position: relative;
  z-index: 1;

  &:not(:last-child)::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 17px;
    width: 100%;
    transform: translate(50%, -50%);
    height: 2px;
    background: var(--${({ complete }) => (complete ? 'brand-primary' : 'grey-100')});
  }
`
export const Label = styled.span<{ active: boolean; complete: boolean }>`
  text-align: center;
  display: block;
  font-weight: var(--font-medium);
  font-size: 12px;
  line-height: 1;
  color: var(--${({ active }) => (active ? 'brand-primary' : 'text-inverse')});
`
export const IconWrapper = styled.div<{ active: boolean; complete: boolean }>`
  background: var(--brand-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  width: 35px;
  height: 35px;
  background: var(--${({ complete, active }) => (active && 'brand-secondary') || (complete && 'brand-primary')});
  box-shadow: inset 0 0 0 3px var(--${({ active, complete }) => (active || complete ? 'brand-primary' : 'grey-100')});

  svg {
    width: 15px;
    height: 15px;
    color: var(--grey-100);
    color: ${({ complete, active }) => (active && 'var(--brand-primary)') || (complete && 'var(--brand-secondary)')};
  }
`
export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 48px;
  width: 500px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  background: var(--brand-secondary);
  padding: 20px 0;
  width: 100%;

  ${StepContainer} {
    height: auto;
    justify-content: space-between;
  }
  ${Step} {
    &:not(:last-child)::before {
      top: 17px;
      height: 3px;
    }
  }
  ${Label} {
    font-size: 16px;
    font-weight: var(--font-bold);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    ${Step} {
      width: calc(calc(100% / 3) - 30px);

      &:not(:last-child)::before {
        width: calc(100% + 30px);
      }
    }
    ${Label} {
      font-size: 14px;
    }
  }
`
