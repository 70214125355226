import { CSSProperties, KeyboardEventHandler } from 'react'
import { Wrapper, Container, Prefix } from './TextField.styles'

const TextField: React.FC<{
  value?: string
  prefix?: string
  className?: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  name?: string
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  style?: CSSProperties
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  error?: boolean
  required?: boolean
  maxLength?: number
  type?: 'password' | 'text' | 'email' | 'tel' | 'number'
  forwardedRef?: React.MutableRefObject<null>,
  autoComplete?: string,
}> = ({
  value,
  prefix,
  className,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  forwardedRef,
  placeholder,
  style,
  disabled,
  readonly,
  maxLength,
  type = 'text',
  name = '',
  required = false,
  error = false,
  autoComplete,
}) => {
  return (
    <Container>
      {prefix && <Prefix>{prefix}</Prefix>}
      <Wrapper
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        ref={forwardedRef}
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        maxLength={maxLength}
        error={error}
        data-error={error}
        data-testid={`input-${name}`}
        type={type}
        autoComplete={autoComplete}
        required={required}
        hasPrefix={!!prefix}
      />
    </Container>
  )
}

export default TextField
