import { useAuthSession } from '@/hooks/AuthSessionHook'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import LoadingSpinner from './LoadingSpinner'
import { useNotification } from '@/hooks/NotificationHook'
import { logger } from '@/logging/Logger'

const log = logger()

const AuthWrapper: React.FC<{ children: React.ReactElement }> = ({ children }): JSX.Element => {
  const router = useRouter()
  const { unauthenticated, authenticated, loading: userLoading, user } = useAuthSession()
  const [loadingCheck, setLoadingCheck] = useState(true)
  const notification = useNotification()

  useEffect(() => {
    // check if the session is loading or the router is not ready
    if (userLoading || !router.isReady) return

    // if the user is not authenticated or if the session doesn't exist yet, redirect to home
    if (unauthenticated || !user) {
      log.info('Not authenticated')
      notification('Please sign in to continue.', 'Error', 'error')
      router.push({ pathname: '/en/login' }) // TODO fetch lang dynamically
      return
    }

    setLoadingCheck(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading, unauthenticated, router, user])

  // if the check is stil loading
  if (loadingCheck || userLoading) {
    return <LoadingSpinner />
  }

  /*
   * If the user is authenticated, render the page otherwise, render nothing while being redirected.
   * TODO: When login page is moved from a modal to a page, will redirect the user to sign in.
   */
  return authenticated ? <>{children}</> : <LoadingSpinner />
}

export default AuthWrapper
