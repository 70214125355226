import { ProductClickedEventProperties } from '@segment/analytics-next'

import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, PlpProduct, Product, ProductRecommendation } from '@/types'

type CreateProductClickedEvent = {
  product: PlpProduct | Product | ProductRecommendation
  fromWidget: boolean
  isRecommended: boolean
  position: number
  listId: string
  listTitle: string
  isCarousel: boolean
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Product+Clicked#Properties
 */
export const createProductClickedEvent = ({
  product,
  position,
  listId,
  listTitle,
  fromWidget,
  isRecommended,
  isCarousel,
}: CreateProductClickedEvent): Extract<AnalyticsTrackEvent, { object: 'Product'; action: 'Clicked' }> => {
  const productBaseProperties = makeProductBaseProperties({ product, includeSessions: true })

  // TODO: Figure out how to get is_live, locations, accessibility, sustainability
  const eventProperties: ProductClickedEventProperties = {
    ...productBaseProperties,
    list_id: listId,
    list_name: listTitle,
    is_recommendation: isRecommended,
    from_widget: fromWidget,
    position,
    is_carousel: isCarousel,
  }

  return {
    object: 'Product',
    action: 'Clicked',
    properties: eventProperties,
  }
}
