import { useRouter } from 'next/router'
import { SubmenuLinkType } from './TopbarSubmenus'
import { LinkItem } from './SubmenuLink.styles'

const SubmenuLink: React.FC<{
  menuType: SubmenuLinkType
  menuName: string
  menuUrl: string
  underline?: boolean
  mouseOver?: () => void
}> = ({ menuType, menuName, menuUrl, underline = false, mouseOver }) => {
  const router = useRouter()

  const getMenuItemLink = () => {
    switch (menuType) {
      case 'category':
        return `/${router.query?.lang}/adventures/${menuUrl}`
      case 'gift':
        return `/${router.query?.lang}/adventures/${menuUrl}`
      case 'location':
        return `/${router.query?.lang}/${menuUrl}`
      default:
        return menuUrl
    }
  }

  return (
    <LinkItem key={menuName} href={getMenuItemLink()} underline={underline} onMouseOver={mouseOver}>
      {menuName}
    </LinkItem>
  )
}

export default SubmenuLink
