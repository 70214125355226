import React from 'react'
import { Wrapper } from './InputError.styles'

type InputErrorProps = {
  dataTestId?: string
  children: React.ReactNode
}

const InputError: React.FC<InputErrorProps> = ({ dataTestId, children }) => {
  return <Wrapper data-testid={dataTestId}>{children}</Wrapper>
}

export default InputError
