import { CSSProperties } from 'react'
import { Wrapper } from '@/components/Container.styles'
import { Spacing } from '@big-red-group/storefront-common-ui'
import { Size, ContainerSize } from '@/types'

const Container: React.FC<{
  children: React.ReactNode
  spacing?: Size | 'none'
  style?: CSSProperties
  dataTestId?: string
  size?: ContainerSize
  fullWidthMobile?: boolean
  fullWidthDesktop?: boolean
  $class?: string
}> = ({
  children,
  spacing,
  style,
  dataTestId,
  size = 'standard',
  fullWidthMobile = false,
  fullWidthDesktop = false,
  $class,
}) => {
  return (
    <Wrapper
      style={style}
      className={$class}
      data-testid={dataTestId}
      size={size}
      fullWidthMobile={fullWidthMobile}
      fullWidthDesktop={fullWidthDesktop}
    >
      {children}
      {spacing && spacing != 'none' && <Spacing size={spacing} />}
    </Wrapper>
  )
}

export default Container
