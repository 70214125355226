import { useState, useCallback } from 'react'
import { useAnalyticsMetaData } from './AnalyticsMetaDataHook'
import { findBrowserThemeColor } from '@/helpers/AnalyticsHelper'

/**
 Users can opt-out of dark themes by either disabling the option on the OS level or in a specific setting in Chrome. 
 Chrome has a Toggle common rendering emulations" icon, which looks like a brush, and has the three properties: 
 1. prefer-color-scheme: dark, 
 2. prefer-color-scheme: light and 
 3. Automatic dark mode
 window.matchMedia('(prefers-color-scheme: dark)') doesn't give the expected result to detect if a user has requested light or dark color themes
 because prefer-color-scheme: dark returns true but it's not a dark theme, leading to a page that has a light theme, but with only parts of it darkened. 
 */

/**
 * Detecting Auto Dark Theme
 * To detect if the user is in Auto Dark Theme, create an element with the background-color set to canvas and the color-scheme set to light.
 * If the computed color for the background is other than white (rgb(255, 255, 255)), then Auto Dark Theme is applied to the page.
 */
export const useAnalyticsBrowserColorTheme = () => {
  const analyticsMetaData = useAnalyticsMetaData()
  const [cacheThemeColor, setCacheThemeColor] = useState('')

  const trackBrowserTheme = useCallback(
    (themeColor: string) => {
      if (cacheThemeColor === themeColor) {
        return
      }
      setCacheThemeColor(themeColor)
      const isDarkTheme = findBrowserThemeColor(themeColor)

      analyticsMetaData.setBrowserThemeColor(isDarkTheme)
    },
    [analyticsMetaData, cacheThemeColor]
  )

  return trackBrowserTheme
}
