import type { Options, Plugin } from '@segment/analytics-next'

import { enrichFBContext } from '@/helpers/PluginHelper'

// Use "_siteName" instead of just "_" for more clarification
export const FBEnrichmentPlugin = (
  _siteName: string = '',
  _currencyCode: string = '',
  _contextOptions?: Options
): Plugin => ({
  name: 'Enrich Facebook Pixel context',
  type: 'enrichment',
  version: '0.0.1',

  isLoaded: () => true,
  load: () => Promise.resolve(),

  track: enrichFBContext,
  page: enrichFBContext,
})
