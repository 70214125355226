import { AgentConfiguration, Language } from '@/types'

export const CANONICAL_BASE_URL = 'www.adrenaline.com.au'
export const DEFAULT_LANGUAGE: Language = 'en'

export const toName = (urlSegment: string) => {
  return urlSegment
    ?.replaceAll('-', ' ')
    ?.split(' ')
    ?.map((token) => token.substring(0, 1).toUpperCase() + token.substring(1))
    ?.join(' ')
}

export const isLanguageValid = (lang: string | undefined): boolean => {
  if (!lang || lang === 'undefined') return false
  const validLangs: Language[] = [DEFAULT_LANGUAGE]
  return validLangs.includes(lang as Language)
}

export const getPartnerCode = (host: string) => {
  if (host.indexOf('localhost') !== -1 && host.split('.').length === 1) {
    return 'www'
  }

  return host.split('.')[0]
}

export const getCatalogueType = () => {
  return process.env.NEXT_PUBLIC_CATALOGUE_TYPE || 'ADR'
}

export const getAgentConfiguration = (host: string): AgentConfiguration => ({
  catalogueType: getCatalogueType(),
  language: DEFAULT_LANGUAGE,
  partnerCode: getPartnerCode(host),
})

// Gets an agent asset prefix for custom domains
export const getUrlPrefix = (handlerKey: string | undefined, trailingSlash?: boolean) => {
  // No asset prefix unless in prod
  if (process.env.NODE_ENV !== 'production') return ''
  switch (handlerKey) {
    case 'virgin':
      if (trailingSlash) {
        return '/au/en/experiences/'
      }
      return '/au/en/experiences'
    default:
      return ''
  }
}

export const removeProtocol = (url: string) => {
  return url.replace(/^https?:\/\//, '')
}

export const createCanonicalLink = (path: string): string => {
  const base = `https://${CANONICAL_BASE_URL}/${DEFAULT_LANGUAGE}`
  return path ? `${base}/${path}` : base
}
