import { ButtonClickType } from '@segment/analytics-next'

import { createButtonClickEvent } from '@/events/ButtonClicked'
import { computeEventLocationPct, constructFullUrl } from '@/helpers/ClientSideRenderHelper'
import { ClickEventHandlerInput } from '@/types'
import { useAnalytics } from './AnalyticsHook'

export const useElementClick = (type: ButtonClickType) => {
  const analytics = useAnalytics()

  const handleClickEventForSegment = ({
    url,
    location,
    text = '',
    event,
    locationType,
  }: ClickEventHandlerInput): void => {
    const eventProps = createButtonClickEvent({
      type,
      url: constructFullUrl(url || event.currentTarget?.getAttribute('href') || ''),
      location,
      text:
        text ||
        event.currentTarget?.innerText ||
        event?.currentTarget?.dataset?.testid ||
        (event?.target as HTMLButtonElement)?.innerText ||
        '',
      section: computeEventLocationPct(event),
      sticky_bar: locationType === 'drawer' ? true : false,
    })

    analytics.trackEvent(eventProps)
  }

  return handleClickEventForSegment
}
