import type { Options, Plugin } from '@segment/analytics-next'

import { performanceMeasureContext } from '@/helpers/PluginHelper'

// Note: This plugin cannot be tested as it depends on browser conditions
// Use "_siteName" instead of just "_" for more clarification
export const PagePerformancePlugin = (
  _siteName: string = '',
  _currencyCode: string = '',
  _contextOptions?: Options
): Plugin => ({
  name: 'Page Performance Plugin',
  type: 'enrichment',
  version: '0.0.1',

  isLoaded: () => true,
  load: () => Promise.resolve(),

  page: performanceMeasureContext,
})
