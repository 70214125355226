import { ProductsSearchedEventProperties } from '@segment/analytics-next'

import { AnalyticsTrackEvent, ProductsSearchedEventInput } from '@/types'

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2549842655/Products+Searched#Properties
 */
export const createProductSearchedEvent = (
  query: ProductsSearchedEventInput,
  isSeeMoreClicked?: boolean
): Extract<AnalyticsTrackEvent, { object: 'Products'; action: 'Searched' }> => {
  const { searchedValue, location, experience, product, correctionValue, recentlyViewedProduct } = query

  const eventProperties: ProductsSearchedEventProperties = {
    query: {
      searched_value: searchedValue!,
      location,
      experience,
      product,
      correction_value: correctionValue,
      recently_viewed_product: recentlyViewedProduct,
    },
    is_see_more_clicked: isSeeMoreClicked,
  }

  return {
    object: 'Products',
    action: 'Searched',
    properties: eventProperties,
  }
}
