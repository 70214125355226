import React from 'react'
import { CommonAnalyticsHandler } from '@/types'
import { useAnalytics } from '@/hooks/AnalyticsHook'

export const AnalyticsHandlerRegistrant: React.FC<Partial<CommonAnalyticsHandler>> = (handler) => {
  const { addHandler, removeHandler } = useAnalytics()

  React.useEffect(() => {
    addHandler(handler)

    return () => {
      removeHandler(handler)
    }
  }, [addHandler, removeHandler, handler])

  return null
}
