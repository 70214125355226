import type {
  Context,
  ID,
  JSONObject,
  Options,
  SegmentCustomEventData,
  EventProperties as SegmentEventProperties,
  UserTraits,
} from '@segment/analytics-next'
import { AnalyticsBrowser } from '@segment/analytics-next'

import {
  CUSTOMER_ID_SALT_PREFIX,
  CUSTOMER_ID_SALT_SUFFIX,
  CUSTOM_CDN_SUBDOMAIN_NAME,
  CUSTOM_PROXY_SUBDOMAIN_NAME,
  DEFAULT_BRAND_GROUP,
  DEFAULT_FBC_COOKIE_PREFIX,
  DEFAULT_FBP_COOKIE_PREFIX,
  DEFAULT_GA_CLIENT_ID_COOKIE_PREFIX,
  DEFAULT_GA_SESSION_COOKIE_PREFIX,
  DEFAULT_TOP_LEVEL_DOMAIN,
  DEV_PROXY_ENDPOINT_EXTENSION,
  SEGMENT_USER_ID_KEY_NAME,
  SEGMENT_USER_TRAITS_KEY_NAME,
} from '@/constant/segment'
import {
  AnalyticsTrackEvent,
  ContextDataProcessResult,
  SegmentAnalyticsCall,
  SegmentConfiguration,
  SegmentTrackEventReturnType,
  TrackEventToSegmentInput,
} from '@/types'
import { createHash } from 'crypto'
import { getCurrentDomain, getEnv, isClient, isCypressRunning } from './ClientSideRenderHelper'
import { AgentConfigurationResponse } from '@big-red-group/storefront-common-checkout'

export class CustomSiteMapEvent extends Event {
  detail: SegmentCustomEventData

  constructor(type: string, data: SegmentCustomEventData, eventInitDict?: EventInit) {
    super(type, eventInitDict)
    this.detail = data
  }
}

/**
 * Normalise value by lowercase the whole raw value and remove any whitespaces
 *
 * See https://redballoon.atlassian.net/wiki/spaces/BID/pages/2530181121/Source+Data+Cleansing+Transformation#Solution for more details
 */
export const normaliseValue = (value: string): string => value?.toLowerCase().replace(/\s/g, '')

/**
 * Cryptographic hash functions to hash sensitive data such as such as names and email addresses, etc.
 *
 * For email, this is to support use-cases of social media where email must be attached for profile matching in ad server such as Pinterest Tag
 *
 * See https://redballoon.atlassian.net/wiki/spaces/BID/pages/2531196985/Hash+Email+Generation#Email-As-Required-Configuration for more details
 *
 * See https://help.pinterest.com/en-gb/business/article/enhanced-match for Pinterest Tag specifically
 */
export const hashValue = (value: string): string => createHash('sha256').update(normaliseValue(value)).digest('hex')

/**
 * This function will generate a customer ID based on email address to mask email at client-side level.
 *
 * See https://redballoon.atlassian.net/wiki/spaces/BID/pages/2531196985/Hash+Email+Generation#Profile-Matching for more details
 */
export const generateCustomerId = (email: string): string =>
  createHash('sha256')
    .update(`${CUSTOMER_ID_SALT_PREFIX}-${normaliseValue(email)}-${CUSTOMER_ID_SALT_SUFFIX}`)
    .digest('hex')

/**
 * All page props will surely includes agent configurations when data is queried from the server.
 **************
 * Except for when environment is changed to server, get Segment-related stuff out from the agent configuration.
 */
export const getSegmentConfiguration = (agentConfig: AgentConfigurationResponse | undefined): SegmentConfiguration => {
  if (!isClient() || isCypressRunning.check()) {
    return {}
  }

  const { segmentApiHost, segmentCdnUrl, segmentKey, currencyCode } = agentConfig || {}

  const isDev = getEnv() === 'DEV'
  const isPreprodHost =
    // If the current agent is ExOZ and hostname has "preprod"
    (agentConfig?.isBrgAgent && /\-preprod/gi.test(window.location.hostname)) ||
    // Or the hostname has "stage" as subdomain
    /^https\:\/\/stage\.(?=virgin)/gi.test(window.location.hostname)
  const segmentConfig: SegmentConfiguration = { siteName: agentConfig?.siteName }

  // This will make default domain name as "experienceoz"
  const defaultDomainName = DEFAULT_BRAND_GROUP.replace(/\s/g, '').toLowerCase()
  // Extension might be either "-uat" or "-preprod" in development environment, or empty string in production environment
  // TODO: isPreprodHost ? STAGING_PROXY_ENDPOINT_EXTENSION when proxy is available for preprod
  const domainExtension = !isDev ? '' : isPreprodHost ? DEV_PROXY_ENDPOINT_EXTENSION : DEV_PROXY_ENDPOINT_EXTENSION

  const defaultApiHostDomainParts: string[] = [
    // This is "segment"
    CUSTOM_PROXY_SUBDOMAIN_NAME,
    // This is either "experienceoz", "experienceoz-uat" or "experienceoz-preprod"
    `${defaultDomainName}${domainExtension}`,
    // This is "com.au"
    DEFAULT_TOP_LEVEL_DOMAIN,
  ]

  const defaultCdnUrlDomainParts: string[] = [
    // This is "analytics"
    CUSTOM_CDN_SUBDOMAIN_NAME,
    // This is either "experienceoz", "experienceoz-uat" or "experienceoz-preprod"
    `${defaultDomainName}${domainExtension}`,
    // This is "com.au"
    DEFAULT_TOP_LEVEL_DOMAIN,
  ]

  // In preprod and while running Cypress test, the write key can be acquired from environment variables
  if (isDev || isPreprodHost) {
    if (agentConfig?.isBrgAgent) {
      if (process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
        segmentConfig.writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
      }
    } else {
      const envKey = `NEXT_PUBLIC_SEGMENT_${
        agentConfig?.partnerCode ? `${agentConfig?.partnerCode.toUpperCase()}_` : ''
      }WRITE_KEY`

      if (process.env[envKey]) {
        segmentConfig.writeKey = process.env[envKey]
      }
    }

    // Prioritise Segment write key coming from configuration in non-preprod environment
    if (!isPreprodHost) {
      segmentConfig.writeKey = segmentKey || segmentConfig.writeKey
    }
  } else {
    segmentConfig.writeKey = segmentKey
  }

  // There's no point in tracking if write key does not exist, return empty as expected
  if (!segmentConfig.writeKey) {
    return {}
  }

  if (process.env.NEXT_PUBLIC_SEGMENT_CDN_URL) {
    segmentConfig.cdnUrl = `https://${process.env.NEXT_PUBLIC_SEGMENT_CDN_URL}`
  }

  if (process.env.NEXT_PUBLIC_SEGMENT_PROXY_URL) {
    segmentConfig.apiHost = process.env.NEXT_PUBLIC_SEGMENT_PROXY_URL
  }

  if (segmentCdnUrl) {
    segmentConfig.cdnUrl = `https://${segmentCdnUrl}`
  }

  if (segmentApiHost) {
    segmentConfig.apiHost = segmentApiHost
  }

  if (!segmentConfig.brandGroup && agentConfig?.handlerKey) {
    //!! Assuming that handler key is snake case
    segmentConfig.brandGroup = agentConfig.handlerKey
      .split('_')
      .map((string) => `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`)
      .join(' ')
  }

  // In case API host or CDN URL are undefined, either of them will fallback to default value, this is the last resort only
  if (!segmentConfig.apiHost) {
    segmentConfig.apiHost = `${defaultApiHostDomainParts.join('.')}/v1`
  }

  if (!segmentConfig.cdnUrl) {
    segmentConfig.cdnUrl = `https://${defaultCdnUrlDomainParts.join('.')}`
  }

  if (currencyCode) {
    segmentConfig.currencyCode = currencyCode
  }

  return segmentConfig
}

/**
 * There will be more events coming into AnalyticsProvider in the future and some of them might not be meant for Segment.
 ***************
 * Setting this up will ensure that we would be able to filter then out when needed to avoid type error.
 */
export const transformEventForSegment = (event: AnalyticsTrackEvent): SegmentAnalyticsCall | null => {
  // TODO: Set up a switch statement to exclude event when needed. For now, any event can go through.

  return transformObjectActionEventForSegment(event)
}

/**
 * Transform the object action into proper Segment method parameters
 */
export const transformObjectActionEventForSegment = (event: AnalyticsTrackEvent): SegmentAnalyticsCall => {
  const parameters: [string, SegmentEventProperties] | [string, SegmentEventProperties, Options] = [
    `${event.object} ${event.action}`,
    event.properties,
  ]

  if (event.context) {
    parameters.push(event.context)
  }

  return { method: 'track', parameters }
}

/**
 * Process all context data and restructure it so that Segment could pick it up correctly
 */
export const processContextData = (inputContext: Options): ContextDataProcessResult | undefined => {
  const result: ContextDataProcessResult = {}

  const { context, integrations } = inputContext

  // If integrations object exists, add it to both context and outer integrations object
  // This is useful when tracking preferences are introduced, which allows the tracking
  // library to know what destinations/downstream systems should receive the data.
  if (integrations && Object.keys(integrations).length) {
    const segmentIntegrations: { [integration: string]: boolean } = {}
    const contextIntegrations: { [integration: string]: JSONObject } = {}

    for (const integrationKey in integrations) {
      const integration = integrations[integrationKey]

      if (integration !== undefined) {
        if (typeof integration === 'boolean') {
          segmentIntegrations[integrationKey] = integration
        } else {
          // @ts-ignore TODO: fix type
          contextIntegrations[integrationKey] = integration
        }
      }
    }

    if (Object.keys(contextIntegrations).length || Object.keys(segmentIntegrations).length) {
      result.context = result.context || {}
      result.context = {
        ...(Object.keys(contextIntegrations).length ? { context: { integrations: contextIntegrations } } : {}),
        ...(Object.keys(segmentIntegrations).length ? { integrations: segmentIntegrations } : {}),
      }
    }
  }

  // If context exists
  if (context) {
    const { campaign, ...otherCtx } = context

    // If custom campaign data from cache is mixed in, move it into event properties instead.
    // It is captured automatically by Segment from landing page, which will page be added to the session
    // data sent to client-side destinations/downstream systems (if required) such as GA, Mixpanel...
    // Adding additional campaign data helps data analytics to get more information regarding campaigns.
    if (campaign) {
      result.additionalEventProperties = { campaign }
    }

    // Other context data can be added to the context object normally
    if (Object.keys(otherCtx).length) {
      result.context = result.context || {}
      result.context = {
        ...result.context,
        context: {
          ...result.context.context,
          ...otherCtx,
        },
      }
    }
  }

  // If no additional pieces of data can be acquired, return nothing
  if (!result.context && !result.additionalEventProperties) {
    return
  }

  return result
}

/**
 * Handle all track events
 */
const trackEventHandler = async (
  segment: Pick<AnalyticsBrowser, 'identify' | 'page' | 'reset' | 'track'>,
  eventName: string,
  eventProperties: SegmentEventProperties,
  contextData?: Options
) => {
  let response: Context

  if (!contextData) {
    response = await segment.track(eventName, eventProperties)
  } else {
    response = await segment.track(eventName, eventProperties, contextData)
  }

  // When user signs out, ensure to untie events to the current user starting from this moment and generate a new anonymous user
  if (eventName === 'User Signed Out') {
    segment.reset()

    const hostName = window.location.hostname
    const domain = hostName.indexOf('localhost') !== -1 ? hostName : getCurrentDomain(hostName)

    if (domain) {
      const cookiePrefixes = new Set([
        DEFAULT_FBC_COOKIE_PREFIX,
        DEFAULT_FBP_COOKIE_PREFIX,
        DEFAULT_GA_CLIENT_ID_COOKIE_PREFIX,
        DEFAULT_GA_SESSION_COOKIE_PREFIX,
      ])
      const cookies = window.document.cookie.split(';')

      for (const cookie of cookies) {
        const cookieName = cookie.split('=').shift()?.trim()

        if (cookieName) {
          const cookieName3Prefix = cookieName.slice(0, 3)
          const cookieName4Prefix = cookieName.slice(0, 4)

          // Only remove cookies with prefix related to either GA or FB since they are used as external identifers
          if (cookiePrefixes.has(cookieName3Prefix) || cookiePrefixes.has(cookieName4Prefix)) {
            // Remove from both current and default path to ensure this cookie is removed completely
            window.document.cookie = `${cookieName}=; max-age=0; domain=${domain};`
            window.document.cookie = `${cookieName}=; max-age=0; domain=${domain}; path=/;`
          }
        }
      }
    }
  }

  return
}

/**
 * Send events to Segment
 */
// TODO: Migrate Identify and Page events and reset to here, currently only supports Track events
export const trackEventToSegment = async ({
  segment,
  event,
  context,
}: TrackEventToSegmentInput): Promise<SegmentTrackEventReturnType> => {
  const segmentAnalyticsCall = transformEventForSegment(event)

  if (!segmentAnalyticsCall) {
    return null
  }

  const { method, parameters } = segmentAnalyticsCall

  switch (method) {
    case 'identify':
      return segment.identify(...parameters)
    case 'reset':
      return segment.reset() // stop logging further event activity to that user after successfully sign-out occurs
    case 'track': {
      const [eventName, properties, optionalContext] = parameters

      // If no context data from cache/provider could be found, do not send it
      if (!context) {
        if (!optionalContext) {
          return trackEventHandler(segment, eventName, properties)
        }

        return trackEventHandler(segment, eventName, properties, optionalContext)
      }

      if (optionalContext) {
        context.context = {
          ...context.context,
          ...optionalContext.context,
        }

        context.integrations = {
          ...context.integrations,
          ...optionalContext.integrations,
        }

        if (context.traits && optionalContext.traits) {
          context.traits = {
            ...context.traits,
            ...optionalContext.traits,
          }
        }
      }

      const contextData = processContextData(context)

      if (!contextData) {
        return trackEventHandler(segment, eventName, properties)
      }

      const { context: ctxData, additionalEventProperties } = contextData

      // If additional event properties from cache is required, migrate it into event properties
      if (additionalEventProperties) {
        for (const key in additionalEventProperties) {
          properties[key] = additionalEventProperties[key]
        }
      }

      // If no additional context data would be sent, send data to Segment without it
      if (!ctxData) {
        return trackEventHandler(segment, eventName, properties)
      }

      // Otherwise, add context data to Segment track call as the second-last function argument.
      // The last argument is a callback function with response payload from Segment Tracking API,
      // it should be ignored to avoid callback hell.
      // Such data can be acquired from the result of this method in an asynchronous way.
      return trackEventHandler(segment, eventName, properties, ctxData)
    }
    default: {
      const _exhaustive: never = method
      return _exhaustive
    }
  }
}

export const stubSegmentEnv = () => {
  const identify = (id?: ID | UserTraits, traits?: UserTraits) => {
    if (id) {
      if (typeof id === 'string') {
        window?.localStorage?.setItem(SEGMENT_USER_ID_KEY_NAME, id)
      } else {
        window?.localStorage?.setItem(SEGMENT_USER_TRAITS_KEY_NAME, JSON.stringify(id))
      }
    }

    if (traits) {
      window?.localStorage?.setItem(SEGMENT_USER_TRAITS_KEY_NAME, JSON.stringify(traits))
    }
  }

  const getUserId = () => {
    const cachedId = window?.localStorage?.getItem(SEGMENT_USER_ID_KEY_NAME)

    if (!cachedId) {
      return
    }

    return JSON.parse(cachedId)
  }

  const getUserTraits = (): UserTraits | undefined => {
    try {
      const cachedTraits = window?.localStorage?.getItem(SEGMENT_USER_TRAITS_KEY_NAME)

      if (cachedTraits) {
        return JSON.parse(cachedTraits)
      }

      return
    } catch (error) {
      return
    }
  }

  const analyticsBrowser = {
    addDestinationMiddleware: cy.stub().as('addDestinationMiddleware'),
    addSourceMiddleware: cy.stub().as('addSourceMiddleware'),
    identify: cy.stub().as('identify').callsFake(identify),
    reset: cy.stub().as('reset'),
    page: cy.stub().as('page'),
    track: cy.stub().as('track'),
    user: cy
      .stub()
      .as('user')
      .returns({
        id: cy.stub().as('userId').callsFake(getUserId),
        traits: cy.stub().as('userTraits').callsFake(getUserTraits),
      }),
  }

  cy.stub(isCypressRunning, 'check').returns(false)
  cy.stub(AnalyticsBrowser, 'load').as('load').returns(analyticsBrowser)
}
