import { DefaultPageResponse } from '@/types'
import { useContext } from 'react'
import { DefaultPropertiesContext } from '@/context/DefaultPropertiesContext'
import { isClient } from '@/helpers/ClientSideRenderHelper'

export const useDefaultProperties = (getLoyaltyCampaign = false): DefaultPageResponse | undefined => {
  const context = useContext(DefaultPropertiesContext)

  // An error message has already been logged when getting loyalty campaign
  if (!context && isClient() && !getLoyaltyCampaign) {
    console.error('Default properties not found, useDefaultProperties() returns undefined')
  }

  return context
}
