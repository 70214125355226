import {
  CheckoutStepCompletedEventProperties,
  OrderListItemProperties,
  ProductBaseProperties,
} from '@segment/analytics-next'

import { DEFAULT_CURRENCY_CODE } from '@/constant/segment'
import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, SegmentCartData, SegmentCartItem } from '@/types'
import { centsToDollars } from '@big-red-group/storefront-common-checkout'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'

type CreateCheckoutStepCompletedEvent = {
  step: number
  stepLabel: string
  cartData?: Omit<SegmentCartData, 'items'>
  cartItemsData?: { [k: string]: SegmentCartItem }
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Checkput+Step+Completed#Properties
 */
export const createCheckoutStepCompletedEvent = ({
  step,
  stepLabel,
  cartData,
  cartItemsData,
}: CreateCheckoutStepCompletedEvent): Extract<
  AnalyticsTrackEvent,
  { object: 'Checkout Step'; action: 'Completed' }
> => {
  const eventProperties: CheckoutStepCompletedEventProperties = {
    step,
    step_name: stepLabel,
    cart_id: cartData?.uuid || '',
    checkout_id: cartData?.uuid || '',
    total: centsToDollars(roundDecimalNumber(cartData?.total || 0)),
    coupon: cartData?.couponPromoCode?.coupon?.code || '',
    discount: centsToDollars(roundDecimalNumber(cartData?.couponDiscount || 0)),
    tax: 0,
    promo_code: cartData?.couponPromoCode?.promoCode?.code || '',
    currency: 'AUD',
    payments: [],
    products: [],
  }

  if (cartItemsData) {
    // Grab additional discount from item
    const { itemDiscount, products } = Object.values(cartItemsData).reduce<{
      itemDiscount: number
      products: (ProductBaseProperties & OrderListItemProperties)[]
    }>(
      (result, cartItem) => ({
        itemDiscount: result.itemDiscount + Math.abs(cartItem.discount!),
        // itemDiscount: result.itemDiscount + Math.abs(cartItem.subTotal - cartItem.total),
        products: [
          ...result.products,
          ...(cartItem.allOptions || []).map<ProductBaseProperties & OrderListItemProperties>(
            ({ optionId, quantity, price, productOptionName, sessionId, sessionName }) => {
              const productBaseProperties = makeProductBaseProperties({ product: cartItem })

              return {
                ...productBaseProperties,
                // Refer to BookingModal.tsx, line 258 for more info
                // is_gift: (cartItem.handlerKey || '').toLowerCase().indexOf('giftcard') > -1,
                is_gift: cartItem.type === 'EXPERIENCE_VOUCHER' || cartItem.type === 'GIFT_VOUCHER' ? true : false,
                variant_id: optionId,
                variant_name: productOptionName || '',
                session_id: Number(sessionId) || 0,
                session_name: sessionName || '',
                quantity: quantity,
                price: centsToDollars(roundDecimalNumber(price) || 0),
                ...(productOptionName ? { name: `${productBaseProperties.name} | ${productOptionName}` } : {}),
              }
            }
          ),
        ],
      }),
      { itemDiscount: 0, products: [] }
    )

    eventProperties.discount = (eventProperties.discount || 0) + centsToDollars(roundDecimalNumber(itemDiscount))
    eventProperties.products = products

    if (products.length) {
      eventProperties.currency = products[0].currency || DEFAULT_CURRENCY_CODE
    }
  }

  eventProperties.payments = cartData?.payments?.map((payment) => {
    return {
      payment_method: payment.paymentMethod,
      amount: centsToDollars(roundDecimalNumber(payment?.amount || 0)),
    }
  })

  return {
    object: 'Checkout Step',
    action: 'Completed',
    properties: eventProperties,
  }
}
