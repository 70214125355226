import { Wrapper } from './Badge.styles'
import { BadgeColor, BadgeSize } from '@/types'
import Icon, { IconName } from '@/components/Icons'
import { Body } from '@big-red-group/storefront-common-ui'

const Badge: React.FC<{
  children: React.ReactNode
  disabled?: boolean
  dataTestId?: string
  size?: BadgeSize
  icon?: IconName
  color: BadgeColor
}> = ({ children, dataTestId, size = 'S', icon, color }) => {
  return (
    <Wrapper size={size} data-testid={dataTestId} color={color}>
      {icon && <Icon name={icon} />}
      <Body size={size === 'S' ? 'XS' : 'S'} weight="medium">
        {children}
      </Body>
    </Wrapper>
  )
}

export default Badge
