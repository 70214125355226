import { useCallback } from 'react'

import { createSearchModalInteractionEvent, createSearchModalToggleEvent } from '@/events/SearchModalEvents'
import { ProductsSearchedEventInput, TrackSearchModalInput } from '@/types'

import { useAnalytics } from './AnalyticsHook'
import { useProductsSearched } from './AnalyticsProductHook'

export const useAnalyticsSearchModalInteraction = () => {
  const analytics = useAnalytics()
  const trackProductsSearched = useProductsSearched()

  const trackSearchModalInteraction = useCallback(
    (interactionInput: TrackSearchModalInput) => {
      const { interaction, placement, toggle, type, extras } = interactionInput

      if (toggle !== 'search') {
        const toggleEvent = createSearchModalToggleEvent(toggle, placement, type, extras)
        analytics.trackEvent(toggleEvent)

        return
      }

      const {
        searchedInput,
        searchedValue,
        isCategorySearched,
        isLocationSearched,
        isProductClicked = false,
        isProductsSearched = false,
        isAutoCorrectionClicked = false,
        isRecentlyViewed = false,
        isSeeMoreClicked,
      } = interaction

      const productsSearchedInput: ProductsSearchedEventInput = {
        ...(isLocationSearched ? { location: searchedValue || searchedInput } : {}),
        ...(isCategorySearched ? { experience: searchedValue || searchedInput } : {}),
        ...(isProductClicked && !isRecentlyViewed ? { product: searchedValue || searchedInput } : {}),
        ...(!isLocationSearched && !isCategorySearched && !isProductClicked && !isAutoCorrectionClicked && searchedValue
          ? { searchedValue: searchedValue || searchedInput }
          : {}),
        ...(isAutoCorrectionClicked ? { correctionValue: searchedValue || searchedInput } : {}),
        ...(isProductClicked && isRecentlyViewed ? { recentlyViewedProduct: searchedValue || searchedInput } : {}),
      }

      const interactionEvent = createSearchModalInteractionEvent(interaction, placement, type)
      analytics.trackEvent(interactionEvent)

      // If this is a search for products, fire Products Searched event in addition
      if (isProductsSearched) {
        trackProductsSearched(productsSearchedInput, isSeeMoreClicked)
      }
    },
    [analytics, trackProductsSearched]
  )

  return trackSearchModalInteraction
}
