import pino from 'pino'

// use to switch between logging for local dev or production json logging
const logPretty = false

const prettyLogger = pino({
        level: 'info',
        transport: {
            target: 'pino-pretty'
        },
        formatters: {
            level: (label) => {
                return {level: label.toUpperCase()}
            }
        },
        errorKey: 'error',
        // ISO8601 - more human-readable
        timestamp: pino.stdTimeFunctions.isoTime,
    }
);

const datadogLogger = pino({
        level: 'info',
        formatters: {
            // remap level to value aligned with backend log level for filtering by the same values in Datadog
            level: (label) => {
                return {level: label.toUpperCase()}
            },
        },
        errorKey: 'error',
        // Milliseconds since Unix epoch is the Default, and Datadog can handle that too, ISO8601 is just more human-readable
        timestamp: pino.stdTimeFunctions.isoTime,
    }
);

export const pinoLogger = logPretty ? prettyLogger : datadogLogger;
