import { Wrapper } from '@/components/Label.styles'
import { BrandColor } from '@big-red-group/storefront-common-ui'

type LabelTag = 'label' | 'span'

const Body: React.FC<{
  children: React.ReactNode
  forInput?: string
  as?: LabelTag
  color?: BrandColor
  dataTestId?: string
  className?: string
}> = ({ children, forInput, as = 'label', color, dataTestId, className }) => {
  return (
    <>
      <Wrapper as={as} htmlFor={forInput} data-testid={dataTestId} color={color} className={className}>
        {children}
      </Wrapper>
    </>
  )
}

export default Body
