import { ImageEventAction, ImageViewedEventProperties } from '@segment/analytics-next'

import { DEFAULT_BRAND_GROUP, DEFAULT_BRAND_TYPE } from '@/constant/segment'
import { createCompositeKey } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, TrackImageInput } from '@/types'

export const createImageEvent = (
  brand: string,
  { action, clickedFrom, cyElement, image, images, imageUrl, position, success = true, showPopup }: TrackImageInput
): Extract<AnalyticsTrackEvent, { object: 'Image'; action: ImageEventAction }> => {
  let imageId = ''
  let imageAlt = ''
  let imageTitle = ''
  let url = ''

  // Overwrite input when having Cypress element
  if (cyElement) {
    const backgroundImage = cyElement.css('background-image') || ''
    const imgUrlMatch = /^url\(['"](.*?)['"]\)/gi.exec(backgroundImage)

    imageUrl = imgUrlMatch?.[1] || cyElement.attr('src') || url

    if (images?.length) {
      position = images.findIndex((url) => url === imageUrl) + 1
    }

    imageId = cyElement.attr('id') || position.toString()
    imageAlt = cyElement.attr('alt') || imageAlt
    imageTitle = cyElement.attr('title') || imageAlt
    url = imgUrlMatch?.[1] || cyElement.attr('src') || url
  } else {
    imageId = image?.id || position.toString()
    imageAlt = image?.alt || imageAlt
    imageTitle = image?.title || imageTitle
    url = imageUrl || image?.src || url
  }

  const eventProperties: ImageViewedEventProperties = {
    image_id: createCompositeKey(imageId),
    image_alt: imageAlt,
    image_title: imageTitle,
    image_url: url,
    position, // Position in the array
    is_default: position === 1,
    brand,
    brand_group: DEFAULT_BRAND_GROUP,
    brand_type: DEFAULT_BRAND_TYPE,
    clicked_from: clickedFrom,
    show_popup: showPopup,
  }

  if (action === 'Viewed') {
    eventProperties.success = success
  }

  return {
    object: 'Image',
    action,
    properties: eventProperties,
  }
}
