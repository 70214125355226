import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@/constant/ui'
import { ContainerSize } from '@/types'

const getContainerSize = (size: ContainerSize): string => {
  switch (size) {
    case 'narrow':
      return '700px'
    case 'standard':
      return '1296px'
    case 'fullWidth':
      return '100%'
  }
}

export const Wrapper = styled.div<{ size: ContainerSize; fullWidthMobile: boolean; fullWidthDesktop: boolean }>`
  width: ${({ size }) => getContainerSize(size)};
  max-width: ${({ size }) => (size === 'fullWidth' ? '100' : '90')}%;
  margin: ${({ size }) => (size === 'fullWidth' ? '0' : '0 auto')};
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: ${({ size }) => (size === 'fullWidth' ? '0' : '0 16px')};
    width: 100%;
    max-width: unset;

    ${({ fullWidthMobile }) =>
      fullWidthMobile &&
      `
      width: 100%;
      padding: 0;
  
    `}
  }

  @media (min-width: ${BREAKPOINT_SMALL}px) {
    ${({ fullWidthDesktop }) =>
      fullWidthDesktop &&
      `
      width: auto;
      padding: 0;
      margin: 0;
 
    `}
  }
`
