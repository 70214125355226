import { isClient } from './ClientSideRenderHelper'

export class FetchError extends Error {
  constructor(message: string, response: Response) {
    super(message)
    this.name = 'FetchError'
    this.cause = response
  }
}

export class UnhandledPromiseError extends Error {
  constructor(message: string, response: Response) {
    super(message)
    this.name = 'UnhandledPromiseError'
    this.cause = response
  }
}

export class CustomError extends Error {
  constructor(name: string, message: string) {
    super(message)
    this.name = name
  }
}

export class UnknownError extends Error {
  constructor(message: string = 'Unknown error detected') {
    super(message)
    this.name = 'UnknownError'
  }
}

export class FetchErrorEvent extends Event {
  readonly err: FetchError

  constructor(error: FetchError) {
    super('fetchrejection')
    this.err = error
  }
}

export class CustomErrorEvent extends Event {
  readonly err: Error | CustomError

  constructor(error: Error | CustomError) {
    super('customerror')
    this.err = error
  }
}

export const dispatchErrorEvent = (error: Error | CustomError | FetchError) => {
  if (isClient()) {
    if (error instanceof FetchError) {
      window.dispatchEvent(new FetchErrorEvent(error))
    } else {
      window.dispatchEvent(new CustomErrorEvent(error))
    }
  }
}
