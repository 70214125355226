import { ProductViewedEventProperties } from '@segment/analytics-next'

import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, ProductDetails } from '@/types'

type CreateProductViewedEvent = {
  product: ProductDetails
  productLayout?: string
  isProductEdit?: boolean
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Product+Viewed#Properties
 */
export const createProductViewedEvent = ({
  product,
  productLayout = 'productGalleryHero',
  isProductEdit = false,
}: CreateProductViewedEvent): Extract<AnalyticsTrackEvent, { object: 'Product'; action: 'Viewed' }> => {
  if (productLayout === 'productMinimal') {
    product.images = []
  }

  const productBaseProperties = makeProductBaseProperties({
    product,
    includeSessions: true,
  })

  const eventProperties: ProductViewedEventProperties = {
    ...productBaseProperties,
    is_product_edit: isProductEdit,
    product_layout: productLayout === 'productMinimal' ? 'Minimal' : 'Gallery Hero',
  }

  return {
    object: 'Product',
    action: 'Viewed',
    properties: eventProperties,
  }
}
