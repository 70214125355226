import { useEffect } from 'react'

import { isClient } from '@/helpers/ClientSideRenderHelper'
import { useAnalyticsError } from '@/hooks/AnalyticsErrorHook'
import {
  CustomError,
  CustomErrorEvent,
  FetchError,
  FetchErrorEvent,
  UnhandledPromiseError,
  UnknownError,
} from '@/helpers/ErrorHelper'
import { logger } from '@/logging/Logger'

const log = logger()

export const AnalyticsErrorTracker: React.FC = () => {
  const errorOccurred = useAnalyticsError()

  useEffect(() => {
    if (isClient()) {
      /**
       * Global error handler to catch unhandled errors
       */
      const commonEventHandler = async (
        event: CustomErrorEvent | Event | ErrorEvent | PromiseRejectionEvent | FetchErrorEvent
      ) => {
        try {
          let error: CustomError | Error | FetchError | UnhandledPromiseError | UnknownError

          // When a fetch error is captured, the error will be available from the top level event property
          if (event instanceof FetchErrorEvent || event instanceof CustomErrorEvent) {
            error = event.err
          }
          // When an image, snippet or something requiring asynchronous load fails, it will be captured here
          else if (event instanceof PromiseRejectionEvent) {
            const res = await event.promise

            if (res instanceof Response) {
              error = new UnhandledPromiseError(`Promise rejected: ${res.statusText}`, res)
            } else {
              error = new UnknownError('Unknown PromiseRejectionEvent error')
            }
          }
          // Any other browser errors will be captured here
          else if (event instanceof ErrorEvent) {
            if (event.error instanceof Error) {
              error = event.error
            } else {
              error = new UnknownError(`Encountered error with event type "${event.type}" with unknown cause`)
            }
          }
          // Otherwise, set the error as generic one
          else {
            error = new Error(`Encountered error with event type "${event.type}"`)
          }

          errorOccurred(error, event.isTrusted)
        } catch (error) {
          log.error('Error', {}, error as Error)
        }
      }

      const events = {
        // error: For other exceptions like Javascript runtime errors or failures to load a resource
        error: commonEventHandler,
        // customerror: For any custom error made during user flow
        customerror: commonEventHandler,
        // unhandledrejection: For unhandled promise rejections
        unhandledrejection: commonEventHandler,
        // fetchrejection: For any HTTPS request made by the app, such as get app resource, get user, get cart...
        fetchrejection: commonEventHandler,
      }

      Object.keys(events).forEach((key) => window.addEventListener(key, events[key as keyof typeof events]))

      // Clean up function
      return () => {
        Object.keys(events).forEach((key) => window.removeEventListener(key, events[key as keyof typeof events]))
      }
    }
  }, [errorOccurred])

  return null
}
