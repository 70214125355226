import { ErrorOccurredEventProperties } from '@segment/analytics-next'

import { AnalyticsTrackEvent } from '@/types'

type CreateErrorOccurredEvent = {
  errorCode: number
  errorType: string
  errorMessage: string
  isTrusted: boolean
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Error+Occurred#Properties
 */
export const createErrorOccurredEvent = ({
  errorCode,
  errorType,
  errorMessage,
  isTrusted,
}: CreateErrorOccurredEvent): Extract<AnalyticsTrackEvent, { object: 'Error'; action: 'Occurred' }> => {
  const eventProperties: ErrorOccurredEventProperties = {
    error_code: 0,
    error_type: errorType,
    error_message: errorMessage,
    is_trusted: isTrusted,
  }

  if (Number.isSafeInteger(errorCode)) {
    eventProperties.error_code = errorCode
  }

  return {
    object: 'Error',
    action: 'Occurred',
    properties: eventProperties,
  }
}
