import { IconName } from '@/components/Icons'

export const STRIPE_AFTER_PAY = 'afterpay_clearpay'
export const STRIPE_ZIP = 'zip'

export const DEFAULT_PAYMENT_ERROR_MESSAGE =
  'Unfortunately, we have had issues processing your payment. Please try again or contact customer support.'

export const DEFAULT_ORDER_ERROR_MESSAGE =
  'Unfortunately, we have had issues processing your order. Please try again or contact customer support.'

export type PaymentError = 'DEFAULT' | 'CAPTURE' | 'ORDER_FAILED' | 'PAYMENT_INFO_NOT_FOUND'

export const ORDER_ERROR_MAPPING: { [key in PaymentError]?: string } = {
  DEFAULT: DEFAULT_PAYMENT_ERROR_MESSAGE,
  CAPTURE: DEFAULT_PAYMENT_ERROR_MESSAGE,
  ORDER_FAILED: DEFAULT_ORDER_ERROR_MESSAGE,
  PAYMENT_INFO_NOT_FOUND: 'Payment information not found.',
}

export const PAYMENT_METHODS: IconName[] = [
  'visaInverse',
  'mastercardInverse',
  'afterpayIconInverse',
  'zipInverse',
  'applePayInverse',
  'googlePayInverse',
  'amexInverse',
]
