import { useContext } from 'react'

import { AnalyticsMetaDataContext } from '@/context/AnalyticsMetaDataContext'
import { isClient } from '@/helpers/ClientSideRenderHelper'
import { AnalyticsMetaData } from '@/types'

export const useAnalyticsMetaData = (isDisplayErrors = false): AnalyticsMetaData => {
  const context = useContext(AnalyticsMetaDataContext)

  if (!context && isClient() && !isDisplayErrors) {
    throw new Error('useAnalyticsMetaData() must be used within an <AnalyticsMetaDataProvider />')
  }

  return context!
}
