import { AnalyticsProductAvailableSession, ProductSessionShownEventProperties } from '@segment/analytics-next'

import { AnalyticsTrackEvent, CreateProductSessionShownEventInput } from '@/types'
import { adjustGeolocations, getTourHourIn12HourFormat, makeProductBaseProperties } from '../helpers/AnalyticsHelper'

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2515930160/Product+Session+Shown#Properties
 */
export const createProductSessionShownEvent = ({
  product,
  url,
  checkedSessionDate,
  productAllSessions,
  availableSessionLength,
  otherSessionLength,
  listId,
  listName,
  isProductEdit = false,
}: CreateProductSessionShownEventInput): Extract<
  AnalyticsTrackEvent,
  { object: 'Product Session'; action: 'Shown' }
> => {
  const updatedProductAllSessions = productAllSessions?.map((session) => {
    const { options: selectedOptions, productOptions } = session

    const updatedOptions = selectedOptions?.map((option) => {
      const matchedOptions = productOptions?.find((productOption) => productOption.id === option.id)
      if (matchedOptions) {
        return { ...matchedOptions, quantity: option?.quantity }
      }
    })
    return { ...session, options: updatedOptions }
  })

  const productBaseProperties = makeProductBaseProperties({ product })

  const { geolocations } = product
  const locations = geolocations ? adjustGeolocations(geolocations) : []

  const eventProperties: ProductSessionShownEventProperties = {
    ...productBaseProperties,
    url,
    locations,
    checked_session_date: checkedSessionDate.toISOString(),
    on_date_sessions: availableSessionLength!,
    other_sessions: otherSessionLength,
    available_sessions: updatedProductAllSessions?.map<AnalyticsProductAvailableSession>(
      ({
        id,
        sessionName: session_name,
        hasPickups: has_pickups,
        isExtra: is_extra,
        options,
        tourDuration: duration,
        tourMinute,
        tourHour,
        status,
        sessionDate,
      }) => ({
        id,
        session_name,
        has_pickups,
        is_extra,
        variants: options?.map((option) => ({
          variant_id: option?.id,
          variant_name: option?.name,
          quantity: option?.quantity,
        })),
        is_available: status !== 'UNAVAILABLE',
        public_call_required: status === 'PUBLIC_CALL',
        session_date: sessionDate,
        duration,
        ...getTourHourIn12HourFormat(tourHour, tourMinute),
      })
    ),
    list_id: listId || '',
    list_name: listName || '',
    is_product_edit: isProductEdit,
  }

  return {
    object: 'Product Session',
    action: 'Shown',
    properties: eventProperties,
  }
}
