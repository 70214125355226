import { useContext } from 'react'

import { AnalyticsContext } from '@/context/AnalyticsContext'
import { CommonAnalyticsContext } from '@/types'

export const useAnalytics = (): CommonAnalyticsContext => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error('useAnalytics() must be used within an <AnalyticsProvider />')
  }

  return context
}
