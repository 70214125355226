import { createCipheriv, createDecipheriv, createHash, randomBytes } from 'crypto'

import { ENCRYPTION_ALGORITHM, ENCRYPTION_OUTPUT_ENCODING, SAVED_CACHE_REGEXP } from '../constant/segment'

//TODO move to prop files and env variables

const secret = createHash('sha512')
  .update(process.env.NEXT_PUBLIC_CRYPTO_ENCRYPTION_KEY ?? '', 'utf-8')
  .digest('hex')
  .substr(0, 32)

const iv = createHash('sha512')
  .update(process.env.NEXT_PUBLIC_CRYPTO_ENCRYPTION_IV ?? '', 'utf-8')
  .digest('hex')
  .substr(0, 16)

export const encrypt_string = (plain_text: string) => {
  const encryptor = createCipheriv(process.env.NEXT_PUBLIC_CRYPTO_SECRET_ENCRYPTION_METHOD ?? 'AES-256-CBC', secret, iv)
  const aes_encrypted = encryptor.update(plain_text, 'utf8', 'hex') + encryptor.final('hex')
  return Buffer.from(aes_encrypted).toString('hex')
}

export const decrypt_string = (encryptedMessage: string) => {
  const buff = Buffer.from(encryptedMessage, 'hex')
  encryptedMessage = buff.toString('utf-8')
  const decryptor = createDecipheriv(
    process.env.NEXT_PUBLIC_CRYPTO_SECRET_ENCRYPTION_METHOD ?? 'AES-256-CBC',
    secret,
    iv
  )
  return decryptor.update(encryptedMessage, 'hex', 'utf8') + decryptor.final('utf8')
}

export const encryptJSON = (data: { [k: string | number | symbol]: any }): [string, Buffer, Buffer, Buffer] => {
  const randomKey = randomBytes(32)
  const randomIv = randomBytes(16)

  const cipher = createCipheriv(ENCRYPTION_ALGORITHM, randomKey, randomIv)

  let encryptedText = cipher.update(JSON.stringify(data), 'utf-8', ENCRYPTION_OUTPUT_ENCODING)
  encryptedText += cipher.final(ENCRYPTION_OUTPUT_ENCODING)

  // Get auth tag for authentication when decrypting
  const authTag = cipher.getAuthTag()

  return [encryptedText, randomKey, randomIv, authTag]
}

export const decryptJSON = (
  ...args: [encryptedText: string, key: Buffer, iv: Buffer, authTag: Buffer, ...rest: any[]]
): string => {
  const [encryptedText, key, iv, authTag] = args

  const decipher = createDecipheriv(ENCRYPTION_ALGORITHM, key, iv)

  decipher.setAuthTag(authTag)

  let decryptedText = decipher.update(encryptedText, ENCRYPTION_OUTPUT_ENCODING, 'utf-8')
  decryptedText += decipher.final('utf-8')

  return decryptedText
}

export const constructCacheString = (
  ...args: [output: string, key: Buffer, iv: Buffer, authTag: Buffer, ...rest: any[]]
): string => {
  const [output, key, iv, authTag] = args

  const keyString = key.toJSON().data.toString()
  const ivString = iv.toJSON().data.toString()
  const authTagString = authTag.toJSON().data.toString()

  return `${output}.[${keyString}].[${ivString}].[${authTagString}]`
}

export const destructureCacheString = (cacheString: string): [string, Buffer, Buffer, Buffer] => {
  const cacheSplit = cacheString.split('.')

  if (cacheSplit.length != 4) {
    throw new Error(
      `Invalid cached string detected, it should have 4 elements after split but received ${cacheSplit.length} element(s)`
    )
  }

  if (!new RegExp(SAVED_CACHE_REGEXP, 'ig').test(cacheString)) {
    throw new Error('Invalid cache string detected')
  }

  const keySplit = Buffer.from(JSON.parse(cacheSplit[1]))
  const ivSplit = Buffer.from(JSON.parse(cacheSplit[2]))
  const authTagSplit = Buffer.from(JSON.parse(cacheSplit[3]))

  return [cacheSplit[0], keySplit, ivSplit, authTagSplit]
}
