import HeartIcon from '../../public/icons/HeartIcon.svg'
import StarFilledIcon from '../../public/icons/StarFilledIcon.svg'
import SearchIcon from '../../public/icons/SearchIcon.svg'
import TeaCupIcon from '../../public/icons/TeaCupIcon.svg'
import FlameIcon from '../../public/icons/FlameIcon.svg'
import BusIcon from '../../public/icons/BusIcon.svg'
import StopwatchIcon from '../../public/icons/StopwatchIcon.svg'
import LeafIcon from '../../public/icons/LeafIcon.svg'
import Wishlist from '../../public/icons/WishlistIcon.svg'
import DrawnCircleIcon from '../../public/icons/DawnCircleIcon.svg'
import DrawnTextBreakIcon from '../../public/icons/DrawnTextBreak.svg'
import RespectsIcon from '../../public/icons/RespectsIcon.svg'
import YoutubeIcon from '../../public/icons/YoutubeIcon.svg'
import FacebookIcon from '../../public/icons/FacebookIcon.svg'
import FacebookLetterIcon from '../../public/icons/FacebookLetterIcon.svg'
import InstagramIcon from '../../public/icons/InstagramIcon.svg'
import ArrowRightIcon from '../../public/icons/ArrowRightIcon.svg'
import ArrowLeftIcon from '../../public/icons/ArrowLeftIcon.svg'
import CaretLeftIcon from '../../public/icons/CaretLeftIcon.svg'
import CaretRightIcon from '../../public/icons/CaretRightIcon.svg'
import CaretUpIcon from '../../public/icons/CaretUpIcon.svg'
import CaretDownIcon from '../../public/icons/CaretDownIcon.svg'
import ChevronDownIcon from '../../public/icons/ChevronDownIcon.svg'
import ShoppingBagIcon from '../../public/icons/ShoppingBagIcon.svg'
import StarRatingIcon from '../../public/icons/StarRatingIcon.svg'
import CloseIcon from '../../public/icons/CloseIcon.svg'
import HighlightTickIcon from '../../public/icons/HighlightTickIcon.svg'
import AfterpayIcon from '../../public/icons/AfterpayLogoIcon.svg'
import GalleryIcon from '../../public/icons/GalleryIcon.svg'
import LocationIcon from '../../public/icons/LocationIcon.svg'
import ArrowCircleLeftIcon from '../../public/icons/ArrowCircleLeftIcon.svg'
import ArrowCircleRightIcon from '../../public/icons/ArrowCircleRightIcon.svg'
import StarOutlineIcon from '../../public/icons/StarOutlineIcon.svg'
import SortIcon from '../../public/icons/SortIcon.svg'
import FilterIcon from '../../public/icons/FilterIcon.svg'
import StrollerIcon from '../../public/icons/StrollerIcon.svg'
import GoogleIcon from '../../public/icons/GoogleIcon.svg'
import MountainsIcon from '../../public/icons/MountainsIcon.svg'
import TicketsIcon from '../../public/icons/TicketsIcon.svg'
import GiftVoucherIcon from '../../public/icons/GiftVoucherIcon.svg'
import MapBrochureIcon from '../../public/icons/MapBrochureIcon.svg'
import LockIcon from '../../public/icons/LockIcon.svg'
import LogoPrimary from '../../public/logos/logo-primary.svg'
import QuestionMarkIcon from '../../public/icons/QuestionMarkIcon.svg'
import CalendarOutlineIcon from '../../public/icons/CalendarOutlineIcon.svg'
import EmailIcon from '../../public/icons/EmailIcon.svg'
import InfoCircleIcon from '../../public/icons/InfoCircleIcon.svg'
import PresentIcon from '../../public/icons/PresentIcon.svg'
import ThumbsUpIcon from '../../public/icons/ThumbsUpIcon.svg'
import CheckmarkCircleIcon from '../../public/icons/CheckmarkCircleIcon.svg'
import ProgressCircleIcon from '../../public/icons/ProgressCircleIcon.svg'
import MailBoxIcon from '../../public/icons/MailBoxIcon.svg'
import PaperPlaneIcon from '../../public/icons/PaperPlaneIcon.svg'
import DisabledCircleIcon from '../../public/icons/DisabledCircleIcon.svg'
import LinkIcon from '../../public/icons/LinkIcon.svg'
import KoalaIcon from '../../public/icons/KoalaIcon.svg'
import TrophyIcon from '../../public/icons/TrophyIcon.svg'
import HeartHandsIcon from '../../public/icons/HeartHandsIcon.svg'
import DollarIcon from '../../public/icons/DollarIcon.svg'
import HeadphonesIcon from '../../public/icons/HeadphonesIcon.svg'
import ErrorIcon from '../../public/icons/ErrorIcon.svg'
import InfoIcon from '../../public/icons/InfoIcon.svg'
import HotDealIcon from '../../public/icons/HotDealIcon.svg'
import AustraliaIcon from '../../public/icons/AustraliaIcon.svg'
import LightningIcon from '../../public/icons/LightningIcon.svg'
import CreditCardErrorIcon from '../../public/icons/CreditCardErrorIcon.svg'
import PersonOutlineIcon from '../../public/icons/PersonOutlineIcon.svg'
import TikTokIcon from '../../public/icons/TikTokIcon.svg'
import CartIcon from '../../public/icons/CartIcon.svg'
import HistoryIcon from '../../public/icons/HistoryIcon.svg'
import TagIcon from '../../public/icons/TagIcon.svg'
import ExchangeIcon from '../../public/icons/ExchangeIcon.svg'
import GiftVoucherOutlineIcon from '../../public/icons/GiftVoucherOutlineIcon.svg'
import RedeemVoucherOutlineIcon from '../../public/icons/RedeemVoucherOutlineIcon.svg'
import EyeIcon from '../../public/icons/EyeIcon.svg'
import EyeSlashIcon from '../../public/icons/EyeSlashIcon.svg'
import AppleIcon from '../../public/icons/AppleIcon.svg'
import ImageIcon from '../../public/icons/ImageIcon.svg'
import GiftVoucherCartIcon from '../../public/icons/giftVoucherCartIcon.svg'
import ApplePayLogo from '../../public/icons/apple pay.svg'
import GooglePayLogo from '../../public/icons/gpay.svg'
import DCBLogo from '../../public/icons/dcb.svg'
import JCBLogo from '../../public/icons/JCB.svg'
import MasterCardLogo from '../../public/icons/Mastercard.svg'
import VisaLogo from '../../public/icons/visa.svg'
import AmexLogo from '../../public/icons/Amex.svg'
import PencilIcon from '../../public/icons/PencilIcon.svg'
import EllipsisIcon from '../../public/icons/EllipsisIcon.svg'
import TickIcon from '../../public/icons/TickIcon.svg'
import MoneyIcon from '../../public/icons/MoneyIcon.svg'
import ChatIcon from '../../public/icons/ChatIcon.svg'
import ZipLogo from '../../public/icons/zip.svg'
import WhatsappIcon from '../../public/icons/whatsappIcon.svg'
import MessengerIcon from '../../public/icons/messengerIcon.svg'
import TwitterIcon from '../../public/icons/twitterIcon.svg'
import CopyIcon from '../../public/icons/copyIcon.svg'
import ShareIcon from '../../public/icons/ShareIcon.svg'
import GiftIcon from '../../public/icons/GiftIcon.svg'
import AfterpayIconLogoInverse from '../../public/icons/AfterpayIconLogoInverse.svg'
import ZipLogoInverse from '../../public/icons/ZipLogoInverse.svg'
import ApplePayLogoInverse from '../../public/icons/ApplePayLogoInverse.svg'
import GooglePayLogoInverse from '../../public/icons/GooglePayLogoInverse.svg'
import AmexLogoInverse from '../../public/icons/AmexLogoInverse.svg'
import MasterCardLogoInverse from '../../public/icons/MasterCardLogoInverse.svg'
import VisaLogoInverse from '../../public/icons/VisaLogoInverse.svg'
import AfterpayAffiliateLogo from '../../public/icons/AfterpayAffiliateLogo.svg'
import NineNewsAffiliateLogo from '../../public/icons/NineNewsAffiliateLogo.svg'
import QantasAffiliateLogo from '../../public/icons/QantasAffiliateLogo.svg'
import RewardGatewayAffiliateLogo from '../../public/icons/RewardGatewayAffiliateLogo.svg'
import ShopbackAffiliateLogo from '../../public/icons/ShopbackAffiliateLogo.svg'
import SitchuAffiliateLogo from '../../public/icons/SitchuAffiliateLogo.svg'
import TimeOutAffiliateLogo from '../../public/icons/TimeOutAffiliateLogo.svg'
import UrbanListAffiliateLogo from '../../public/icons/UrbanListAffiliateLogo.svg'
import TikTokColouredIcon from '../../public/icons/TikTokColouredIcon.svg'
import InstagramColouredIcon from '../../public/icons/InstagramColouredIcon.svg'
import FacebookColouredIcon from '../../public/icons/FacebookColouredIcon.svg'
import BadgeDiscountIcon from '../../public/icons/BadgeDiscountIcon.svg'
import FlagIcon from '../../public/icons/FlagIcon.svg'
import ReceiveMoneyIcon from '../../public/icons/ReceiveMoneyIcon.svg'
import AwardIcon from '../../public/icons/AwardIcon.svg'

export type IconName =
  | 'heart'
  | 'search'
  | 'starFilled'
  | 'teaCup'
  | 'flame'
  | 'bus'
  | 'stopwatch'
  | 'leaf'
  | 'wishlist'
  | 'drawnCircle'
  | 'drawnTextBreak'
  | 'respects'
  | 'youtube'
  | 'facebook'
  | 'facebookLetter'
  | 'instagram'
  | 'google'
  | 'arrowRight'
  | 'arrowLeft'
  | 'chevronDown'
  | 'shoppingBag'
  | 'caretLeft'
  | 'caretRight'
  | 'caretUp'
  | 'caretDown'
  | 'starRating'
  | 'close'
  | 'highlightTick'
  | 'afterpayLogo'
  | 'gallery'
  | 'location'
  | 'arrowCircleLeft'
  | 'arrowCircleRight'
  | 'starOutlined'
  | 'filter'
  | 'sort'
  | 'stroller'
  | 'mountains'
  | 'tickets'
  | 'giftVoucher'
  | 'mapBrochure'
  | 'lock'
  | 'logoPrimary'
  | 'questionMark'
  | 'calendar'
  | 'email'
  | 'present'
  | 'thumbsUp'
  | 'infoCircle'
  | 'checkmarkCircle'
  | 'progressCircle'
  | 'paperPlane'
  | 'mailbox'
  | 'disabledCircle'
  | 'link'
  | 'koala'
  | 'trophy'
  | 'heartHands'
  | 'dollar'
  | 'headphones'
  | 'info'
  | 'error'
  | 'hotDeal'
  | 'australia'
  | 'creditCardError'
  | 'lightning'
  | 'personOutline'
  | 'tikTok'
  | 'cart'
  | 'history'
  | 'tag'
  | 'exchange'
  | 'giftVoucherOutline'
  | 'redeemVoucherOutline'
  | 'eye'
  | 'eyeSlash'
  | 'apple'
  | 'image'
  | 'giftVoucherCartIcon'
  | 'applePayLogo'
  | 'googlePayLogo'
  | 'dcbLogo'
  | 'jcbLogo'
  | 'mastercardLogo'
  | 'visaLogo'
  | 'amexLogo'
  | 'pencil'
  | 'tick'
  | 'ellipsis'
  | 'money'
  | 'chat'
  | 'zipLogo'
  | 'whatsapp'
  | 'messenger'
  | 'twitter'
  | 'copy'
  | 'share'
  | 'gift'
  | 'afterpayIconInverse'
  | 'zipInverse'
  | 'applePayInverse'
  | 'googlePayInverse'
  | 'amexInverse'
  | 'mastercardInverse'
  | 'visaInverse'
  | 'afterpayAffiliate'
  | 'nineNewsAffiliate'
  | 'qantasAffiliate'
  | 'rewardGatewayAffiliate'
  | 'shopbackAffiliate'
  | 'sitchuAffiliate'
  | 'timeOutAffiliate'
  | 'urbanListAffiliate'
  | 'tikTokColoured'
  | 'instagramColoured'
  | 'facebookColoured'
  | 'badgeDiscount'
  | 'flag'
  | 'receiveMoney'
  | 'award'

const icons: Record<IconName, string> = {
  heart: HeartIcon,
  search: SearchIcon,
  starFilled: StarFilledIcon,
  teaCup: TeaCupIcon,
  flame: FlameIcon,
  bus: BusIcon,
  stopwatch: StopwatchIcon,
  leaf: LeafIcon,
  wishlist: Wishlist,
  drawnCircle: DrawnCircleIcon,
  drawnTextBreak: DrawnTextBreakIcon,
  respects: RespectsIcon,
  youtube: YoutubeIcon,
  facebook: FacebookIcon,
  facebookLetter: FacebookLetterIcon,
  instagram: InstagramIcon,
  google: GoogleIcon,
  arrowRight: ArrowRightIcon,
  chevronDown: ChevronDownIcon,
  shoppingBag: ShoppingBagIcon,
  arrowLeft: ArrowLeftIcon,
  caretLeft: CaretLeftIcon,
  caretRight: CaretRightIcon,
  caretUp: CaretUpIcon,
  caretDown: CaretDownIcon,
  starRating: StarRatingIcon,
  close: CloseIcon,
  highlightTick: HighlightTickIcon,
  afterpayLogo: AfterpayIcon,
  gallery: GalleryIcon,
  location: LocationIcon,
  arrowCircleLeft: ArrowCircleLeftIcon,
  arrowCircleRight: ArrowCircleRightIcon,
  starOutlined: StarOutlineIcon,
  sort: SortIcon,
  filter: FilterIcon,
  stroller: StrollerIcon,
  mountains: MountainsIcon,
  tickets: TicketsIcon,
  giftVoucher: GiftVoucherIcon,
  mapBrochure: MapBrochureIcon,
  lock: LockIcon,
  logoPrimary: LogoPrimary,
  questionMark: QuestionMarkIcon,
  calendar: CalendarOutlineIcon,
  email: EmailIcon,
  infoCircle: InfoCircleIcon,
  present: PresentIcon,
  thumbsUp: ThumbsUpIcon,
  checkmarkCircle: CheckmarkCircleIcon,
  progressCircle: ProgressCircleIcon,
  paperPlane: PaperPlaneIcon,
  mailbox: MailBoxIcon,
  disabledCircle: DisabledCircleIcon,
  link: LinkIcon,
  koala: KoalaIcon,
  trophy: TrophyIcon,
  heartHands: HeartHandsIcon,
  dollar: DollarIcon,
  headphones: HeadphonesIcon,
  info: InfoIcon,
  error: ErrorIcon,
  hotDeal: HotDealIcon,
  creditCardError: CreditCardErrorIcon,
  australia: AustraliaIcon,
  lightning: LightningIcon,
  personOutline: PersonOutlineIcon,
  tikTok: TikTokIcon,
  cart: CartIcon,
  history: HistoryIcon,
  tag: TagIcon,
  exchange: ExchangeIcon,
  giftVoucherOutline: GiftVoucherOutlineIcon,
  redeemVoucherOutline: RedeemVoucherOutlineIcon,
  eye: EyeIcon,
  eyeSlash: EyeSlashIcon,
  apple: AppleIcon,
  image: ImageIcon,
  giftVoucherCartIcon: GiftVoucherCartIcon,
  applePayLogo: ApplePayLogo,
  googlePayLogo: GooglePayLogo,
  mastercardLogo: MasterCardLogo,
  visaLogo: VisaLogo,
  dcbLogo: DCBLogo,
  jcbLogo: JCBLogo,
  amexLogo: AmexLogo,
  pencil: PencilIcon,
  ellipsis: EllipsisIcon,
  tick: TickIcon,
  money: MoneyIcon,
  chat: ChatIcon,
  zipLogo: ZipLogo,
  whatsapp: WhatsappIcon,
  messenger: MessengerIcon,
  twitter: TwitterIcon,
  copy: CopyIcon,
  share: ShareIcon,
  gift: GiftIcon,
  afterpayIconInverse: AfterpayIconLogoInverse,
  zipInverse: ZipLogoInverse,
  applePayInverse: ApplePayLogoInverse,
  googlePayInverse: GooglePayLogoInverse,
  amexInverse: AmexLogoInverse,
  mastercardInverse: MasterCardLogoInverse,
  visaInverse: VisaLogoInverse,
  afterpayAffiliate: AfterpayAffiliateLogo,
  nineNewsAffiliate: NineNewsAffiliateLogo,
  qantasAffiliate: QantasAffiliateLogo,
  rewardGatewayAffiliate: RewardGatewayAffiliateLogo,
  shopbackAffiliate: ShopbackAffiliateLogo,
  sitchuAffiliate: SitchuAffiliateLogo,
  timeOutAffiliate: TimeOutAffiliateLogo,
  urbanListAffiliate: UrbanListAffiliateLogo,
  tikTokColoured: TikTokColouredIcon,
  instagramColoured: InstagramColouredIcon,
  facebookColoured: FacebookColouredIcon,
  badgeDiscount: BadgeDiscountIcon,
  flag: FlagIcon,
  receiveMoney: ReceiveMoneyIcon,
  award: AwardIcon,
}

type IconProps = {
  name: IconName
  color?: string
  [propName: string]: any
}

const Icon: React.FC<IconProps> = ({ name, color, ...props }) => {
  const Component = icons[name]
  return <Component {...props} />
}

export default Icon
