import { Z_INDEX } from '@/constant/ui'
import { BREAKPOINT_SMALL } from '@big-red-group/storefront-common-ui'
import Link from 'next/link'
import styled from 'styled-components'

export const BAR_HEIGHT = 80
export const BAR_HEIGHT_MOBILE = 60

export const Wrapper = styled.div`
  background: var(--brand-secondary);
  height: ${BAR_HEIGHT}px;
  border-bottom: 1px solid #8d8d8d;
  box-sizing: border-box;

  > div {
    height: 100%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: auto;
  }
`

export const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  height: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`

export const Left = styled.div`
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 150px;
    padding: 20px 0;
  }
`
export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    order: 3;
    width: 100%;
    padding-bottom: 20px;
  }
`
export const Right = styled.div`
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  color: var(--text-inverse);

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 150px;
  }
`

export const Logo = styled.div`
  display: block;
  z-index: ${Z_INDEX.medium};
  position: relative;

  svg {
    height: 28px;
    color: var(--brand-primary);
    display: block;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
  }
`
