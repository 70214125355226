import { ProductListViewedEventProperties } from '@segment/analytics-next'

import { limitProductsLength, makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { AnalyticsTrackEvent, PlpProduct, Product, ProductRecommendation } from '@/types'

type CreateProductListViewedEvent = {
  products: (PlpProduct | Product | ProductRecommendation)[]
  isWidget: boolean
  listId?: string
  name?: string
  isPopularCategory?: boolean
  totalProductsCount?: number
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Product+List+Viewed#Properties
 */
// TODO: Add sort method, filter when available
export const createProductListViewedEvent = ({
  products,
  listId = '',
  name = '',
  isWidget,
  isPopularCategory = false,
  totalProductsCount,
}: CreateProductListViewedEvent): Extract<AnalyticsTrackEvent, { object: 'Product List'; action: 'Viewed' }> => {
  let limitedProducts: (PlpProduct | Product | ProductRecommendation)[] = []
  if (products.length) {
    limitedProducts = limitProductsLength(products)
  }

  // TODO: If geolocation list is available in any input type, set it up in the payload
  const productProperties = limitedProducts.map((product, index) => ({
    ...makeProductBaseProperties({ product, position: index + 1, includeSessions: true }),
  }))

  const eventProperties: ProductListViewedEventProperties = {
    name,
    products: productProperties,
    is_widget: isWidget,
    list_id: listId,
    is_popular_category: isPopularCategory,
    displayed_items: products.length,
  }

  if (totalProductsCount) {
    eventProperties.total_items = totalProductsCount
  }

  return {
    object: 'Product List',
    action: 'Viewed',
    properties: eventProperties,
  }
}
