import type { Options, Plugin } from '@segment/analytics-next'

import { enrichGoogleAdsContext } from '@/helpers/PluginHelper'

export const GoogleAdsEnrichmentPlugin = (
  _siteName: string = '',
  _currencyCode: string = '',
  _contextOptions?: Options
): Plugin => ({
  name: 'Enrich  Google Ads Conversions context',
  type: 'enrichment',
  version: '0.0.1',

  isLoaded: () => true,
  load: () => Promise.resolve(),

  track: enrichGoogleAdsContext,
  page: enrichGoogleAdsContext,
})
