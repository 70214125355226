import { useCallback } from 'react'
import { createBeaconEvent } from '@/events'
import { BeaconEventData } from '@/types'
import { useAnalytics } from './AnalyticsHook'
import { useAnalyticsMetaData } from './AnalyticsMetaDataHook'

export const useMCPEvent = () => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const trackMCPEvent = useCallback(
    ({ object, action, properties }: BeaconEventData) => {
      const context = metadata.getContextOptions()

      const event = createBeaconEvent({ object, action, properties })

      if (context) {
        event.context = context
      }

      analytics.trackEvent(event)
    },
    [analytics, metadata]
  )

  return trackMCPEvent
}
