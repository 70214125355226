import { Subheading } from '@/components/FeaturedLinksSwiper.styles'
import { IconName } from '@/components/Icons'
import { ButtonStyles, Perk, SpacingSizes } from '@/types'

import { CSSProperties } from 'react'

/* Breakpoints */
export const BREAKPOINT_EXTRA_SMALL = 599
export const BREAKPOINT_SMALL = 768
export const BREAKPOINT_MEDIUM = 1020
export const BREAKPOINT_MEDIUM_LARGE = 1200
export const BREAKPOINT_LARGE = 1440

export const SPACING_SIZES: SpacingSizes = {
  XXXS: {
    name: 'XXXS',
    desktop: 4,
    mobile: 2,
  },
  XXS: {
    name: 'XXS',
    desktop: 8,
    mobile: 4,
  },
  XS: {
    name: 'XS',
    desktop: 12,
    mobile: 8,
  },
  S: {
    name: 'S',
    desktop: 16,
    mobile: 12,
  },
  M: {
    name: 'M',
    desktop: 24,
    mobile: 16,
  },
  L: {
    name: 'L',
    desktop: 32,
    mobile: 24,
  },
  XL: {
    name: 'XL',
    desktop: 40,
    mobile: 32,
  },
  XXL: {
    name: 'XXL',
    desktop: 64,
    mobile: 48,
  },
  XXXL: {
    name: 'XXXL',
    desktop: 128,
    mobile: 64,
  },
}

export const BUTTON_STYLE: ButtonStyles = {
  S: { name: 'S', paddingVertical: 6, paddingHorizontal: 10 },
  M: { name: 'M', paddingVertical: 10, paddingHorizontal: 12 },
  L: { name: 'L', paddingVertical: 14, paddingHorizontal: 26 },
}

export const PAYMENT_PAGE_PATH = 'en/order/payment/payment-router'
export const CHECKOUT_PAGE_PATH = 'en/order/checkout'

export const CHECKOUT_STEPS: Array<{ label: string; icon: IconName }> = [
  { label: 'Details', icon: 'pencil' },
  { label: 'Payment', icon: 'money' },
]

export const TOASTER_STYLE: CSSProperties = {
  maxWidth: '400px',
  width: '100%',
  padding: '0',
  animation: 'none',
}

export const TOASTER_CLASS_NAME = 'react-hot-toast-container'

export { Z_INDEX } from '@big-red-group/storefront-common-ui'
export const BASE_MOBILE_TOP_BAR_HEIGHT = 56
export const HAPPY_HOUR_BANNER_HEIGHT = 28

export const CREW_EXCLUSIVE_PERKS: Perk[] = [
  {
    icon: 'receiveMoney',
    heading: 'Kickstart with $10 off your first purchase',
    subheading: '(min spend $49)',
  },
  {
    icon: 'badgeDiscount',
    heading: 'Come back: 15% off next purchase ',
    subheading: '(min spend $99)',
  },
  {
    icon: 'award',
    heading: 'Adventure fund',
    subheading: `Every month, you're in the running for $100`,
  },
  {
    icon: 'flag',
    heading: 'First to know:',
    subheading: 'Exclusive offers, gift inspiration, occasion reminders straight to your inbox',
  },
]
