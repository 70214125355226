import { Wrapper } from '@/components/Button.styles'
import { ButtonSize } from '@/types'
import Icon, { IconName } from '@/components/Icons'
import { Body } from '@big-red-group/storefront-common-ui'
// TODO: Button needs to be removed and replaced with the common-ui implementation
const Button: React.FC<{
  children: React.ReactNode
  className?: string
  onClick?: (k?: any) => void
  loading?: boolean
  secondary?: boolean
  alternate?: boolean
  inverse?: boolean
  disabled?: boolean
  dataTestId?: string
  type?: 'submit' | 'button'
  size?: ButtonSize
  form?: string
  icon?: IconName
  iconRight?: boolean
}> = ({
  children,
  className,
  onClick,
  dataTestId,
  secondary = false,
  inverse = false,
  alternate = false,
  disabled = false,
  loading = false,
  type = 'button',
  size = 'M',
  form,
  icon,
  iconRight = false,
}) => {
  const renderChildren = () => {
    if (loading) {
      return (
        <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 50 50">
          <path
            fill="currentColor"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.75s"
              repeatCount="indefinite"
            ></animateTransform>
          </path>
        </svg>
      )
    }

    return (
      <>
        {icon && !iconRight && <Icon name={icon} data-testid="button-icon" />}
        <Body size={size} weight="medium">
          {children}
        </Body>
        {icon && iconRight && <Icon name={icon} data-testid="button-icon" />}
      </>
    )
  }

  return (
    <Wrapper
      className={className}
      form={form}
      $secondary={secondary}
      $alternate={alternate}
      $inverse={inverse}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      type={type}
      size={size}
    >
      {renderChildren()}
    </Wrapper>
  )
}

export default Button
