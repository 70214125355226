export const generateHtmlSafeId = (id: string | number) => {
  const formattedId = String(id)
    .replace(/[^A-Z0-9]+/gi, '-')
    .toLowerCase()
  return formattedId && !isNaN(Number(formattedId.charAt(0))) ? `_${formattedId}` : formattedId
}

/**
 * This function calculates the target scroll position based on the element's
 * position relative to the viewport and then scrolls smoothly to it.
 * @param elementId - The ID of the element to scroll to
 * @param scrollPadding - Additional padding to adjust the final scroll position (default: 120px)
 *
 */
export const scrollToElement = (elementId: string, scrollPadding = 120) => {
  const element = document.getElementById(elementId)
  if (element) {
    const scrollPoint = element.getBoundingClientRect().top + window.scrollY - scrollPadding
    window.scroll({
      top: scrollPoint,
      behavior: 'smooth',
    })
  }
}
/**
 * This function returns a complete string outlining the total reviews
 * @param total - The total number of reviews
 *
 */
export const getTotalReviewsText = (total: number) => {
  return `${total} review${total > 1 ? 's' : ''}`
}
