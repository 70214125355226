import { AnalyticsBrowser, AnalyticsBrowserSettings, InitOptions } from '@segment/analytics-next'
import React, { useMemo } from 'react'

import { AnalyticsSegmentContext } from '@/context/AnalyticsSegmentContext'
import { getSegmentConfiguration } from '@/helpers/AnalyticsSegmentHelper'
import { useAnalyticsMetaData } from '@/hooks/AnalyticsMetaDataHook'
import { useDefaultProperties } from '@/hooks/UseDefaultPropertiesHook'
import { loyaltyCampaignDataMiddleWare, marketingCloudMiddleWare, userDataMiddleWare } from '@/middlewares'
import * as plugins from '@/plugins'

export const AnalyticsSegmentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const metadata = useAnalyticsMetaData()
  const defaultProperties = useDefaultProperties()

  const segmentConfiguration = getSegmentConfiguration(defaultProperties?.agentConfiguration)
  const contextOptions = metadata.getContextOptions()

  const segment: AnalyticsBrowser | null = useMemo(() => {
    const { writeKey, cdnUrl, apiHost, brandGroup, brandType, siteName, currencyCode } = segmentConfiguration
    const {
      writeKey: cachedSegmentWriteKey,
      apiHost: cachedSegmentApiHost,
      cdnUrl: cachedSegmentCdnUrl,
      brandGroup: cachedSegmentBrandGroup,
      brandType: cachedSegmentBrandType,
      siteName: cachedSegmentSiteName,
      currencyCode: cachedSegmentCurrency,
    } = metadata.getSegmentWriteKeyFromCache() || {}

    const sgWriteKey = writeKey || cachedSegmentWriteKey
    const sgApiHost = apiHost || cachedSegmentApiHost
    const sgCdnUrl = cdnUrl || cachedSegmentCdnUrl
    const sgBrandGroup = brandGroup || cachedSegmentBrandGroup
    const sgBrandType = brandType || cachedSegmentBrandType
    const sgSiteName = siteName || cachedSegmentSiteName
    const sgCurrency = currencyCode || cachedSegmentCurrency

    // Not deploy Segment when running E2E testing or writekey is not detected or empty
    if (!sgWriteKey) {
      return null
    }

    // Set Segment write key in the cache, this method will only change when no write key is detected or write keys are different
    metadata.cacheSegmentWriteKey(sgWriteKey, sgApiHost, sgCdnUrl, sgBrandGroup, sgBrandType, sgSiteName, sgCurrency)

    const segmentSettings: AnalyticsBrowserSettings = {
      writeKey: sgWriteKey,
      plugins: Object.values(plugins).map((plugin) => plugin(siteName, currencyCode, contextOptions)),
      // CDN URL example, do NOT forget "https://", otherwise, it will fail to load
      // cdnURL: 'https://cdn.experienceoz-uat.com.au',
    }

    // cdnUrl: Proxy to Segment CDN (cdn.segment.com)
    if (cdnUrl) {
      /**
       * This optional setting helps change the URL where Segment library will be loaded from,
       * as well as the outgoing requests generated when events are triggered.
       */
      segmentSettings.cdnURL = cdnUrl
    }

    const segmentOptions: InitOptions = {
      /**
       * This option is used to obscure the URL from which the integrations
       * and destination actions are loaded.
       *
       * This helps prevent words that are flagged by ad blockers to not
       * to be detected and enable the integrations to be properly loaded.
       */
      obfuscate: true,
      /**
       * This option is set in order to avoid event loss when connection is unstable.
       * Segment will wait for the API call to finish before flushing out of the queue
       */
      retryQueue: true,
    }

    // apiHost: Proxy to Segment tracking API (api.segment.io)
    if (apiHost) {
      segmentOptions.integrations = {
        'Segment.io': {
          /**
           * This option is used for integration purpose only.
           * This enables the POST request to firstly be sent to the API host, which is managed
           * as a subdomain, before being proxied to https://api.segment.io/v1/t
           */
          apiHost,
          protocol: 'https',
        },
      }
    }

    const analytics = AnalyticsBrowser.load(segmentSettings, segmentOptions)

    // Add middleware to adjust data for only a specific destination
    //! Please note: This will only work on device-mode destinations
    analytics.addDestinationMiddleware('Google Tag Manager', userDataMiddleWare, marketingCloudMiddleWare)
    analytics.addDestinationMiddleware('Google Analytics 4 Web', loyaltyCampaignDataMiddleWare)

    return analytics
  }, [metadata, segmentConfiguration, contextOptions])

  return <AnalyticsSegmentContext.Provider value={segment}>{children}</AnalyticsSegmentContext.Provider>
}
