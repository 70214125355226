import styled from 'styled-components'
import { BREAKPOINT_MOBILE_MENU } from './Topbar.styles'

export const LinkItem = styled.a<{ underline: boolean }>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-primary);

  &:hover {
    background: var(--brand-primary);
  }

  ${({ underline }) =>
    underline &&
    `
    text-decoration: underline;
  `}

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    padding: 15px;
    position: relative;
    z-index: 2;
    &:hover {
      background: unset;
    }
    &:active {
      background: var(--brand-primary);
    }
  }
`
