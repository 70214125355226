import { Inclusion, SearchFilterDecorator } from '@/types'
import { IconName } from '@/components/Icons'

// TODO: Replace WHEELCHAIR_FRIENDLY icon
export const decoratorsIcons: Record<Inclusion, IconName> = {
  HOTEL_PICKUP: 'bus',
  MEALS_INCLUDED: 'teaCup',
  BEST_SELLER: 'starFilled',
  ECO_ADVANCED_TOURISM: 'leaf',
  SKIP_THE_QUEUE: 'stopwatch',
  WHEELCHAIR_FRIENDLY: 'stroller',
  STROLLER_FRIENDLY: 'stroller',
}

export const productTileBadges: string[] = ['BEST_SELLER', 'MEALS_INCLUDED', 'HOTEL_PICKUP']

export type SearchFilter = {
  decorator: SearchFilterDecorator
  label: string
}

export const searchFiltersDecorators: SearchFilter[] = [
  { decorator: 'HOTEL_PICKUP', label: 'Hotel pickup' },
  { decorator: 'BEST_SELLER', label: 'Best seller' },
  { decorator: 'MEALS_INCLUDED', label: 'Meals included' },
  { decorator: 'SKIP_THE_QUEUE', label: 'Skip the queue' },
  { decorator: 'WHEELCHAIR_FRIENDLY', label: 'Wheelchair friendly' },
]

export const hiddenDecoratorIds = [12, 13, 14, 15, 16, 17]
