import styled from 'styled-components'
import { BadgeColor, BadgeSize } from '@/types'

const getBadgeBgColor = (color: BadgeColor): string => {
  switch (color) {
    case 'blue':
      return '--brand-primary'
    case 'orange':
      return '--brand-accent'
    case 'red':
      return '--sale'
    case 'black':
      return '--bg-black'
  }
}

const getBadgeColor = (color: BadgeColor): string => {
  return color === 'blue' ? '--text-primary' : '--text-inverse'
}

export const Wrapper = styled.div<{ color: BadgeColor; size: BadgeSize }>`
  display: inline-flex;
  padding: 4px 6px 4px 4px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  letter-spacing: 0.01em;
  border-radius: 2px;
  background-color: var(${({ color }) => getBadgeBgColor(color)});
  color: var(${({ color }) => getBadgeColor(color)});

  svg {
    width: 12px;
    height: 12px;
    position: relative;
  }

  ${({ size }) =>
    size === 'L' &&
    `
    padding: 4px 10px 4px 4px;
    
    svg {
    width: 16px;
    height: 16px;
  }
  `}
`
