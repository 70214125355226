import Link from 'next/link'
import Icon, { IconName } from './Icons'
import Image from 'next/image'
import {
  Main,
  Menu,
  Secondary,
  Third,
  Social,
  Wrapper,
  BottomWrapper,
  SmallLinks,
  SectionLink,
  SecondaryLeft,
  PaymentMethods,
  CardIcon,
} from './Footer.styles'
import { useRouter } from 'next/router'
import Container from '@/components/Container'
import { Body, Spacing } from '@big-red-group/storefront-common-ui'
import { useElementClick } from '@/hooks/HTMLElementClickHook'
import { PAYMENT_METHODS } from '@/constant/checkout'
import { useMemo } from 'react'

type MenuSection = {
  title: string
  links: { label: string; url: string; target?: '_blank' }[]
}

const Footer = () => {
  const privacyPolicyLinkTxt = 'Privacy'
  const instagramLinkTxt = 'Instagram'
  const youtubeLinkTxt = 'YouTube'
  const facebookLinkTxt = 'Facebook'
  const tiktokLinkTxt = 'TikTok'

  const paymentMethods: IconName[] = useMemo(() => PAYMENT_METHODS.map((method) => method), [])

  const router = useRouter()

  const linkClick = useElementClick('Link')

  const menuSections: MenuSection[] = [
    {
      title: 'Our Company',
      links: [
        { label: 'About Us', url: '/en/about-us' },
        { label: 'Blog', url: '/en/blog' },
        { label: 'Privacy Policy', url: '/en/privacy-policy' },
        { label: 'Terms and Conditions', url: '/en/terms-and-conditions' },
      ],
    },
    {
      title: 'Join Us',
      links: [
        { label: 'Affiliate Partners', url: '/en/affiliate-program' },
        {
          label: 'Experience Operators',
          url: 'https://www.thebigredgroup.com.au/our-network/experience-operators/',
          target: '_blank',
        },
        { label: 'Careers', url: 'https://www.thebigredgroup.com.au/team/jobs-overview/', target: '_blank' },
      ],
    },
    {
      title: 'Get in Touch',
      links: [{ label: 'Help Centre', url: 'https://help.adrenaline.com.au/s/', target: '_blank' }],
    },
  ]

  return (
    <>
      <Wrapper data-testid="footer">
        <Container>
          <Main>
            <Icon name="logoPrimary" width={137} height={33} />
            <Menu>
              {menuSections.map(({ title, links }) => (
                <div key={title}>
                  <Body size="L" color="brand-primary" weight="medium">
                    {title}
                  </Body>
                  <Spacing size="M" />
                  {links.map(({ label, url, target }, index) => (
                    <SectionLink
                      data-testid={label}
                      href={url}
                      key={index}
                      target={target || '_self'}
                      onClick={(event) => linkClick({ location: `Footer | ${title}`, event })}
                    >
                      <Body size="S" weight="regular">
                        {label}
                      </Body>
                      <Spacing size="S" />
                    </SectionLink>
                  ))}
                </div>
              ))}
              <Social>
                <Link
                  href="https://www.instagram.com/adrenaline_au"
                  target="_blank"
                  data-testid={instagramLinkTxt}
                  onClick={(event) => linkClick({ location: `Footer | ${instagramLinkTxt}`, event })}
                >
                  <Icon name="instagram" width={44} height={44} />
                </Link>
                <Link
                  href="https://www.tiktok.com/@adrenaline.crew"
                  target="_blank"
                  data-testid={tiktokLinkTxt}
                  onClick={(event) => linkClick({ location: `Footer | ${tiktokLinkTxt}`, event })}
                >
                  <Icon name="tikTok" width={44} height={44} />
                </Link>
                <Link
                  href="https://www.facebook.com/adrenaline.com.au"
                  target="_blank"
                  data-testid={facebookLinkTxt}
                  onClick={(event) => linkClick({ location: `Footer | ${facebookLinkTxt}`, event })}
                >
                  <Icon name="facebook" width={44} height={44} />
                </Link>
              </Social>
            </Menu>
          </Main>
          <Secondary>
            <SecondaryLeft>
              <SmallLinks>
                <span>
                  <Body size="XS" weight="regular" color="text-inverse">
                    &copy; Adrenaline
                  </Body>
                </span>
                <span>
                  <Link
                    data-testid={privacyPolicyLinkTxt}
                    href="/en/privacy-policy"
                    onClick={(event) => linkClick({ location: `Footer | ${privacyPolicyLinkTxt}`, event })}
                  >
                    <Body size="XS" weight="regular" color="text-inverse">
                      {privacyPolicyLinkTxt}
                    </Body>
                  </Link>
                </span>
                <span style={{ cursor: 'pointer' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                  <Body size="XS" weight="regular" color="text-inverse">
                    Back to Top
                  </Body>
                </span>
              </SmallLinks>

              <PaymentMethods>
                {paymentMethods.map((method) => (
                  <CardIcon key={method}>
                    <Icon name={method} />
                  </CardIcon>
                ))}
              </PaymentMethods>
            </SecondaryLeft>

            {/* Logo */}
            <Link href="https://www.thebigredgroup.com.au/" target="_blank">
              <Image src="/images/brg-network-logo.webp" alt="Big Red Group" width={0} height={0} sizes="100%" />
            </Link>
          </Secondary>
        </Container>
      </Wrapper>
      <BottomWrapper>
        <Container>
          <Third>
            <Icon name="respects" width={32} height={32} />
            <Body size="S" weight="regular" color="text-inverse">
              We acknowledge and pay respect to the Traditional Custodians of Country and their connections and
              continuous care for the skies, lands and waterways throughout Australia.
            </Body>
          </Third>
        </Container>
      </BottomWrapper>
    </>
  )
}

export default Footer
