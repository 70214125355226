import { pinoLogger } from './Server'
import { datadogLogger } from './Client'

// Wrapper around pino for server side logging and Datadog browser logs for client side logging
// With Next.js it is not always deterministic where the code is executed.
// This wrapper standardising the log interface for the application developer and picks the right logging depending on the context the code runs.
export class Logger {
  private readonly name: string

  constructor(name: string) {
    this.name = name
  }

  log(message: string, messageContext?: object, status: string = 'info', error?: Error) {
    // browser side logging with Datadog
    if (typeof window !== 'undefined') {
      const logger = datadogLogger(this.name)

      switch (status) {
        case 'warn':
          logger.warn(message, messageContext, error)
          break
        case 'debug':
          logger.debug(message, messageContext, error)
          break
        case 'trace':
          // no trace log level in Datadog logger available
          logger.debug(message, messageContext, error)
          break
        case 'error':
          logger.error(message, messageContext, error)
          break
        case 'info':
          logger.info(message, messageContext, error)
          break
      }
    } else {
      // server side logging with pino
      const logger = pinoLogger.child({ loggerName: this.name })

      const logObject = {
        ...(error && { error }),
        ...(messageContext && { messageContext }),
      }
      switch (status) {
        case 'warn':
          logger.warn(logObject, message)
          break
        case 'trace':
          logger.trace(logObject, message)
          break
        case 'debug':
          logger.debug(logObject, message)
          break
        case 'error':
          logger.error(logObject, message)
          break
        case 'info':
          logger.info(logObject, message)
          break
      }
    }
  }

  trace(message: string, messageContext?: object, error?: Error) {
    this.log(message, messageContext, 'trace', error)
  }

  debug(message: string, messageContext?: object, error?: Error) {
    this.log(message, messageContext, 'debug', error)
  }

  info(message: string, messageContext?: object, error?: Error) {
    this.log(message, messageContext, 'info', error)
  }

  warn(message: string, messageContext?: object, error?: Error) {
    this.log(message, messageContext, 'warn', error)
  }

  error(message: string, messageContext?: object, error?: Error) {
    this.log(message, messageContext, 'error', error)
  }
}

export const logger = (loggerName: string = 'default'): Logger => {
  return new Logger(loggerName)
}
