import { createErrorOccurredEvent } from '@/events'
import { CustomError, FetchError, UnhandledPromiseError, UnknownError } from '@/helpers/ErrorHelper'
import { useAnalytics } from '@/hooks/AnalyticsHook'

export const useAnalyticsError = () => {
  const analytics = useAnalytics()

  const handleErrorEvent = (
    error: CustomError | Error | FetchError | UnhandledPromiseError | UnknownError,
    isTrusted: boolean
  ) => {
    const eventInput = {
      errorCode: 0,
      errorMessage: error.message,
      errorType: error.name,
      isTrusted: isTrusted,
    }

    const { cause } = error

    if (cause && cause instanceof Response) {
      eventInput.errorCode = cause.status
    }

    const event = createErrorOccurredEvent(eventInput)

    analytics.trackEvent(event)
  }

  return handleErrorEvent
}
