import styled from 'styled-components'
import { BREAKPOINT_MOBILE_MENU } from './Topbar.styles'
import { Z_INDEX } from '@/constant/ui'

export const SubmenuWrapperBg = styled.div<{ topbarHeight: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  opacity: 0;
  z-index: ${Z_INDEX.low};
  transition: 0.3s ease-in-out;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: none;
  }
`
export const Submenu = styled.div<{ simpleSubmenu?: boolean }>`
  position: absolute;
  left: 50%;
  z-index: ${Z_INDEX.medium};
  width: 900px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
  align-items: flex-start;
  border: 1px solid var(--brand-secondary);
  border-radius: 4px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  background: var(--grey-10);
  transform: translate(-50%, 30px);
  transition: 0.2s ease-in-out;

  ${({ simpleSubmenu }) =>
    simpleSubmenu &&
    `
    @media (min-width: ${BREAKPOINT_MOBILE_MENU + 1}px) {
      grid-template-columns: 100%;
      background: transparent;
      border: 0;
    }
  `}

  &.submenu-active {
    pointer-events: all;
    opacity: 1;
    transform: translate(-50%, 0);
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    border-radius: 0;
    left: 100%;
    transition: 0.3s ease-in-out;
    transform: none;

    &.submenu-active {
      transform: none;
      left: 0;
    }
  }
`
export const SubmenuPanel = styled.div<{ simpleSubmenu?: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  min-height: 500px;
  height: 100%;

  ${({ simpleSubmenu }) =>
    simpleSubmenu &&
    `
    @media (min-width: ${BREAKPOINT_MOBILE_MENU + 1}px) {
      min-height: unset;
      width: 300px;
      margin: 0 auto;
      background: var(--grey-10);
      border: 1px solid var(--brand-secondary);
      border-radius: 4px;
    }
  `}

  &:not(:first-child) {
    border-left: 1px solid var(--brand-secondary);
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    min-height: auto;

    &:not(:first-child) {
      border-left: 0;
    }
  }
`
export const SecondMenu = styled(SubmenuPanel)`
  position: absolute;
  left: 100%;
  width: calc(100% + 1px);
  top: 0;
  display: none;
  height: 100%;
  overflow: auto;

  &.submenu-active {
    display: block;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    position: fixed;
    left: 100%;
    height: 100%;
    background: var(--grey-10);
    display: block;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: ${Z_INDEX.low};

    &.submenu-active {
      left: 0;
      pointer-events: all;
      opacity: 1;
      z-index: ${Z_INDEX.low + 2};
    }
  }
`

export const PromoPanel = styled(SubmenuPanel)`
  grid-column-start: 3;
  padding: 32px;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: none;
  }
`

export const PromoImage = styled.img`
  background: var(--grey-50);
  height: 150px;
  align-items: flex-start;
  cursor: pointer;
`
export const MenuGroup = styled.div`
  button {
    z-index: ${Z_INDEX.base};
    position: relative;
    width: calc(100% - 20px);
    margin: 10px auto;
    display: block;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    button {
      width: calc(100% - 30px);
      margin: 15px auto;
      padding: 10px;

      p {
        font-size: 14px;
      }
    }
  }
`
export const MenuItem = styled.div<{ underline?: boolean; active?: boolean }>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background: var(--brand-primary);
  }

  ${({ active }) =>
    active &&
    `
    background: var(--brand-primary);
  `}

  ${({ underline }) =>
    underline &&
    `
    text-decoration: underline;
  `}

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    padding: 15px;
    position: relative;
    z-index: ${Z_INDEX.medium};
  }
`

export const MobileHeader = styled.div<{ align?: 'flex-end' | 'flex-start' | 'space-between' }>`
  display: none;
  padding: 15px 15px 10px 15px;
  justify-content: ${({ align }) => align ?? 'space-between'};
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: flex;
  }
`

export const MobileTitle = styled.div`
  display: none;
  padding: 15px;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: block;
  }
`

export const MobileHeaderIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SubmenuWrapper = styled.div<{ topbarHeight: number; active: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(100vh - ${({ topbarHeight }) => topbarHeight}px);
  z-index: ${Z_INDEX.medium};
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in-out;

  ${({ active }) =>
    active &&
    `
    opacity: 1;
    pointer-events: all;

    ${SubmenuWrapperBg} {
      opacity: 1;
      pointer-events: all;
    }
  `}

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    height: 100vh;
    top: 0;
  }
`
