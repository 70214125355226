import {
  TopbarConfigurationDestination,
  TopbarConfigurationDestinationState,
  TopbarConfigurationDestinationStateRegion,
  TopbarConfigurationLink,
  TopbarConfigurationCategoryType,
  TopbarConfigurationCategory,
  TopbarConfigurationSubLink,
  SearchPropertiesEntity,
} from '@/types'
import Label from './Label'
import Icon from './Icons'
import Button from './Button'
import {
  MenuGroup,
  MenuItem,
  MobileHeader,
  MobileHeaderIcon,
  MobileTitle,
  PromoImage,
  PromoPanel,
  SecondMenu,
  Submenu,
  SubmenuPanel,
  SubmenuWrapper,
  SubmenuWrapperBg,
} from './TopbarSubmenus.styles'
import { useRouter } from 'next/router'
import { Body, Spacing } from '@big-red-group/storefront-common-ui'
import React from 'react'
import { useDefaultProperties } from '@/hooks/UseDefaultPropertiesHook'
import SubmenuLink from './SubmenuLink'

export type SubmenuLinkType = 'category' | 'gift' | 'location' | 'fullUrl'

type OffCanvasMenuProps = {
  topbarHeight: number
  submenuToggled?: string
  activeSecondMenu?: string
  onMenuChange: (submenu: string) => void
  onSecondMenuChange: (submenu: string) => void
  onClose: () => void
  triggerLogin: () => void
  onButtonClick: (link: string) => void
  destinations?: TopbarConfigurationDestination[]
  categoryTypes?: TopbarConfigurationCategoryType[]
  displayableLinks?: TopbarConfigurationLink[]
}

// BAI-4092: Filter out unwanted categories
// List of categories to exclude
const EXCLUDED_CATEGORIES: { categoryType: string; categoryName: string }[] = [
  { categoryType: 'EXPERIENCE', categoryName: 'Christmas Sale' },
  { categoryType: 'EXPERIENCE', categoryName: 'Adventure On Sale' },
  { categoryType: 'OCCASION', categoryName: "Father's Day Gifts" },
  { categoryType: 'OCCASION', categoryName: 'Christmas Gifts' },
  { categoryType: 'OCCASION', categoryName: 'Black Friday Cyber Monday' },
  { categoryType: 'OCCASION', categoryName: "Valentine's Day Gifts" },
]

// To-do - BAI-4092: This is a temporary solution in FE. We need a robust way to manage menus.
const filterCategoryTypes = (categories: TopbarConfigurationCategoryType[]): TopbarConfigurationCategoryType[] => {
  return categories.map((categoryType) => ({
    ...categoryType,
    categories: categoryType.categories.filter((cat) => {
      const isExcluded = EXCLUDED_CATEGORIES.some(
        (excluded) =>
          excluded.categoryType.trim().toLowerCase() === categoryType.categoryType.trim().toLowerCase() &&
          excluded.categoryName.trim().toLowerCase() === cat.name.trim().toLowerCase()
      )

      return !isExcluded
    }),
  }))
}

const TopbarSubmenus: React.FC<OffCanvasMenuProps> = ({
  topbarHeight = 75,
  submenuToggled,
  activeSecondMenu,
  onMenuChange,
  onSecondMenuChange,
  onClose,
  triggerLogin,
  onButtonClick,
  destinations,
  categoryTypes,
  displayableLinks,
}) => {
  const router = useRouter()
  const { lang } = router.query
  const defaultProperties = useDefaultProperties()
  const searchCategories = defaultProperties?.searchProperties?.categories

  const miscMenuItems = [displayableLinks].flat()
  const otherSubmenus = Array.from(miscMenuItems.filter((item) => item?.subLinks))

  // To-do - BAI-4092: This is a temporary solution in FE. We need a robust way to manage menus.
  const filteredCategoryTypes: TopbarConfigurationCategoryType[] = filterCategoryTypes(categoryTypes || [])
  const giftCategories = filteredCategoryTypes?.filter((type) => type.categoryType !== 'EXPERIENCE')
  const experienceCategories = filteredCategoryTypes?.find((type) => type.categoryType === 'EXPERIENCE')

  experienceCategories?.categories?.map((expCat) => {
    const matchingCat = searchCategories?.find((searchCat) => searchCat.urlSegment === expCat.url)
    if (!matchingCat || !matchingCat.subCategories) {
      return
    }
    expCat.subCategories = matchingCat.subCategories.map((matchingSubCat) => {
      return {
        name: matchingSubCat.name,
        url: matchingSubCat.urlSegment,
      }
    })
  })

  const sortingArrays = [
    [
      'new-south-wales',
      'queensland',
      'victoria',
      'south-australia',
      'tasmania',
      'western-australia',
      'northern-territory',
      'act',
    ],
    ['north-island', 'south-island'],
  ]
  const sortStates = (states: TopbarConfigurationDestinationState[], countryIndex: number) => {
    return states.sort(
      (a, b) => sortingArrays[countryIndex].indexOf(a.url) - sortingArrays[countryIndex].indexOf(b.url)
    )
  }

  const handleSubmenuLink = (label: string) => {
    onSecondMenuChange(label)
  }

  const handleSubmenuHover = (label: string) => {
    if (window.innerWidth <= 1100) return
    onSecondMenuChange(label)
  }

  const handleClickLink = (link: string) => {
    window.location.href = link
  }

  const renderMobileHeader = (headerName: string, menuLevel: number) => {
    return (
      <>
        <MobileHeader>
          <MobileHeaderIcon onClick={() => (menuLevel === 1 ? onMenuChange('') : onSecondMenuChange(''))}>
            <Icon name="caretLeft" width={20} height={20} />
          </MobileHeaderIcon>
          <MobileHeaderIcon data-testid={`close-${headerName}`} onClick={onClose}>
            <Icon name="close" width={20} height={20} />
          </MobileHeaderIcon>
        </MobileHeader>
        <MobileTitle>
          <Body size="XL" weight="bold">
            {headerName}
          </Body>
        </MobileTitle>
      </>
    )
  }

  const renderSecondMenu = (
    menuIndex: number,
    menuType: SubmenuLinkType,
    menuName: string,
    menuUrl: string,
    subMenuItems?:
      | TopbarConfigurationDestinationStateRegion[]
      | TopbarConfigurationCategory[]
      | TopbarConfigurationSubLink[]
  ) => {
    return (
      <>
        {/* Second menu trigger */}
        {subMenuItems ? (
          <MenuItem
            data-testid={`${menuType}${menuUrl && `-${menuUrl}`}`}
            data-secondmenu={menuIndex}
            active={activeSecondMenu === menuName}
            onClick={() => handleSubmenuLink(menuName)}
            onMouseOver={() => subMenuItems && handleSubmenuHover(menuName)}
          >
            {menuName}
            <Icon name="caretRight" width={6} height={11} />
          </MenuItem>
        ) : (
          <SubmenuLink
            menuType={menuType}
            menuName={menuName}
            menuUrl={menuUrl}
            mouseOver={() => handleSubmenuHover('')}
          />
        )}

        {/* Second menu sublinks */}
        <SecondMenu
          className={activeSecondMenu === menuName ? 'submenu-active' : ''}
          data-testid={`sub-menu${menuUrl && `-${menuUrl}`}`}
        >
          {renderMobileHeader(menuName, 2)}
          <MenuGroup>
            {(menuType === 'category' || menuType === 'location') && (
              // Shop all link
              <SubmenuLink menuType={menuType} menuName={`Shop all ${menuName}`} menuUrl={menuUrl} underline />
            )}
            {subMenuItems &&
              subMenuItems.map((subItem, index) => (
                <SubmenuLink
                  key={index}
                  //@ts-ignore
                  menuName={menuType === 'fullUrl' ? subItem?.label : subItem?.name}
                  menuType={menuType}
                  menuUrl={subItem?.url}
                />
              ))}
          </MenuGroup>
        </SecondMenu>
      </>
    )
  }

  const handlePromoImage = () => {
    router.push(`/${lang}/gift-vouchers`)
    onClose()
  }

  const renderCategoriesSubmenu = () => {
    if (!experienceCategories) return

    return (
      <Submenu
        id="adventures-submenu"
        data-testid="adventures-submenu"
        className={submenuToggled === 'Adventures' ? 'submenu-active' : ''}
      >
        <SubmenuPanel>
          {renderMobileHeader('Adventures', 1)}
          <MenuGroup>
            {experienceCategories?.categories.map(({ name, url, subCategories }, catIndex) => (
              <React.Fragment key={name}>
                {renderSecondMenu(catIndex, 'category', name, url, subCategories)}
              </React.Fragment>
            ))}
          </MenuGroup>
        </SubmenuPanel>
        <PromoPanel>
          <PromoImage onClick={handlePromoImage} src="/images/gift-vouchers-nav.png" loading="lazy" />
        </PromoPanel>
      </Submenu>
    )
  }

  const renderDestinationSubmenu = () => {
    if (!destinations) return

    return (
      <Submenu
        id="location-submenu"
        data-testid="location-submenu"
        className={submenuToggled === 'Location' ? 'submenu-active' : ''}
      >
        <SubmenuPanel>
          {destinations &&
            destinations.map((country, index) => (
              <React.Fragment key={country.name}>
                {renderMobileHeader('Location', 1)}

                {destinations.length > 1 && (
                  <Label as="span" color="text-primary-light">
                    {country.name}
                  </Label>
                )}
                <MenuGroup>
                  {sortStates(country.states, index).map(({ name, url, regions }, stateIndex) => (
                    <React.Fragment key={name}>
                      {renderSecondMenu(stateIndex, 'location', name, url, regions)}
                    </React.Fragment>
                  ))}
                </MenuGroup>
              </React.Fragment>
            ))}
        </SubmenuPanel>
        <PromoPanel>
          <PromoImage onClick={handlePromoImage} src="/images/gift-vouchers-nav.png" loading="lazy" />
        </PromoPanel>
      </Submenu>
    )
  }

  const getGiftMenuName = (categoryType: 'EXPERIENCE' | 'OCCASION' | 'RECIPIENT') => {
    switch (categoryType) {
      case 'OCCASION':
        return 'Occasions'
      case 'RECIPIENT':
        return 'Recipients'
      default:
        return ''
    }
  }

  const renderGiftsSubmenus = () => {
    if (!giftCategories) return

    return (
      <Submenu
        id="giftideas-submenu"
        data-testid="giftideas-submenu"
        className={submenuToggled === 'Gift Ideas' ? 'submenu-active' : ''}
      >
        <SubmenuPanel>
          <MenuGroup>
            {renderMobileHeader('Gift Ideas', 1)}
            {giftCategories &&
              giftCategories.map((giftMenu, index) => (
                <React.Fragment key={giftMenu.label}>
                  {renderSecondMenu(index, 'gift', getGiftMenuName(giftMenu.categoryType), '', giftMenu.categories)}
                </React.Fragment>
              ))}
            <Spacing size="S" />
            <Button size="S" secondary alternate onClick={() => onButtonClick(`/${lang}/gift-vouchers`)}>
              <Icon name="present" width={17} height={17} />
              Adrenaline Gift Vouchers
            </Button>
          </MenuGroup>
        </SubmenuPanel>
        <PromoPanel>
          <PromoImage onClick={handlePromoImage} src="/images/gift-vouchers-nav.png" loading="lazy" />
        </PromoPanel>
      </Submenu>
    )
  }

  const renderOtherSubmenus = () => {
    if (!otherSubmenus) return

    return (
      <>
        {otherSubmenus &&
          otherSubmenus.map((submenu, index) => (
            <Submenu
              simpleSubmenu
              key={submenu!.label}
              id={`other-submenu-${index}`}
              data-testid={`other-submenu-${index}`}
              className={submenuToggled === submenu!.label ? 'submenu-active' : ''}
            >
              <SubmenuPanel simpleSubmenu>
                {renderMobileHeader(submenu!.label, 1)}
                <MenuGroup>
                  <React.Fragment key={submenu!.label}>
                    {submenu?.subLinks &&
                      submenu.subLinks.map((subItem, index) => (
                        <SubmenuLink key={index} menuName={subItem.label} menuType="fullUrl" menuUrl={subItem.url} />
                      ))}
                  </React.Fragment>
                </MenuGroup>
              </SubmenuPanel>
            </Submenu>
          ))}
      </>
    )
  }

  return (
    <SubmenuWrapper topbarHeight={topbarHeight} active={submenuToggled != ''}>
      <SubmenuWrapperBg
        topbarHeight={topbarHeight}
        data-testid="submenu-wrapper-bg"
        className="menu-bg"
        onClick={onClose}
        onMouseOver={onClose}
      />
      {renderCategoriesSubmenu()}
      {renderDestinationSubmenu()}
      {renderGiftsSubmenus()}
      {renderOtherSubmenus()}
    </SubmenuWrapper>
  )
}

export default TopbarSubmenus
