import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'
export const BREAKPOINT_MOBILE_MENU = 1100
export const TOP_DESKTOP_HEIGHT = 64
export const TOP_MOBILE_HEIGHT = 56
export const BOTTOM_DESKTOP_HEIGHT = 48

export const TopbarContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.topBar};
  width: 100%;
  box-sizing: border-box;
  background: var(--brand-secondary);
  border-bottom: 1px solid #8d8d8d;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    border-bottom: 0;
  }
`
export const MenuItemWrapper = styled.a<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  line-height: 1;
  font-size: 16px;
  color: var(--text-inverse);
  font-family: var(--font-medium);
  text-decoration: none;
  color: var(--text-inverse);

  svg {
    margin-left: 8px;
    transition: 0.15s ease-in-out;
  }

  ${({ isActive }) =>
    isActive &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    color: var(--text-primary);
  }
`
export const TopWrapper = styled.nav`
  height: ${TOP_DESKTOP_HEIGHT}px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    height: ${TOP_MOBILE_HEIGHT}px;
  }
`

export const BottomWrapper = styled.nav<{ active: boolean }>`
  height: ${BOTTOM_DESKTOP_HEIGHT}px;

  > div {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 40px;
    height: 100%;

    > button {
      align-self: center;
      padding: 8px;
      box-sizing: border-box;
      border: 1px solid var(--text-inverse);
      background: transparent;
      border-radius: 4px;
      color: var(--text-inverse);
      font-family: var(--font-medium);
      font-size: 16px;
      line-height: 1;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--grey-10);
    padding: 16px;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    transform: translateY(30px);

    > div {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
      padding: 0;
      gap: 0;

      > button {
        color: var(--text-primary);
        border-color: var(--text-primary);
        width: calc(100% - 30px);
        padding: 10px;
        box-sizing: border-box;
        margin: 15px 0;
      }
    }

    ${MenuItemWrapper} {
      color: var(--text-primary);
      padding: 15px;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-between;

      svg {
        margin: 0;
        transform: rotate(-90deg);
      }

      p {
        font-size: 16px;
        font-family: var(--font-regular);
      }
    }

    ${({ active }) =>
      active &&
      `
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    `}
  }
`
export const BodySpacer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`
export const MenuContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    width: 100%;
  }
`
export const BurgerLine = styled.div`
  background: var(--text-inverse);
  width: 20px;
  height: 2px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  transition: 0.3s var(--cubicBezier);

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  &:nth-child(3) {
    top: auto;
    bottom: 0;
  }
`
export const Burger = styled.div<{ isToggled: boolean }>`
  margin-right: 20px;
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  transition: 0.3s var(--cubicBezier);
  display: none;

  ${({ isToggled }) =>
    isToggled &&
    `
    .line-1 {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }
    .line-2 {
      opacity: 0;
    }
    .line-3 {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  `}

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: block;
  }
`

export const MenuItemText = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`
export const MenuItemLink = styled(Link)`
  @media (max-width: 1024px) {
    display: none;
  }
`
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const ClearSearch = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 15px;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 35px;
  }
`
export const SearchButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: var(--text-primary);
  color: white;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 6px;
  font-size: 11px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const Icons = styled.div`
  margin-left: 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: var(--text-inverse);

  a {
    color: var(--text-inverse);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  & > *:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    margin-left: 10px;
  }
`
export const UserInitials = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--brand-primary);
  color: var(--brand-secondary);
  font-size: 14px;
  font-family: var(--font-medium);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
`

export const Logo = styled.div`
  display: block;
  color: var(--brand-primary);

  svg {
    @media (min-width: ${BREAKPOINT_SMALL}px) {
      padding-right: 25px;
    }
    @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
      height: 24px;
      width: 100%;
    }
  }
`

export const CartIcon = styled.div`
  position: relative;
`

export const Total = styled.div`
  position: absolute;
  top: -1px;
  right: -4px;
  width: 14px;
  height: 14px;
  background: var(--sale);
  text-align: center;
  color: white;
  font-size: 11px;
  font-weight: var(--font-bold);
  line-height: 15px;
  border-radius: 50%;

  @media (max-width: 768px) {
    top: -4px;
    right: -7px;
  }
`

export const MobileMenuLinks = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: block;
  }
`

export const BrowserThemeColorDetector = styled.div`
  display: none;
  background-color: canvas;
  colorscheme: light;
`
