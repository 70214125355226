import { useEffect } from 'react'

import { useAnalytics } from '@/hooks/AnalyticsHook'
import { useAuthSession } from '@/hooks/AuthSessionHook'

export const AnalyticsIdentifyTracker = () => {
  const analytics = useAnalytics()
  const { authenticated, loading: isLoading, unauthenticated, user: userData } = useAuthSession()

  useEffect(() => {
    if (!isLoading) {
      if (unauthenticated) {
        // Reset user ID if exists, this ensures better tracking
        analytics.setUserId()
      } else {
        if (authenticated) {
          analytics.setUserId(undefined, userData)
        }
      }
    }
  }, [analytics, authenticated, isLoading, unauthenticated, userData])

  return null
}
