import { useEffect } from 'react'

import { isClient } from '@/helpers/ClientSideRenderHelper'
import { useMCPEvent } from '@/hooks/AnalyticsBeaconHook'
import { BeaconEventData } from '@/types'

/**
 * 1. Beacon: The JavaScript beacon embedded on the storefront transmits user actions on the different parts of a page and on different pages to SFMC.
 * 2. window.postMessage(): When SFMC recieves the user action, it posts an event along with the event details to ExOz using window.postMessage().
 * 3. <AnalyticsBeaconTracker/>: It handles all Beacon events in one place and listens to the events send by SFMC and then send them to the Segment
 */
export const AnalyticsBeaconTracker: React.FC = () => {
  const mcpTracker = useMCPEvent()

  useEffect(() => {
    if (isClient()) {
      const mcpEventHandler = async (event: MessageEvent) => {
        let eventName: string = ''

        // An array that contains the trusted origin URL(s) as a security measure
        const originsAllowed = [window.location.origin]

        const mcpEvents = ['MCP Interaction', 'MCP Viewed']

        // Checks to be sure the event is coming from a valid URL
        if (originsAllowed.includes(event.origin)) {
          if (event && event.data) {
            const eventData = (event as MessageEvent).data as BeaconEventData
            const { object, action } = eventData
            if (object && action) {
              eventName = object + ' ' + action
              if (mcpEvents.includes(eventName)) {
                mcpTracker(eventData)
              }
            }
          }
        }
      }

      const events = {
        eventName: mcpEventHandler,
      }

      // message: The event type, and when using postMessage() the type is always message.
      Object.keys(events).forEach((key) => window.addEventListener('message', events[key as keyof typeof events]))

      // Clean up function
      return () => {
        Object.keys(events).forEach((key) => window.removeEventListener('message', events[key as keyof typeof events]))
      }
    }
  }, [mcpTracker])

  return null
}
