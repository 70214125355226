import React, { useMemo } from 'react'
import { Wrapper, Step, StepContainer, IconWrapper, Label } from '@/components/ProgressSteps.styles'
import Icon from '@/components/Icons'
import { CheckoutStep } from '@/types'
import { CHECKOUT_STEPS } from '@/constant/ui'
import { useRouter } from 'next/router'
import Container from './Container'

export type ProgressStepsSize = 'normal' | 'large'

const ProgressSteps: React.FC<{ customSteps?: CheckoutStep[] }> = ({ customSteps }) => {
  const router = useRouter()
  const pageUrl = router.asPath

  // Determine the active step index
  const activeStepIndex = useMemo(() => {
    switch (pageUrl) {
      case '/en/order/checkout':
        return 0
      case '/en/order/payment':
        return 1
      default:
        return -1 // No active step if the page isn't in the checkout flow
    }
  }, [pageUrl])

  // Get completed steps (all before the active step)
  const completeSteps = useMemo(() => {
    return activeStepIndex > 0 ? [...Array(activeStepIndex).keys()] : []
  }, [activeStepIndex])

  // Define checkout steps dynamically
  const checkoutSteps: CheckoutStep[] = useMemo(
    () =>
      CHECKOUT_STEPS.map((step, index) => ({
        label: step.label,
        icon: step.icon,
        active: index === activeStepIndex,
        complete: completeSteps.includes(index),
      })),
    [activeStepIndex, completeSteps]
  )

  const steps = customSteps ?? checkoutSteps

  const renderSteps = () => (
    <StepContainer>
      {steps.map(({ active, label, icon, complete }, index) => (
        <Step key={index} data-testid={`step-${label}-${active}`} complete={complete}>
          <IconWrapper active={active} complete={complete}>
            {icon && <Icon name={icon} />}
          </IconWrapper>
          <Label active={active} complete={complete}>
            {label}
          </Label>
        </Step>
      ))}
    </StepContainer>
  )

  return customSteps ? (
    <Wrapper>
      <Container size="narrow" spacing="none">
        {renderSteps()}
      </Container>
    </Wrapper>
  ) : (
    renderSteps()
  )
}

export default ProgressSteps
