import { CartListItemProperties, CheckoutStartedEventProperties } from '@segment/analytics-next'

import { DEFAULT_CURRENCY_CODE } from '@/constant/segment'
import { makeProductBaseProperties } from '@/helpers/AnalyticsHelper'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'
import { AnalyticsTrackEvent, SegmentCartData } from '@/types'
import { centsToDollars } from '@big-red-group/storefront-common-checkout'

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Checkout+Started#Properties
 */
export const createCheckoutStartedEvent = (
  cart: SegmentCartData,
  currency?: string
): Extract<AnalyticsTrackEvent, { object: 'Checkout'; action: 'Started' }> => {
  const { uuid, total, subtotal, items: products = [], couponPromoCode, couponDiscount } = cart

  const productList = products.reduce<CartListItemProperties[]>((productList, product) => {
    const { variants, ...productBaseProperties } = makeProductBaseProperties({ product, includeSessions: true })

    // In case there are variants computed from cart data, flatten them out
    if (variants?.length) {
      return [
        ...productList,
        ...variants.map<CartListItemProperties>((variant) => ({
          ...productBaseProperties,
          currency: currency || DEFAULT_CURRENCY_CODE,
          price: centsToDollars(roundDecimalNumber(variant.price! || 0)),
          quantity: variant.quantity || productBaseProperties.quantity,
          variant_id: variant.id,
          variant_name: variant.name,
          variant_type: variant.type,
          session_id: variant.session_id,
          session_name: variant.session_name,
        })),
      ]
    }

    // Otherwise, add data normally
    return [...productList, productBaseProperties]
  }, [])

  const eventProperties: CheckoutStartedEventProperties = {
    products: productList,
    cart_id: uuid || '',
    // Subtotal in Segment term is order/cart total AFTER discount but before tax and shipping
    // Subtotal value in payload is the order/cart total BEFORE discount (not sure if before or after tax)
    subtotal: centsToDollars(roundDecimalNumber(subtotal || 0)),
    total: centsToDollars(roundDecimalNumber(total || 0)),
    // TODO: Provision tax data from payload if available
    tax: 0,
    promo_code: '',
    currency: currency || productList[0]?.currency || DEFAULT_CURRENCY_CODE,
  }

  // TODO: When subtotal is available in the cart response
  // if (!eventProperties.subtotal && products?.length) {
  //   const { computedTotal, computedSubtotal } = products.reduce<{ computedTotal: number; computedSubtotal: number }>(
  //     (computed, product) => ({
  //       computedTotal: computed.computedTotal + product.total,
  //       computedSubtotal: computed.computedSubtotal + product.subTotal,
  //     }),
  //     { computedTotal: 0, computedSubtotal: 0 }
  //   )

  //   eventProperties.subtotal = roundDecimalNumber(computedSubtotal)
  //   eventProperties.total = roundDecimalNumber(computedTotal)
  // }

  if (couponDiscount) {
    eventProperties.discount = centsToDollars(roundDecimalNumber(couponDiscount))
  }

  if (couponPromoCode) {
    const { coupon, promoCode } = couponPromoCode
    eventProperties.coupon = coupon ? coupon!.code : ''
    eventProperties.promo_code = promoCode ? promoCode!.code : ''
  }

  return {
    object: 'Checkout',
    action: 'Started',
    properties: eventProperties,
  }
}
