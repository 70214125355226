import type { AnalyticsBrowser } from '@segment/analytics-next'
import { useContext } from 'react'

import { AnalyticsSegmentContext } from '@/context/AnalyticsSegmentContext'
import { isClient } from '@/helpers/ClientSideRenderHelper'
import { logger } from '@/logging/Logger'

const log = logger()

export const useSegment = (): AnalyticsBrowser | null => {
  const context = useContext(AnalyticsSegmentContext)

  if (!context && isClient()) {
    log.error('Incorrect write key or no write key for Segment tracking library was supplied')
  }

  return context
}
