import currency from 'currency.js'
// TODO: formatCurrency should be replaced
export const formatCurrency = (val: number) => currency(val).format()

export const formatPrice = (val: number) => currency(val, { fromCents: true }).format()

export const convertPriceToDollars = (val: number) => val / 100

export const multiplyCurrency = (val1: number, val2: number) => currency(val1).multiply(val2).value

export const addCurrency = (val1: number, val2: number) => currency(val1).add(val2).value

export const integerCurrencyToFloat = (val: number, decimalPoints: number) =>
  currency(val, { fromCents: true, precision: decimalPoints })

export const centsCurrencyToInteger = (val: number) => currency(val / 100, { precision: 0 }).format()
