import { useState, useEffect, useRef } from 'react'
import {
  Burger,
  BurgerLine,
  Icons,
  MenuContainer,
  SearchContainer,
  UserInitials,
  TopWrapper,
  Logo,
  BodySpacer,
  TopbarContainer,
  MenuItemWrapper,
  CartIcon,
  Total,
  BottomWrapper,
  MobileMenuLinks,
  BrowserThemeColorDetector,
} from './Topbar.styles'
import dynamic from 'next/dynamic'
const HappyHour = dynamic(() => import('./HappyHour'), { ssr: false })
import Icon from './Icons'
import { useAuthSession } from '@/hooks/AuthSessionHook'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDefaultProperties } from '@/hooks/UseDefaultPropertiesHook' //TODO: topbar data
import Button from '@/components/Button'
import { useCart } from '@big-red-group/storefront-common-checkout'
import { Body, SearchPopup, Spacing } from '@big-red-group/storefront-common-ui'
import Container from './Container'
import TopbarSubmenus from './TopbarSubmenus'
import { ModalTypes, SearchInteractionInput, TopbarConfigurationSubLink } from '@/types'
import { MobileHeader, MobileHeaderIcon, MobileTitle } from './TopbarSubmenus.styles'
import { useAnalyticsSearchModalInteraction } from '@/hooks/AnalyticsSearchModalInteractionHook'
import { useAnalyticsBrowserColorTheme } from '@/hooks/AnalyticsBrowserThemeHook'
import ProductTile from './ProductTile'
import 'swiper/css'
import { DEFAULT_LANGUAGE } from '@/helpers/UrlHelper'
import { useProductClicked } from '@/hooks/AnalyticsProductHook'

const Topbar = () => {
  const placement = 'Topbar'
  const searchType: ModalTypes = 'search'

  const router = useRouter()
  const { lang } = router.query
  const defaultProperties = useDefaultProperties()
  const { data: cart } = useCart('cart')
  const total = cart?.items?.length || 0
  // Auth
  const { authenticated, unauthenticated, loading, user } = useAuthSession()

  const trackSearchModalInteraction = useAnalyticsSearchModalInteraction()
  const analyticsBrowserTheme = useAnalyticsBrowserColorTheme()
  const trackProductClicked = useProductClicked()

  const happyHour = defaultProperties?.happyHourEvent
  const destinations = defaultProperties?.topbarConfiguration?.destinations
  const categoryTypes = defaultProperties?.topbarConfiguration?.categoryTypes
  const displayableLinks = defaultProperties?.topbarConfiguration?.displayableLinks
  // TODO: Remove this
  const updatedDisplayableLinks = displayableLinks?.filter((link) => link.label !== 'Gift Vouchers')
  const mobileMenuLinks = [
    { label: 'Sign in', url: '/en/login' },
    { label: 'Help', url: '/en/faqs' },
    { label: 'Blog', url: '/en/blog' },
  ]

  const [isOffCanvasMenuOpen, setOffCanvasMenuOpen] = useState(false)
  const [offCanvasSubmenu, setOffCanvasSubmenu] = useState('')
  const [submenuSecondLevel, setSubmenuSecondLevel] = useState('')

  const browserThemeDetectorRef = useRef<HTMLInputElement>(null)

  const getInitials = (firstName?: string | null, lastName?: string | null) => {
    const firstInitial = firstName && firstName.length > 0 ? firstName.charAt(0) : ''
    const lastInitial = lastName && lastName.length > 0 ? lastName.charAt(0) : ''
    return `${firstInitial}${lastInitial}`.toUpperCase()
  }

  const renderUser = () => {
    if (loading) return null

    if (unauthenticated) {
      return (
        <Link href={`/${router.query?.lang ?? DEFAULT_LANGUAGE}/login`} data-testid="topbar-login">
          <Icon name="personOutline" width={24} height={24} />
        </Link>
      )
    }

    if (authenticated) {
      return (
        <UserInitials
          onClick={() => {
            window.location.href = `/${lang}/account`
          }}
          data-testid="initials"
        >
          <Body size="XS" weight="medium">
            {getInitials(user?.firstName, user?.lastName)}
          </Body>
        </UserInitials>
      )
    }

    return (
      <Button
        secondary
        onClick={() => router.push(`/${router.query?.lang ?? DEFAULT_LANGUAGE}/login`)}
        dataTestId="topbar-login"
      >
        Login
      </Button>
    )
  }

  const handleBurgerMenu = () => {
    if (isOffCanvasMenuOpen) {
      handleCloseOffCanvasMenu()
      return
    }
    document.body.style.overflow = 'hidden'
    setOffCanvasMenuOpen(true)
  }

  const handleOpenSubmenu = (submenu?: string, secondMenu?: string) => {
    if (submenu) {
      setOffCanvasSubmenu(submenu)
    }
    if (window.innerWidth < 1100) {
      document.body.style.overflow = 'hidden'
    }
    if (window.innerWidth > 1100 && secondMenu) {
      setSubmenuSecondLevel(secondMenu)
    }
  }

  const handleHoverOpenSubmenu = (submenu?: string, secondMenu?: string) => {
    if (window.innerWidth <= 1100) return
    handleOpenSubmenu(submenu, secondMenu)
  }

  const handleCloseOffCanvasMenu = () => {
    setOffCanvasMenuOpen(false)
    setOffCanvasSubmenu('')
    setSubmenuSecondLevel('')
    document.body.style.overflow = 'unset'
  }

  const handleSearchModalOpen = () => {
    try {
      trackSearchModalInteraction({ toggle: 'on', placement, type: searchType })
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearchModalClose = (isPerformedSearch = false, trackingExtras?: any) => {
    try {
      if (!isPerformedSearch) {
        const extras = {
          autocorrectValues: trackingExtras?.suggestions,
          totalProducts: trackingExtras?.products?.length,
          displayProducts: trackingExtras?.products?.slice(0, 4),
          searchedInput: trackingExtras?.searchedInput,
          searchedValue: trackingExtras?.searchedValue,
          suggestedCategories: trackingExtras?.suggestedCategories?.slice(0, 6),
          suggestedLocations: trackingExtras?.suggestedRegions?.slice(0, 6),
        }
        trackSearchModalInteraction({ toggle: 'off', placement, type: searchType, extras: extras })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const trackSearch = (input: SearchInteractionInput) => {
    try {
      trackSearchModalInteraction({ toggle: 'search', placement, type: searchType, interaction: input })
    } catch (error) {
      console.error(error)
    }
  }

  const handleNavigate = (param: any) => {
    if (param.extras.action === 'onPressEnter') {
      trackSearch({
        isProductsSearched: true,
        searchedInput: param.extras?.searchedInput,
        searchedValue: param.extras?.searchedValue,
        autocorrectValues: param.extras?.suggestions,
        isLocationSearched: param.type === 'region' ? true : false,
        isCategorySearched: param.type === 'category' ? true : false,
        suggestedCategories: param.extras?.suggestedCategories?.slice(0, 6),
        suggestedLocations: param.extras?.suggestedRegions?.slice(0, 6),
        totalProducts: param.extras?.products?.length,
        displayProducts: param.extras?.products?.slice(0, 4),
      })
    } else if (param.extras.action === 'onClick') {
      trackSearch({
        isProductsSearched: true,
        searchedInput: param.extras?.searchedInput,
        searchedValue: param.extras?.searchedValue,
        autocorrectValues: param.extras?.suggestions,
        suggestedCategories: param.extras?.suggestedCategories?.slice(0, 6),
        suggestedLocations: param.extras?.suggestedRegions?.slice(0, 6),
        isAutoCorrectionClicked: param.extras?.isAutoCorrectionClicked,
        isSuggestedCategoryClicked: param.extras?.isSuggestedCategoryClicked,
        isCategorySearched: param.extras?.isCategorySearched,
        isSuggestedLocationClicked: param.extras?.isSuggestedRegionClicked,
        isLocationSearched: param.extras?.isRegionSearched,
        totalProducts: param.extras?.products?.length,
        displayProducts: param.extras?.products?.slice(0, 4),
      })
    } else if (param.extras.action === 'productClick') {
      trackSearch({
        isProductsSearched: true,
        searchedInput: param.extras?.searchedInput,
        searchedValue: param.extras?.searchedValue,
        autocorrectValues: param.extras?.suggestions,
        suggestedCategories: param.extras?.suggestedCategories?.slice(0, 6),
        suggestedLocations: param.extras?.suggestedRegions?.slice(0, 6),
        isProductClicked: param.extras?.isProductClicked,
        product: param.extras?.product,
        totalProducts: param.extras?.product?.length,
        displayProducts: param.extras?.products?.slice(0, 4),
        isAutoCorrectionClicked: param.extras?.isAutoCorrectionClicked,
        isRecentlyViewed: param.extras?.isRecentlyViewed,
      })
      trackProductClicked({
        product: param.extras?.product,
        position: param.extras?.position,
        isRecommended: true,
        listTitle: param.extras?.listTitle,
        isCarousel: true,
      })
    } else if (param.extras.action === 'seeMoreClick') {
      trackSearch({
        isProductsSearched: true,
        searchedInput: param.extras?.searchedInput,
        searchedValue: param.extras?.searchedValue,
        autocorrectValues: param.extras?.suggestions,
        suggestedCategories: param.extras?.suggestedCategories?.slice(0, 6),
        suggestedLocations: param.extras?.suggestedRegions?.slice(0, 6),
        totalProducts: param.extras?.products?.length,
        displayProducts: param.extras?.products?.slice(0, 4),
        isSeeMoreClicked: true,
      })
    }
  }

  const renderMenuItem = (label: string, subLinks?: TopbarConfigurationSubLink[] | string) => {
    const defaultSubmenu = subLinks && Array.isArray(subLinks) ? subLinks[0].label : subLinks

    return (
      <MenuItemWrapper
        key={label}
        data-testid={`${label.toLowerCase()}-topbar`}
        onMouseOver={() => {
          handleHoverOpenSubmenu(label, subLinks ? defaultSubmenu : '')
        }}
        onClick={() => {
          handleOpenSubmenu(label, subLinks ? defaultSubmenu : '')
        }}
        isActive={label.length > 0 && offCanvasSubmenu == label}
      >
        {label}
        <Icon name="chevronDown" width={16} height={17} />
      </MenuItemWrapper>
    )
  }

  const renderMainHeaderLinks = () => {
    if (!updatedDisplayableLinks) return

    return (
      <>
        {updatedDisplayableLinks.map((item, index) => {
          if (item.subLinks) {
            return renderMenuItem(item.label, item?.subLinks)
          } else {
            return (
              <MenuItemWrapper
                key={item.label}
                onMouseOver={() => !(window.innerWidth <= 1100) && handleCloseOffCanvasMenu()}
                href={item.url!}
              >
                {item.label}
              </MenuItemWrapper>
            )
          }
        })}
      </>
    )
  }

  const renderMobileMenuLinks = () => {
    return (
      <MobileMenuLinks>
        <Spacing size="L" />
        {mobileMenuLinks.map((menuLink, index) => (
          <MenuItemWrapper key={index} href={menuLink.url}>
            {menuLink.label}
          </MenuItemWrapper>
        ))}
        <Spacing size="L" />
      </MobileMenuLinks>
    )
  }

  const handleButtonClick = (link: string) => {
    window.location.href = link
    handleCloseOffCanvasMenu()
  }

  const topbarRef = useRef<HTMLInputElement>(null)
  const [topbarHeight, setTopbarHeight] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      if (topbarRef.current !== null) {
        setTopbarHeight(topbarRef.current.clientHeight)
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (browserThemeDetectorRef.current !== null) {
      const handleDarkModePrefferedChange = () => {
        const themeColor = window
          ?.getComputedStyle(browserThemeDetectorRef.current!, null)
          .getPropertyValue('background-color')
        analyticsBrowserTheme(themeColor)
      }

      window.addEventListener('click', handleDarkModePrefferedChange)

      return () => {
        window.removeEventListener('click', handleDarkModePrefferedChange)
      }
    }
  }, [analyticsBrowserTheme])

  return (
    <>
      <TopbarContainer ref={topbarRef}>
        {happyHour && <HappyHour content={happyHour.content} mobileContent={happyHour?.mobileContent} />}
        <TopWrapper data-testid="nav-top-wrapper" onMouseEnter={handleCloseOffCanvasMenu}>
          <Container>
            <MenuContainer>
              <Burger data-testid="burger-menu" isToggled={isOffCanvasMenuOpen} onClick={handleBurgerMenu}>
                <BurgerLine className="line-1" />
                <BurgerLine className="line-2" />
                <BurgerLine className="line-3" />
              </Burger>
              <Link href={`/${lang}`}>
                <Logo>
                  <Icon name="logoPrimary" width={136} height={31} color="#70edff" />
                </Logo>
              </Link>
              <SearchPopup
                lang={`${lang || 'en'}`}
                popularUrl={`/adventures/new-arrivals`}
                onToggleOpen={({ isOpen, data }) => {
                  if (isOpen) {
                    handleSearchModalOpen()
                    return
                  } else if (data) {
                    handleSearchModalClose(data.isPerformedSearch, data.trackingExtras)
                  }
                }}
                onSearch={async (param) => {
                  const { query, limit } = param
                  const res = await (
                    await fetch(`/api/search`, { method: 'POST', body: JSON.stringify({ query, limit, lang }) })
                  ).json()
                  return res
                }}
                onNavigate={handleNavigate}
                onProductRender={(product) => (
                  <ProductTile
                    id={product.id}
                    title={product.name}
                    supplier={product.operatorName}
                    price={product.retailPrice}
                    fromPrice={product.fromPrice}
                    region={product.primaryRegion?.replace(/-/, ' ')}
                    canonicalRegionUrlSegment={product.canonicalRegionUrlSegment}
                    image={product.images?.[0] || ''}
                    url={product.url}
                    onClick={() => {}}
                    mobileCardTile={false}
                    rating={product.rating}
                  />
                )}
              />
            </MenuContainer>
            <SearchContainer>
              <Icons>
                {renderUser()}
                {/* TODO: enable when we have wishlist funcationality */}
                {/* <Link href="#">
                  <Icon name="heart" />
                </Link> */}
                <Link href="/en/order/checkout">
                  <CartIcon>
                    {total > 0 && <Total>{total}</Total>}
                    <Icon name="cart" width={24} height={24} />
                  </CartIcon>
                </Link>
              </Icons>
            </SearchContainer>
          </Container>
        </TopWrapper>
        <BottomWrapper active={isOffCanvasMenuOpen}>
          <Container>
            <MobileHeader align="flex-end">
              <MobileHeaderIcon onClick={handleCloseOffCanvasMenu}>
                <Icon name="close" width={20} height={20} />
              </MobileHeaderIcon>
            </MobileHeader>
            <MobileTitle>
              <Body size="XL" weight="bold">
                Explore
              </Body>
            </MobileTitle>
            {renderMenuItem('Adventures', 'Flying Adventures')}
            {renderMenuItem('Location', 'New South Wales')}
            {renderMenuItem('Gift Ideas', 'Occasions')}
            {renderMainHeaderLinks()}
            {renderMobileMenuLinks()}
            <button onClick={() => handleButtonClick(`/${lang}/redeem`)}>Use a voucher</button>
          </Container>
        </BottomWrapper>
        <TopbarSubmenus
          topbarHeight={topbarHeight}
          triggerLogin={() => (window.location.href = `/${router.query?.lang ?? DEFAULT_LANGUAGE}/login`)}
          submenuToggled={offCanvasSubmenu}
          activeSecondMenu={submenuSecondLevel}
          onClose={handleCloseOffCanvasMenu}
          onMenuChange={setOffCanvasSubmenu}
          onSecondMenuChange={setSubmenuSecondLevel}
          destinations={destinations}
          categoryTypes={categoryTypes}
          displayableLinks={updatedDisplayableLinks}
          onButtonClick={handleButtonClick}
        />
      </TopbarContainer>
      <BrowserThemeColorDetector ref={browserThemeDetectorRef} id="detection"></BrowserThemeColorDetector>
      <BodySpacer height={topbarHeight} />
    </>
  )
}

export default Topbar
