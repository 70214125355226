import Notification from '@/components/Notification'
import { NotificationType } from '@/types'
import { toast } from 'react-hot-toast'
import { generateHtmlSafeId } from '@/helpers/UtilHelper'

export const useNotification = () => {
  return (text: string, title: string = 'Alert', type: NotificationType = 'success') => {
    toast(<Notification text={text} title={title} type={type} />, {
      position: 'top-right',
      id: generateHtmlSafeId(text),
    })
  }
}
