import styled from 'styled-components'
import { BrandColor } from '@big-red-group/storefront-common-ui'

export const Wrapper = styled.label<{ color: BrandColor | undefined }>`
  font-size: 18px;
  line-height: 1.1;
  font-family: var(--font-heading);
  color: ${({ color }) => (color ? `var(--${color})` : 'inherit')};
  text-transform: uppercase;
  letter-spacing: 1.5px;
`
