import { ProductSessionCheckedEventProperties } from '@segment/analytics-next'

import { AnalyticsTrackEvent, CreateProductSessionCheckedEventInput } from '@/types'
import { adjustGeolocations, makeProductBaseProperties } from '../helpers/AnalyticsHelper'

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2515930303/Product+Session+Checked#Properties
 */
export const createProductSessionCheckedEvent = ({
  product,
  url,
  sessionFrom,
  sessionTo,
  listId,
  listName,
  isRecommended = false,
  isProductEdit = false,
  bookingCalendarType = 'Default',
}: CreateProductSessionCheckedEventInput): Extract<
  AnalyticsTrackEvent,
  { object: 'Product Session'; action: 'Checked' }
> => {
  const productBaseProperties = makeProductBaseProperties({ product })

  const { geolocations } = product
  const locations = geolocations ? adjustGeolocations(geolocations) : []

  const eventProperties: ProductSessionCheckedEventProperties = {
    ...productBaseProperties,
    url,
    locations,
    session_from: sessionFrom?.toISOString() || '',
    session_to: sessionTo?.toISOString() || '',
    list_id: listId || '',
    list_name: listName || '',
    is_recommendation: isRecommended,
    is_product_edit: isProductEdit,
    calendar_type: bookingCalendarType,
  }

  return {
    object: 'Product Session',
    action: 'Checked',
    properties: eventProperties,
  }
}
