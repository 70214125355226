import React, { useMemo } from 'react'

import { SAVED_CACHE_KEY_NAME, SAVED_CACHE_REGEXP, SEGMENT_WRITE_KEY_SESSION_KEY } from '@/constant/segment'
import { AnalyticsMetaDataContext } from '@/context/AnalyticsMetaDataContext'
import { getPrioritisedProductItem, isPlpProduct, isProduct, isProductDetails } from '@/helpers/AnalyticsHelper'
import { isClient } from '@/helpers/ClientSideRenderHelper'
import { decryptJSON, encryptJSON } from '@/helpers/EncryptionHelper'
import { logger } from '@/logging/Logger'
import {
  AnalyticsMetaData,
  AnalyticsMetadataCache,
  CommonAnalyticsProvider,
  PlpProduct,
  Product,
  ProductComposite,
  ProductDetails,
  ProductRecommendation,
  SegmentCartItem,
  SegmentConfiguration,
} from '@/types'

const log = logger()

export const AnalyticsMetaDataProvider: React.FC<CommonAnalyticsProvider> = ({ children }) => {
  const analyticsMetaData: AnalyticsMetaData = useMemo(() => {
    const cache: AnalyticsMetadataCache = {
      context: {},
      cart: {},
      items: { products: {}, variants: {} },
      orderDetailsEntered: false,
      paymentFailed: false,
      paymentIsBeingProcessed: false,
      isPopularCategory: false,
      browserThemeColor: 'Light',
    }

    return {
      setContextOptions(contextOptions) {
        if (contextOptions) {
          const { context, integrations } = contextOptions
          const { traits, campaign } = context || {}

          if (campaign || integrations || traits) {
            cache.context = {
              ...cache.context,
              context: {
                ...cache.context.context,
                ...(traits
                  ? {
                      traits: {
                        ...cache.context.context?.traits,
                        ...traits,
                      },
                    }
                  : {}),
                ...(campaign ? { campaign } : {}),
              },
              ...(integrations ? { integrations } : {}),
            }
          }
        }
      },

      getContextOptions() {
        if (!Object.keys(cache.context).length) {
          return
        }

        return cache.context
      },

      addItemToCache(item) {
        const productId = isProductDetails(item) || isProduct(item) || isPlpProduct(item) ? item.id : item.productId

        if (!productId) {
          return
        }

        const cachedItem = cache.items.products[productId.toString()]

        const prioritisedProductItemInput: (
          | PlpProduct
          | ProductDetails
          | ProductComposite
          | Product
          | ProductRecommendation
        )[] = []

        // Always pass product data when not exist
        if (!cachedItem || isProductDetails(item)) {
          prioritisedProductItemInput[0] = item
        } else {
          prioritisedProductItemInput[0] = cachedItem
          prioritisedProductItemInput[1] = item
        }

        const [product, variants] = getPrioritisedProductItem(
          prioritisedProductItemInput[0],
          prioritisedProductItemInput[1]
        )
        cache.items.products = { ...cache.items.products, ...product }
        cache.items.variants = { ...cache.items.variants, ...variants }

        return
      },

      getCachedItems() {
        return cache.items
      },

      cacheCart(cart) {
        if (!cart) {
          cache.cart = {}
        } else {
          const { items: cartItems = [], ...cartMetadata } = cart

          cache.cart = {
            metadata: cartMetadata,
            items: cartItems.reduce<{ [k: string]: SegmentCartItem }>(
              (cartItemMap, cartItem) => ({ ...cartItemMap, [cartItem.uuid.toString()]: cartItem }),
              {}
            ),
          }
        }

        // Save this into cache for further use
        this.memoriseCache()
      },

      getCachedCart() {
        return cache.cart
      },

      cacheSegmentWriteKey(
        writeKey,
        apiHost = '',
        cdnUrl = '',
        brandGroup = '',
        brandType = '',
        siteName = '',
        currencyCode = ''
      ) {
        try {
          const encryptedData = encryptJSON({
            writeKey,
            apiHost,
            cdnUrl,
            brandGroup,
            brandType,
            siteName,
            currencyCode,
          } as SegmentConfiguration)

          // Construct the cache as string
          const cachedString = this.constructCacheString(...encryptedData)

          // Write into session storage, which will only live during the current session
          window?.sessionStorage?.setItem(SEGMENT_WRITE_KEY_SESSION_KEY, cachedString)
        } catch (error) {
          if (isClient()) {
            log.error('Encountered error when trying to memorise metadata cache:', {}, error as Error)
          }
        }
      },

      getSegmentWriteKeyFromCache() {
        try {
          const cachedData = window?.sessionStorage?.getItem(SEGMENT_WRITE_KEY_SESSION_KEY)

          if (cachedData && new RegExp(SAVED_CACHE_REGEXP, 'ig').test(cachedData)) {
            // Restructure mandatory parameters for decryption of message
            const encryptedData = this.destructureCacheString(cachedData)

            if (encryptedData) {
              const [encryptedText, key, iv, authTag] = encryptedData

              const decryptedData = decryptJSON(
                encryptedText.toString(),
                key as Buffer,
                iv as Buffer,
                authTag as Buffer
              )

              // Parse decrypted string into JSON, if not, the catch block will emit error
              return JSON.parse(decryptedData) as SegmentConfiguration
            }
          } else {
            // Remove cached item if not conform with cache structure
            window?.sessionStorage?.removeItem(SEGMENT_WRITE_KEY_SESSION_KEY)
          }
        } catch (error) {
          if (isClient()) {
            log.error('Encountered error when trying to restore Segment cache:', {}, error as Error)
          }
        }
      },

      // Restore cache string from local storage on load and remove it
      restoreCache() {
        try {
          const cachedData = window?.sessionStorage?.getItem(SAVED_CACHE_KEY_NAME)

          // TODO: RegExp
          // if (cachedData && new RegExp(SAVED_CACHE_REGEXP, 'ig').test(cachedData)) {
          if (cachedData) {
            // Restructure mandatory parameters for decryption of message
            const encryptedData = this.destructureCacheString(cachedData)

            if (encryptedData) {
              const [encryptedText, key, iv, authTag] = encryptedData

              const decryptedData = decryptJSON(
                encryptedText.toString(),
                key as Buffer,
                iv as Buffer,
                authTag as Buffer
              )

              // Parse decrypted string into JSON, if not, the catch block will emit error
              const restoredCache: AnalyticsMetadataCache = JSON.parse(decryptedData)

              cache.context = { ...restoredCache.context, ...cache.context }
              cache.cart = { ...restoredCache.cart, ...cache.cart }
              cache.items = {
                ...restoredCache.items,
                products: { ...restoredCache.items?.products, ...cache.items?.products },
                variants: { ...restoredCache.items?.variants, ...cache.items?.variants },
              }

              if (restoredCache.purchaser) {
                cache.purchaser = { ...restoredCache.purchaser, ...cache.purchaser }
              }
            }
          }

          // Remove cached item
          window?.sessionStorage?.removeItem(SAVED_CACHE_KEY_NAME)
        } catch (error) {
          if (isClient()) {
            log.info('Encountered error when trying to restore metadata cache', {}, error as Error)
          }
        }
      },

      // Memorise cache as string into local storage before unload
      memoriseCache() {
        try {
          const encryptedData = encryptJSON(cache)

          // Construct the cache as string
          const cachedString = this.constructCacheString(...encryptedData)

          // Write into session storage, which will only live during the current session
          window?.sessionStorage?.setItem(SAVED_CACHE_KEY_NAME, cachedString)
        } catch (error) {
          if (isClient()) {
            log.error('Encountered error when trying to memorise metadata cache', {}, error as Error)
          }
        }
      },

      constructCacheString(output, key, iv, authTag) {
        const keyString = key.toJSON().data.toString()
        const ivString = iv.toJSON().data.toString()
        const authTagString = authTag.toJSON().data.toString()

        return `${output}.[${keyString}].[${ivString}].[${authTagString}]`
      },

      destructureCacheString(cacheString) {
        const cacheSplit = cacheString.split('.')

        if (cacheSplit.length != 4) {
          throw new Error(
            `Invalid cached string detected, it should have 4 elements after split but received ${cacheSplit.length} element(s)`
          )
        }

        // TODO
        // if (!new RegExp(SAVED_CACHE_REGEXP, 'ig').test(cacheString)) {
        //   throw new Error('Invalid cache string detected')
        // }

        const keySplit = Buffer.from(JSON.parse(cacheSplit[1]))
        const ivSplit = Buffer.from(JSON.parse(cacheSplit[2]))
        const authTagSplit = Buffer.from(JSON.parse(cacheSplit[3]))

        return [cacheSplit[0], keySplit, ivSplit, authTagSplit]
      },

      setPaymentFailed(paymentFailed) {
        cache.paymentFailed = paymentFailed
      },

      paymentFailed() {
        return cache.paymentFailed
      },

      setPaymentProcessing(isProcessing) {
        cache.paymentIsBeingProcessed = isProcessing
      },

      paymentIsBeingProcessed() {
        return cache.paymentIsBeingProcessed
      },

      setOrderDetailsEntered(hasEntered) {
        cache.orderDetailsEntered = hasEntered
      },

      orderDetailsEntered() {
        return cache.orderDetailsEntered
      },

      setPurchaser(purchaser) {
        if (purchaser) {
          cache.purchaser = purchaser
        }
      },

      getPurchaser() {
        return cache.purchaser
      },

      setIsPopularCategory(isPopularCategory) {
        if (isPopularCategory) {
          cache.isPopularCategory = isPopularCategory
        }
      },

      getIsPopularCategory() {
        return cache.isPopularCategory
      },

      setCartJWEToken(cartJWEToken) {
        if (cartJWEToken) {
          cache.cartJWEToken = cartJWEToken
        }
      },

      getCartJWEToken() {
        return cache.cartJWEToken
      },

      getBrowserThemeColor() {
        return cache.browserThemeColor
      },

      setBrowserThemeColor(color) {
        if (color) {
          cache.browserThemeColor = color
        }
      },
    }
  }, [])

  return <AnalyticsMetaDataContext.Provider value={analyticsMetaData}>{children}</AnalyticsMetaDataContext.Provider>
}
