import { PageEventAction } from '@segment/analytics-next'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'

import { INTENTIONAL_RELOAD_KEY_NAME, PAGE_BOUNCE_THRESHOLD, SUCCESSFUL_PAYMENT_MSG } from '@/constant/segment'
import { PAYMENT_PAGE_PATH } from '@/constant/ui'
import { createPageEvent } from '@/events'
import { postWindowMessage } from '@/helpers/AnalyticsHelper'
import { isClient, parseQueryParams } from '@/helpers/ClientSideRenderHelper'
import { useAnalytics } from '@/hooks/AnalyticsHook'
import { useAnalyticsMetaData } from '@/hooks/AnalyticsMetaDataHook'
import { useAuthSession } from '@/hooks/AuthSessionHook'

export const AnalyticsPageTracker: React.FC = () => {
  const router = useRouter()
  const analytics = useAnalytics()
  const { loading: isLoading, user } = useAuthSession()

  const [currentPath, setCurrentPath] = useState<string | undefined>()
  const [setContext, toggleSetContext] = useState(false)

  // Get query parameters (this includes query paths and search parameters) from the URL
  const analyticsMetaData = useAnalyticsMetaData()
  const { query } = router

  const timeLanded = Date.now()

  const handlerPageUnload = useCallback(
    (unloadEvent: BeforeUnloadEvent) => {
      /**
       * According to this documentation: https://stripe.com/docs/js/payment_intents/confirm_payment, Stripe will redirect to the specified return URL without waiting for its Promise to resolve if payment is successful.
       * In this case, the Checkout Step Completed event will not be tracked properly if triggered inside PaymentForm.tsx since it will be redirected before any asynchronous hook could be resolved.
       * As such, switch to use before unload approach without synchronously resolved metadata values should be more appropriate since the page will be reloaded after payment intent is confirmed by Stripe.
       */
      if (
        window.location.pathname.indexOf(PAYMENT_PAGE_PATH) !== -1 &&
        analyticsMetaData.paymentIsBeingProcessed() &&
        !analyticsMetaData.paymentFailed()
      ) {
        postWindowMessage(window, SUCCESSFUL_PAYMENT_MSG)
      }

      // Save cache into session storage in case the page is reloaded
      // If the tab or browser is closed, this will not affect anything
      analyticsMetaData.memoriseCache()

      /**
       * Do not send page events if page is redirected or reload intentionally.
       * This indicator will be set when needed to avoid sending Page Bounced/Exited event unnecessarily.
       */
      if (window.sessionStorage.getItem(INTENTIONAL_RELOAD_KEY_NAME) === 'true') {
        return
      }

      // Measuring time spent in seconds
      const timeSpent = Math.floor((Date.now() - timeLanded) / 1000)

      const action: PageEventAction = timeSpent < PAGE_BOUNCE_THRESHOLD ? 'Bounced' : 'Exited'

      const eventInput = {
        timeSpent,
        isTrusted: unloadEvent.isTrusted,
        eventAction: action,
      }

      const analyticsTrackEvent = createPageEvent(eventInput)
      analytics.trackEvent(analyticsTrackEvent)
    },
    [analytics, analyticsMetaData, timeLanded]
  )

  useEffect(() => {
    // Remove indicator on page load
    window.sessionStorage.removeItem(INTENTIONAL_RELOAD_KEY_NAME)

    if (!setContext) {
      const { gclid } = query
      if (gclid) {
        window.sessionStorage.setItem('gclid', gclid as string)
      }
      analyticsMetaData.setContextOptions(parseQueryParams(query))
      toggleSetContext(true)
    }

    if (!isLoading && currentPath !== router.asPath) {
      // Set the campaign data, if detected
      analytics.trackPage({ name: window.document.title, url: window.location.href }, user)

      analyticsMetaData.restoreCache()
      setCurrentPath(router.asPath)
    }
  }, [analytics, analyticsMetaData, currentPath, isLoading, query, router.asPath, setContext, user])

  useEffect(() => {
    // Detect exit before page start unloading
    if (isClient()) {
      window.addEventListener('beforeunload', handlerPageUnload)

      return () => {
        window.removeEventListener('beforeunload', handlerPageUnload)
      }
    }
  }, [handlerPageUnload])

  return null
}
