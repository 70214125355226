import type { CipherGCMTypes, Encoding } from 'crypto'
import { ProductBaseProperties } from '@segment/analytics-next'
import { Language } from '@big-red-group/storefront-common-checkout'

export const SEGMENT_USER_ID_KEY_NAME = 'ajs_user_id'
export const SEGMENT_USER_TRAITS_KEY_NAME = 'ajs_user_traits'

export const CUSTOM_PROXY_SUBDOMAIN_NAME = 'segment'
export const CUSTOM_CDN_SUBDOMAIN_NAME = 'analytics'
export const DEV_PROXY_ENDPOINT_EXTENSION = '-uat'
export const STAGING_PROXY_ENDPOINT_EXTENSION = '-preprod'
export const DEFAULT_TOP_LEVEL_DOMAIN = 'com.au'

export const MAXIMUM_SEGMENT_STRING_LENGTH = 900

export const COMPOSITE_KEY_SEPARATOR = '|'

export const DEFAULT_IMPRESSION_TIME = 1500

export const DEFAULT_VISIBILITY = 0.75

export const LIST_SIZE = 4

export const SEGMENT_WRITE_KEY_SESSION_KEY = 'sgWriteKey'

export const SAVED_CACHE_KEY_NAME = 'tempCache'

export const EDITING_FLAG = 'editing'

export const SAVED_CACHE_REGEXP = '^\\w+\\.\\[\\d+(,\\d+)*\\].\\[\\d+(,\\d+)*\\].\\[\\d+(,\\d+)*\\]$'

export const ENCRYPTION_ALGORITHM: CipherGCMTypes = 'aes-256-gcm'

export const ENCRYPTION_OUTPUT_ENCODING: Encoding = 'base64'

export const INTENTIONAL_RELOAD_KEY_NAME = 'pageSideTrackDisabled'

export const PAGE_BOUNCE_THRESHOLD = 3

export const SUCCESSFUL_PAYMENT_MSG = 'Payment Successful'

export const CACHE_EXPIRY_DATE_KEY_NAME = 'sessionExpiredAt'

// See https://redballoon.atlassian.net/wiki/spaces/BID/pages/2531196985/Hash+Email+Generation#Profile-Matching for more details
export const CUSTOMER_ID_SALT_PREFIX = 'BRG'
export const CUSTOMER_ID_SALT_SUFFIX = 'user'

// This is by far the relatively complete list of mobile platform names existing in userAgent
export const MOBILE_DEVICE_PATTERN =
  'Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini'
export const MACOS_PLATFORMS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
export const WINDOWS_PLATFORMS = ['Win32', 'Win64', 'Windows', 'WinCE']
export const IOS_PLATFORMS = ['iPhone', 'iPad', 'iPod']

export const MACOS_PLATFORM = 'macOS'
export const IOS_PLATFORM = 'iOS'
export const WINDOWS_PLATFORM = 'Windows'
export const ANDROID_PLATFORM = 'Android'
export const LINUX_PLATFORM = 'Linux'
export const UNKNOWN_PLATFORM = 'Unknown'

export const NAVIGATOR_USER_AGENT_DATA_KEY = 'userAgentData'

export const DEFAULT_GA_CLIENT_ID_COOKIE_PREFIX = '_ga'

export const DEFAULT_GA_SESSION_COOKIE_PREFIX = '_ga_'

export const DEFAULT_FBP_COOKIE_PREFIX = '_fbp'

export const DEFAULT_FBC_COOKIE_PREFIX = '_fbc'

export const DEFAULT_CURRENCY_CODE = 'AUD'

export const DEFAULT_BRAND_GROUP = 'Adrenaline'

export const DEFAULT_BRAND_TYPE = 'B2C | Adrenaline'

export const PRODUCTS_LENGTH_LIMIT = 30

export const DEFAULT_LANGUAGE: Language = 'en'

export const AUS_CALL_TO_BOOK_NUMBER = '1300 791 793'

export const TEST_CAMPAIGN = {
  utm_name: 'Dummy Name',
  utm_source: 'Dummy Source',
  utm_medium: 'Dummy Medium',
  utm_term: 'Dummy Term',
  utm_content: 'Dummy Campaign',
}

export const BASE_PRODUCT_PROPERTIES: ProductBaseProperties = {
  product_id: 0,
  sku: '',
  category: '',
  sub_category: '',
  categories: [],
  currency: DEFAULT_CURRENCY_CODE,
  name: '',
  brand: '',
  brand_group: DEFAULT_BRAND_GROUP,
  brand_type: DEFAULT_BRAND_TYPE,
  description: '',
  title: '',
  quantity: 1,
  url_segment: '',
  image_url: '',
  operator: {
    id: 0,
    region_id: '',
    type: '',
  },
  region_id: 0,
  is_recommendation: false,
}
