import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@/constant/ui'

const productImageHeight = '270px'

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  transition: 0.25s var(--cubicBezierImage);
  transform: scale(1);
  object-fit: cover;
`
export const ProductImageWrap = styled.div<{
  $mobileCardTile: boolean | undefined
  $smallDesktopTile: boolean | undefined
}>`
  position: relative;
  width: 100%;
  height: ${productImageHeight};
  margin-bottom: 18px;
  overflow: hidden;

  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile
      ? `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      width: 114px;
      height: 114px;
      margin: 0;
      border-radius: 2px;
      overflow: hidden;
      flex-shrink: 0;
    }
  `
      : `
    height: 200px;
  `}
`
export const Badges = styled.div<{ $mobileCardTile: boolean | undefined; $smallDesktopTile: boolean | undefined }>`
  position: absolute;
  top: calc(${productImageHeight} - 12px);
  transform: translateY(-100%);
  left: 12px;
  display: flex;
  width: calc(100% - 24px);
  flex-wrap: wrap;
  gap: 5px;

  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      position: static;
      padding-top: 4px;
      transform: none;
      width: 100%;
    }
  `}
`
export const Header = styled.div<{ $mobileCardTile: boolean | undefined; $smallDesktopTile: boolean | undefined }>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;

  p {
    line-height: 1;
    text-transform: capitalize;
  }

  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      margin-bottom: 5px;
    }
  `}
`
export const Main = styled.div<{ $mobileCardTile: boolean | undefined; $smallDesktopTile: boolean | undefined }>`
  margin-bottom: 10px;

  > p {
    line-height: 1.3;
  }

  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      grid-row-start: 1;
      margin-bottom: 5px;
    }
  `}
`
export const Rating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;

  svg {
    color: var(--brand-accent);
  }
  small {
    font-size: 85%;
    letter-spacing: 2px;
    margin-left: 3px;
  }
  span {
    line-height: 1;
  }
`
export const TextContent = styled.div<{ $mobileCardTile: boolean | undefined; $smallDesktopTile: boolean | undefined }>`
  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      display: grid;
      flex-grow: 1;
    }
  `}
`
export const WishList = styled.div<{
  $filled: boolean
  $mobileCardTile: boolean | undefined
  $smallDesktopTile: boolean | undefined
}>`
  position: absolute;
  top: 12px;
  right: 12px;

  svg {
    fill: ${({ $filled }) => ($filled ? '#FFFFFF' : 'rgba(0, 0, 0, 0.2)')};

    path {
      stroke: white;
      stroke: ${({ $filled }) => ($filled ? 'rgba(0, 0, 0, 0.2)' : '#FFFFFF')};
    }
  }

  &:hover {
    svg {
      fill: rgba(255, 255, 255, 0.2);
    }
  }

  ${({ $mobileCardTile, $smallDesktopTile, $filled }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      position: static;

      svg {
        fill: ${$filled ? 'var(--text-primary)' : 'transparent'};

        path {
        stroke: var(--text-primary);
        }
      }
    }   
  `}
`
export const Pricing = styled.div<{ onSale: boolean }>`
  ${({ onSale }) =>
    onSale &&
    `
    color: var(--sale);
  `}

  s {
    text-decoration: line-through;
    font-family: var(--font-regular);
    color: var(--brand-accent-secondary-dark);
    margin-left: 4px;
  }
`
export const Wrapper = styled(Link)<{ $mobileCardTile: boolean | undefined; $smallDesktopTile: boolean | undefined }>`
  text-decoration: none;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${ProductImage} {
      transform: scale(1.1);
    }
  }

  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      padding: 8px;
      overflow: hidden;
      border-radius: ${$smallDesktopTile ? '4px' : '0'};
      border: ${$smallDesktopTile ? '1px solid var(--brand-accent-secondary-dark)' : '0'};
      background: var(--bg-white);
    }
  `}

  ${({ $mobileCardTile, $smallDesktopTile }) =>
    !$mobileCardTile &&
    !$smallDesktopTile &&
    `
    @media (max-width: ${BREAKPOINT_SMALL}px) {
      width: 55vw;
    }
  `}
`
export const MobileWrapper = styled.div<{
  $mobileCardTile: boolean | undefined
  $smallDesktopTile: boolean | undefined
}>`
  ${({ $mobileCardTile, $smallDesktopTile }) =>
    $mobileCardTile &&
    `
    @media (${$smallDesktopTile ? 'min-width: 1px' : `max-width: ${BREAKPOINT_SMALL}px`}) {
      display: flex;
      gap: 8px;
      width: 100%;
    }
  `}
`

export const ProductCounter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
  background: var(--brand-primary);
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-fancy);
  font-size: 35px;
  color: var(--text-primary);
`
