import type { CustomUserTraits, EventProperties, ID, Options } from '@segment/analytics-next'
import React, { useMemo } from 'react'

import { CACHE_EXPIRY_DATE_KEY_NAME } from '@/constant/segment'
import { isValidDate } from '@/helpers/AnalyticsHelper'
import { generateCustomerId, hashValue, trackEventToSegment } from '@/helpers/AnalyticsSegmentHelper'
import { isCypressRunning } from '@/helpers/ClientSideRenderHelper'
import { useAnalyticsMetaData } from '@/hooks/AnalyticsMetaDataHook'
import { useSegment } from '@/hooks/AnalyticsSegmentHook'
import { useDefaultProperties } from '@/hooks/UseDefaultPropertiesHook'
import { AnalyticsPageEvent, CommonAnalyticsHandler, SessionUserResponse } from '@/types'
import { CommonUserTraits } from '@/types/segment'

import { AnalyticsHandlerRegistrant } from './RegistrantHandler'
import { logger } from '@/logging/Logger'

const log = logger()

export const AnalyticsSegmentHandler: React.FC = () => {
  // TODO: Consume traits/cart data if available from context provider
  const segment = useSegment()
  const metadata = useAnalyticsMetaData()
  const defaultProperties = useDefaultProperties()

  const handlerKey = defaultProperties?.agentConfiguration?.handlerKey

  const handler: CommonAnalyticsHandler = useMemo(() => {
    const externalLogins = new Set(['facebook', 'virgin'])

    return {
      name: 'Segment',

      async setUserId(userId, additionalProps) {
        try {
          const user = await segment?.user()
          const traits = user?.traits() as CustomUserTraits | undefined
          const emailProp = additionalProps?.email || additionalProps?.user?.email

          const userIdMismatched = (!userId && user?.id()) || (userId && userId !== user?.id())
          const traitsNeedsClearing = !userId && !additionalProps && traits && Object.keys(traits).length
          const customerIdMismatched =
            emailProp && traits?.customer_id && generateCustomerId(emailProp) !== traits.customer_id

          // TODO: This will go away when we implement User event.
          // This is to avoid sending data to wrong profile when session expires
          if (userIdMismatched || traitsNeedsClearing || customerIdMismatched) {
            // Reset all Segment cache including traits and anonymous ID
            segment?.reset()

            // Send page view again for new anonymous profile
            await this.trackPage({ name: window.document.title, url: window.location.href })

            // Terminate after clearing traits since neither user ID nor additional user properties exist
            if (traitsNeedsClearing) {
              return
            }
          }

          const params: (object | ID | CustomUserTraits)[] = []

          if (userId) {
            params.push(userId)
          }

          if (additionalProps) {
            const traits: CustomUserTraits = {}
            const { id, email, loginType, pointBalance, secondaryId, tierCode, tierLevel, user } = additionalProps
            const userEmail = email || user?.email

            if (userEmail) {
              traits.customer_id = generateCustomerId(userEmail)

              //! Ensure that Segment's Privacy Portal must be set up to filter email out and avoid sending this one is E2E testing
              //! Additional set up must be done in https://app.segment.com/big-red-co/privacy/welcome before proceeding.
              //! Alternatively, transformation must be done in
              //! Enlist email as red category privacy data and enfore client-side source to exclude it before accepting data
              if (!isCypressRunning.check()) {
                traits.email = hashValue(userEmail)
              }

              if ((id || secondaryId) && loginType) {
                // Secondary ID is only available when loggin in through partner site
                const externalId = secondaryId || id

                if (externalId) {
                  const idHash = generateCustomerId(`${userEmail}-${externalId}`)

                  switch (loginType) {
                    case 'facebook': {
                      traits.facebook_id = externalId
                      traits.facebook_id_hash = idHash
                      break
                    }
                    case 'virgin': {
                      if (tierCode) {
                        traits.tier_code = tierCode
                      }

                      if (tierLevel) {
                        traits.tier_level = tierLevel
                      }

                      if (Number.isSafeInteger(pointBalance)) {
                        traits.point_balance = pointBalance
                      }

                      traits.virgin_member_id = externalId
                      traits.virgin_member_id_hash = idHash
                      break
                    }
                    default: {
                      // TODO: Confirm whether this ID is UUID in CredentialsProvider
                      // traits.brg_b2c_experience_oz_id = id
                      break
                    }
                  }
                }
              }
            }

            params.push(traits)
          }

          // This is to prevent data flowing from E2E testing into Segment accidentally
          // Reason is to avoid having overwhelming number of unused profiles
          // However, in case user ID or email does exist, it's not an issue since we can merge profiles this way.
          if (
            userId ||
            (additionalProps && (additionalProps.email || additionalProps.user?.email)) ||
            (params.length && !isCypressRunning.check())
          ) {
            await segment?.identify(...params)

            // Get token expiry date from localStorage
            const cachedExpiryDate = localStorage.getItem(CACHE_EXPIRY_DATE_KEY_NAME)
            const now = Date.now()

            //! Since external login is different from credential login, a User Signed In event must be fired on first load after being redirected to site
            if (externalLogins.has(additionalProps?.loginType || '')) {
              // If no session expiry date exists in localStorage or session expires, fire User Signed In event
              if (!cachedExpiryDate || !isValidDate(cachedExpiryDate) || new Date(cachedExpiryDate).getTime() <= now) {
                await this.trackEvent({ object: 'User', action: 'Signed In', properties: { success: true } })
              }
            }

            // If expiry date exists in session, upsert it into localStorage instead.
            if (additionalProps?.expires) {
              window.localStorage.setItem(CACHE_EXPIRY_DATE_KEY_NAME, additionalProps?.expires)
            } else {
              // Temporarily set session expiry date as 30 minutes from "now" if not exist or an invalid date
              if (!cachedExpiryDate || !isValidDate(cachedExpiryDate)) {
                window.localStorage.setItem(
                  CACHE_EXPIRY_DATE_KEY_NAME,
                  new Date(now + 30 * 60 * 60 * 1000).toISOString()
                )
              }
            }
          }
        } catch (error) {
          log.error('Analytics User Error', {}, error as Error)
        }
      },

      async trackEvent(event) {
        try {
          // This is to prevent data flowing from E2E testing into Segment accidentally
          if (segment && !isCypressRunning.check()) {
            const user = await segment?.user()
            const { customer_id, tier_code, tier_level, virgin_member_id } = (user?.traits() || {}) as CustomUserTraits

            const context = metadata.getContextOptions()

            if (customer_id || virgin_member_id) {
              event.context = {
                ...event.context,
                traits: {
                  ...(customer_id ? { customer_id } : {}),
                  ...(tier_code ? { tier_code } : {}),
                  ...(tier_level ? { tier_level } : {}),
                  ...(virgin_member_id ? { virgin_member_id } : {}),
                  ...event.context?.traits,
                },
              }
            }

            // TODO: Remove PII data when server-side tracking is available
            if (
              `${event.object} ${event.action}` === 'Checkout Step Viewed' ||
              `${event.object} ${event.action}` === 'Checkout Step Completed'
            ) {
              const purchaser = metadata.getPurchaser()

              if (purchaser) {
                const { email, firstName, lastName } = purchaser
                if (email || firstName || lastName) {
                  event.context = {
                    ...event.context,
                    traits: {
                      ...event.context?.traits,
                      ...(!customer_id && email ? { customer_id: generateCustomerId(email) } : {}),
                      ...(email ? { email: email } : {}),
                      ...(firstName ? { first_name: firstName } : {}),
                      ...(lastName ? { last_name: lastName } : {}),
                    },
                  }
                }
              }
            }

            if (
              `${event.object} ${event.action}` === 'Checkout Started' ||
              `${event.object} ${event.action}` === 'Checkout Step Completed' ||
              `${event.object} ${event.action}` === 'Checkout Step Viewed' ||
              `${event.object} ${event.action}` === 'Checkout Field Entered'
            ) {
              const cartJWEToken = metadata.getCartJWEToken()

              if (cartJWEToken) {
                event.context = {
                  ...event.context,
                  jwe_token: cartJWEToken,
                }
              }
            }

            const browserTheme = metadata.getBrowserThemeColor()
            if (browserTheme) {
              event.context = {
                ...event.context,
                context: {
                  ...event.context?.context,
                  browser_theme_color: browserTheme,
                },
              }
            }

            await trackEventToSegment({ segment, event, context })
          }
        } catch (error) {
          log.error('Analytics Track Error', {}, error as Error)
        }
      },

      async trackPage(page?: AnalyticsPageEvent, sessionUser?: SessionUserResponse) {
        try {
          if (!isCypressRunning.check()) {
            const params: (string | EventProperties | Options)[] = []
            const properties: EventProperties = {}

            if (page?.category) {
              params.push(page.category)
            }

            if (page?.name) {
              params.push(page.name)
            }

            if (page?.url) {
              properties.url = page.url
              params.push(...[properties])
            }

            if (sessionUser?.email || sessionUser?.secondaryId || sessionUser?.user?.email) {
              const traits: CommonUserTraits = {}

              if (handlerKey === 'virgin') {
                const { pointBalance, secondaryId, tierCode, tierLevel } = sessionUser

                if (Number.isSafeInteger(pointBalance)) {
                  traits.point_balance = pointBalance
                }

                if (secondaryId) {
                  traits.virgin_member_id = secondaryId
                }

                if (tierCode) {
                  traits.tier_code = tierCode
                }

                if (tierLevel) {
                  traits.tier_level = tierLevel
                }
              }

              if (Object.keys(traits).length) {
                params.push({ context: { traits } })
              }
            }

            const browserTheme = metadata.getBrowserThemeColor()
            if (browserTheme) {
              params.push({ context: { browser_theme_color: browserTheme } })
            }
            await segment?.page(...params)
          }
        } catch (error) {
          log.error('Analytics Page Error', {}, error as Error)
        }
      },
    }
  }, [handlerKey, metadata, segment])

  return <AnalyticsHandlerRegistrant {...handler} />
}
